import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="footer bg-dark text-light py-4 mt-5">
      <Container>
        <Row>
          <Col md={4} className="mb-3">
            <h5>About Us</h5>
            <p>
              We connect people with expert knowledge, premium courses, and remote work opportunities. Join us to unlock
              your potential and succeed in your field.
            </p>
          </Col>
          <Col md={4} className="mb-3">
            <h5>Quick Links</h5>
            <Nav className="flex-column">
              <Nav.Link href="/learn-more" className="text-light">
                Learn More
              </Nav.Link>
              <Nav.Link href="/testimonials" className="text-light">
                User Success Stories
              </Nav.Link>
              <Nav.Link href="/auth" className="text-light">
                Sign Up / Login
              </Nav.Link>
              <Nav.Link href="/contact-us" className="text-light">
                Contact Us
              </Nav.Link>
            </Nav>
          </Col>
          {/* <Col md={4} className="mb-3">
            <h5>Contact Us</h5>
            <p>Email: support@sell-skill.com</p>
            <p>Phone: +1 (555) 123-4567</p>
            <p>Follow us on social media:</p>
            <Nav className="social-links">
              <Nav.Link href="https://www.instagram.com/sell.ur.skill/" target="_blank" className="text-light">
                <FontAwesomeIcon icon={faInstagram} size="lg" /> {/* Instagram Icon */}
              {/* </Nav.Link>
              <Nav.Link href="https://www.youtube.com/@sell-skill" target="_blank" className="text-light">
                <FontAwesomeIcon icon={faYoutube} size="lg" /> {/* YouTube Icon     
              </Nav.Link>
            </Nav>
          </Col>  */}
        </Row>
        <Row className="text-center mt-4">
          <Col>
            <p>&copy; {new Date().getFullYear()} Sell-Skill. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;

