import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard, FaTimes, FaBars } from 'react-icons/fa';
import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setWithId, setWorldId } from '../../../../reducers/reducers';
import { v4 as uuidv4 } from 'uuid';

function Worlds() {
    let filteredWorlds;
    let filteredMyUploads;
    let filteredMyDrafts;
    let filteredMySubscriptions;
    const [worlds, setWorlds] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Define the maximum length before truncation

    const toggleExpand = () => setIsExpanded(!isExpanded);
    const [skillerId, setSkillerId] = useState('')
    const [activeSubTab, setActiveSubTab] = useState('exploreWorlds');
    const [matesRequests, setMatesRequests] = useState([]);
const [currentMates, setCurrentMates] = useState([]);
const [myUploads, setMyUploads] = useState([])
const [subscribedInWorlds, setSubscribedInWorlds] = useState([])          
const [isSearchVisible, setIsSearchVisible] = useState(false);
const [isSearchVisibleM, setIsSearchVisibleM] = useState(false);
const [searchTerm, setSearchTerm] = useState('');



const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};


const handleSearchIconClickM = () => {
    setIsSearchVisibleM(!isSearchVisibleM);
};

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};


useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }
    getSkillerId();
  })
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



    const [activeTab, setActiveTab] = useState('worlds');
    const [posts, setPosts] = useState([])
    const [Aposts, setAPosts] = useState([])
    const [ASposts, setASPosts] = useState([])
    const [ASVposts, setASVPosts] = useState([])
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [expandedSection, setExpandedSection] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [myEarning, setMyEarning] = useState('');
    const [myRate, setMyRate] = useState('');
    const [myCart, setMyCart] = useState([]);
    const [selectedWorldComments, setSelectedWorldComments] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [mates, setMates] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [resources, setResources] = useState([])
    const [myDrafts, setMyDrafts] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

    const [relativePeople, setRelativePeople] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });


    const [categories, setCategories] = useState([
        "Freelancing",
        "E-commerce",
        "Dropshipping",
        "Affiliate Marketing",
        "Content Creation (YouTube, TikTok, Blogging)",
        "Social Media Management",
        "Stock Trading & Investing",
        "Cryptocurrency & NFTs",
        "Online Coaching & Consulting",
        "Digital Marketing Services",
        "Selling Online Courses",
        "Print-on-Demand",
        "Virtual Assistance",
        "Real Estate Investing",
        "Passive Income Strategies",
        "Remote Work Opportunities"
    ]); // Example categories


            const [activeCategory, setActiveCategory] = useState(null);
      
        const handleCategoryClick = (category) => {
          setActiveCategory(category);
 setFilters({ ...filters, category: category });
        };


        const filteredCategories = categories.filter((cat) =>
            cat.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
    const handleMateTab = () => {
        setActiveTab('mates')
        setActiveSubTab('exploreMates')
        
    }

    const handlePostsTab = () => {
        setActiveTab('posts')
        setActiveSubTab('explorePosts')
        
    }

    const handleShowModal = (post) => {
        setSelectedPost(post);
        setResources([
          ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
          ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
          ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
          ...(post.texts || []).map((item) => ({ type: "text", content: item })),
          ...(post.quizs || []).map((item) => ({
            type: "quiz",
            content: item.content,
            choices: item.choices,
            correctAnswer: item.correctAnswer,
          })),
        ]);
    
        console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
        setShowModal(true);
      };
      const handleCloseModal = () => setShowModal(false);


      /// <---- fetch worlds --->  ssssssssssssssssssssssssssssssssssss

        // Infinite scroll handler

        const fetchWorlds = async () => {
           
            try
      {
      setLoading(true)
                     const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
                         console.log('fetching worlds..............')
     
                         setWorlds(response.data);
     
                     
                     }
                     catch(error){
                         console.log('refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......')
                         fetchWorlds();
                     }finally{
                         setLoading(false)
                     }
               
                        
             
             };

     
 const handleScroll =  () => {
   console.log("Scrolling detected"); // Debug log
   if (loading) return;

   if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
     console.log("scroll is working, scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,scroll is working,...............scroll is working,scroll is working,scroll is working,scroll is working,"); // Debug log
        
   }
 };

        
           useEffect(() => {
             window.addEventListener("scroll", handleScroll);
             return () => window.removeEventListener("scroll", handleScroll); // Cleanup the scroll listener
           }, [handleScroll]);   



      useEffect(() => {
 
        const fetchWorlds = async () => {
           
       try
 {
 
                const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
                    console.log('fetching worlds..............')

                    setWorlds(response.data);

                
                }
                catch(error){
                    console.log('refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......refetching worlds.......')
                    fetchWorlds();
                }finally{
                    setLoading(false)
                }
          
                   
        
        };

        fetchWorlds();



              const fetchMyDrafts = async () => {
                  try {
                      const response = await axios.get('https://sell-skill.com/api/endpoints/getMyDrafts');
                      setMyDrafts(response.data);
                      console.log('response.data from fetchWorlds ===========================-=-=-=---------------------------------------------------=-=--=->  > > > >> > > > >>  ',response.data)
                  } catch (error) {
                      console.error('Error fetching worlds:', error);
                  }
              };

          const fetchProfilePicture = async () => {
              try {
                  const response = await axios.get(
                      'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                      { responseType: 'blob' }
                  );
        
                  console.log('Fetched blob:', response.data);
                 
                  if (response.data.size > 0) {
                      // Blob has data, create an object URL
                      const url = URL.createObjectURL(response.data);
                      setProfilePictureSrc(url);
                  } else {
                      // Blob is empty, use the Firebase image URL
                      setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                  }
              } catch (error) {
                  console.error('Error fetching profile picture:', error);
                  // Optionally set a fallback image if there's an error
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          };
        
          const getMates = async() => {

          const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
              setMates(response.data)
          }
          const getMateRequests = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getMateRequests')
              setMatesRequests(response.data)
              console.log('response.data from getMateRequests ==================-=-=-=-==========-=-=-=-=-=-> > > >  > > > >  ',response.data)
           }
           const getPosts = async() => {
          const response =  await axios.get('https://sell-skill.com/api/endpoints/WgetPosts')
              setPosts(response.data)
            }
            const fetchMyWorlds = async () => {
              try {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
                  setMyUploads(response.data);

              } catch (error) {
                  console.error('Error fetching worlds:', error);
              }
          };

          const fetchSubscribedInWorlds = async () => {
              try {
                const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn');
                setSubscribedInWorlds(response.data);
                console.log('response.data from get subscribtions===========================-=-=-==-==-=-=-=-=-=-=--==-=-=-==-=---------------------->>> > > > >> > > >  > >>  ',response.data)
          } catch (error) {
                console.error('Error fetching worlds:', error);
              }
            };
            const getCurrentMates = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getCurrentMates')
              setCurrentMates(response.data)
              console.log('response.data from getMateRequests =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
           }

       const ASVGetPosts = async () => {
 
              const response = await axios.get(
                "https://sell-skill.com/api/endpoints/ASVgetPosts"
           
             );
              console.log('response.data from ASV getPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
              setASVPosts(response.data);
    
             console.log('response.data from ASVgetPosts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)

          };

          const AgetPosts = async () => {
              try {
                const response = await axios.get(
                  "https://sell-skill.com/api/endpoints/AgetPosts"
             
                );
                console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
                setAPosts(response.data);
      
                console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
              } catch (error) {
                console.error("Error fetching posts:", error);
              }
            };


            const ASgetPosts = async () => {
             try {
               const response = await axios.get(
                 "https://sell-skill.com/api/endpoints/AgetPosts"
            
               );
               console.log('response.data from ASSSSSSSSSSSSSSSSSSSSSSSgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
               setASPosts(response.data);
     
               console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
             } catch (error) {
               console.error("Error fetching posts:", error);
             }
           };

            const fetchRelativePeople = async () => {
              try {
                  const response = await axios.get('https://sell-skill.com/api/endpoints/relativePeople');
                  setRelativePeople(response.data);
              } catch (error) {
                  console.error('Error fetching relative people:', error);
              } 
          }; 

           const getWorldCategories = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterWorlds')
              setCategories(response.data)
              console.log('response.data from getWorldCategories ===========================-=-=-=-=-=-=-----------------------=-=-=-=-=-=-> > >  >  > > >>  >  ',response.data)
           }
           getWorldCategories();




          
          fetchMyDrafts();
          fetchRelativePeople();
            AgetPosts();
            ASgetPosts();
          ASVGetPosts();
           getCurrentMates();
            fetchSubscribedInWorlds();
          getMates();

      fetchProfilePicture();
          getPosts()
          getMateRequests()
          fetchMyWorlds();
    }, []);



    const handleAddMate = async (mateId) => {
        const mateRequestId = uuidv4();
        await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId, mateRequestId})
        console.log(`Add mate with ID: ${mateId}`);
        // Add your logic here
    };
           
    const handleMessage = (mateId) => {
         setWithId(mateId)
         navigate('/chat')
        // Add your logic here
    };
    

    const handleAcceptRequest = async( mateId) => {
        console.log('mateId from handleAcceptRequest ===================================================> > > > > ', mateId)
        await axios.patch('https://sell-skill.com/api/endpoints/acceptMateRequest', {mateId})
    }
    const  handleDeclineRequest = async() => {

    }
    const clearAllCookies = () => {
        document.cookie.split(";").forEach((cookie) => {
            const cookieParts = cookie.split("=");
            const cookieName = cookieParts[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
        });
    };
    const handleUnSavePost = async(postId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/unSavePost', {postId})
    }
    const handleLogout = async () => {
        // Clear localStorage
        console.log("Before clearing localStorage:", localStorage);
        localStorage.clear();
        console.log("After clearing localStorage:", localStorage);
    
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
        console.log("Cookies after clearing:", document.cookie);
    
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

    //   useEffect(() => {
    //     const getPosts = async () => {
    //       try {
    //         const response = await axios.get(
    //           "https://sell-skill.com/api/endpoints/ASgetPosts"
           
    //         );
    //         console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
    //         setASPosts(response.data);
    
    //         console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
    //       } catch (error) {
    //         console.error("Error fetching posts:", error);
    //       }
    //     };
    //     getPosts();
    //   },[]);  


 
    // useEffect(() => {
    //     const getCart = async () => {
    //         try {
    //             const response = await axios.get('https://sell-skill.com/api/endpoints/getCart');
    //             setMyCart(response.data);
    //             const total = response.data.reduce((sum, world) => sum + (world.price || 0), 0);
    //             setTotalPrice(total); // Update the state
    //         } catch (error) {
    //             console.error('Error fetching cart:', error);
    //         }
    //     };
    //     getCart();
    // }, []);
    


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);  
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };


         const handleWorldClickFromMyCart = (worldId) => {
         dispatch(setWorldId(worldId))
         navigate('/levels')
     }
     const handleNameClick = (withId) => {
         dispatch(setWithId(withId));
         navigate('/chat');
     };

    //       useEffect(() => {
    //   const getSendersInfo = async () => {
    //          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
    //          setMessages(response.data);
    //          console.log('senders ==========>>', response.data);
    //      };
    //      getSendersInfo();
    //  }, []);
    // useEffect(() => {
    //     const myEarning = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
    //         setMyEarning(response.data);
    //     };
    //     myEarning();
    // }, []);


    // useEffect(() => {
    //     const myRate = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
    //         setMyRate(response.data);
    //     };
    //     myRate();
    // }, []);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
            //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                    //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
    
     filteredWorlds = worlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    
    
    

        
    filteredMyUploads = myUploads.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    


        
    filteredMyDrafts = myDrafts.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    


        /////
    filteredMySubscriptions = subscribedInWorlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    

    const handleGetInWorld = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/levels/${worldId}`);
    };

    const handleViewWorldTrailer = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/world-trailer/${worldId}`);
    };

    const handleAddToCart = async (worldId) => {
        await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
    };

    const handleWorldComments = async(worldId) => {
        setShowCommentsModal(true);
        const response =  await axios.get('https://sell-skill.com/api/endpoints/getWorldComments', {params: {worldId}})
        setSelectedWorldComments(response.data);

    };

    const closeCommentsModal = () => {
        setShowCommentsModal(false);
    };
    const handleToggleWorldsSection = () => {
        setActiveTab('worlds')
        setActiveSubTab('exploreWorlds')
    }

    const handlePurchaseWorldFromCart = (worldId) => {
        dispatch(setWorldId(worldId))
        navigate('/levels')
    }

    const handleDeleteWorldFromCart = async(worldId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
    }

    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [opacity, setOpacity] = useState(1);

 
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    
    return (
        <PageContainer onScroll={    () =>    {
        // Calculate opacity based on scroll position
        const maxScroll = 300; // Adjust based on when you want it to vanish completely

        const currentScroll = window.scrollY;
        console.log('currentScroll ===============================================================-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-> >  > > > > > > > > >   ',currentScroll)
        const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
        console.log('newOpacity ===================================================-=-=-=-=-=-=-=-=- > > > > > >  > > > > > >  ',newOpacity)
        console.log('joaaaaaaa joaaaaaa joaaaaa masryyyayayyy joaaaaaaa jiaaaaa noaaaa jooaa hiaaa hoaa joaaaaaaa joaaaaaa joaaaaa masryyyayayyy joaaaaaaa jiaaaaa noaaaa jooaa hiaaa hoaa joaaaaaaa joaaaaaa joaaaaa masryyyayayyy joaaaaaaa jiaaaaa noaaaa jooaa hiaaa hoaa joaaaaaaa joaaaaaa joaaaaa masryyyayayyy joaaaaaaa jiaaaaa noaaaa jooaa hiaaa hoaa')
        setOpacity(newOpacity);
 
      }}>   
        <Container   >
    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>


        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
        <SearchContainer>
                <FaSearch onClick={handleSearchIconClick} />
                <SearchBar isVisible={isSearchVisible}>
                    <input
                        type="text"
                        placeholder="Search Worlds"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </SearchBar>
            </SearchContainer>
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      



                        <ContentContainer >
             {sidebarVisible && (
         <Sidebar>

      

 <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <h5 style={{ marginBottom: '20px', color: '#2c3e50' }}>Filters</h5>

   
    {/* <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:</label>
        <div className="position-relative">
            <button 
                onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                style={{
                    background: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    borderRadius: '8px',
                }}
            >
                {filters.category || 'Any'}
                <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
            </button>
            {showCategoryDropdown && (
                <ul 
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        background: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        listStyle: 'none',
                        padding: 0,
                        margin: 0,
                        zIndex: 1000,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <li 
                        style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
                        onClick={() => {
                            setFilters({ ...filters, category: '' });
                            setShowCategoryDropdown(false);
                        }}
                    >
                        Any
                    </li>
                    {categories.map((cat) => (
                        <li 
                            key={cat} 
                            style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
                            onClick={() => {
                                setFilters({ ...filters, category: cat });
                                setShowCategoryDropdown(false);
                            }}
                        >
                            {cat}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    </div>  */}


{/* 
 <div className="mb-3">
      <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:</label>
      <Container fluid>
        <Row className="g-1">
          {categories.map((category, index) => (
            <Col key={index} xs={6}>
              <Button
                variant={activeCategory === category ? 'success' : 'outline-secondary'}
                onClick={() => handleCategoryClick(category)}
                style={{
                  fontSize: '8px', // Smaller font size
                  padding: '5px', // Reduced padding
                  width: '100%', // Full width for consistency
                  borderRadius: '12px', // Rounded edges
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  overflow: 'hidden', // Hide overflow
                  textOverflow: 'ellipsis', // Add ellipsis if text is too long
                }}
                title={category} // Tooltip for full category name
              >
                {category}
              </Button>
            </Col>   
          ))}
        </Row>
      </Container>
    </div>
 */}



 <div
            style={{
                background: '#f9f9f9',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
 

            {/* Category Filter */}
            <div className="mb-3">
                <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:                     <button
                        onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                    >
                        {filters.category || 'Any...'}
                        <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
                    </button></label>
                <div className="position-relative">

                    {showCategoryDropdown && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                width: '100%',
                                background: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                zIndex: 1000,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {/* Search Bar */}
                            <div
                                style={{
                                    padding: '10px',
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search categories..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>

                            {/* Category List */}
                            <ul
                                style={{
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <li
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                        color: '#2c3e50',
                                    }}
                                    onClick={() => {
                                        setFilters({ ...filters, category: '' });
                                        setShowCategoryDropdown(false);
                                    }}
                                >
                                    Any
                                </li>
                                {filteredCategories.map((cat) => (
                                    <li
                                        key={cat}
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            color: '#2c3e50',
                                        }}
                                        onClick={() => {
                                            setFilters({ ...filters, category: cat });
                                            setShowCategoryDropdown(false);
                                        }}
                                    >
                                        {cat}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
     

    {/* Price Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Price Range:</label>
        <div className="d-flex">
            <input 
                type="number" 
                placeholder="Min" 
                className="form-control me-2" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [Number(e.target.value) || 0, filters.priceRange[1]] 
                })} 
            />
            <input 
                type="number" 
                placeholder="Max" 
                className="form-control" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [filters.priceRange[0], Number(e.target.value) || 1000] 
                })} 
            />
        </div>
    </div>

    {/* Rating Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Rating:</label>
        <select
            value={filters.minRate}
            onChange={(e) => setFilters({ ...filters, minRate: Number(e.target.value) })}
            className="form-select"
        >
            <option value="0">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>
    </div>

    {/* Publish Date Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Publish Date:</label>
        <div className="d-flex">
            <input 
                type="date" 
                className="form-control me-2"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [new Date(e.target.value), filters.publishDateRange[1]] 
                })} 
            />

        </div>

        <div className="d-flex">
        <input 
                type="date" 
                className="form-control"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [filters.publishDateRange[0], new Date(e.target.value)] 
                })} 
            />
            

        </div>


    </div>

    {/* Minimum Students Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Students:</label>
        <input 
            type="number" 
            value={filters.minStudents} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minStudents: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>

    {/* Allow Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Allow Advisors:</label>
        <select 
            value={filters.allowAdvisors === null ? 'any' : filters.allowAdvisors.toString()}
            className="form-select"
            onChange={(e) => setFilters({ ...filters, allowAdvisors: e.target.value === 'any' ? null : e.target.value === 'true' })}
        >
            <option value="any">Any</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>

    {/* Minimum Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Advisors:</label>
        <input 
            type="number" 
            value={filters.minAdvisors} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minAdvisors: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>
</div>


 <SectionHeader onClick={() => toggleSection('myCart')}>
                {expandedSection === 'myCart' ? <FaChevronUp /> : <FaChevronDown />}
                My Cart
            </SectionHeader>
            {expandedSection === 'myCart' &&
    myCart.map((world) => (
        <div key={world._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {world.worldName && (
                <Button
                    onClick={() => handleWorldClickFromMyCart(world._id)}
                    variant="light"
                    style={{ flexGrow: 1, marginRight: '10px' }}
                >
                    {world.worldName}
                </Button>
            )}
            {world.price && (
                <span style={{ marginRight: '10px' }}>${world.price.toFixed(2)}</span>
            )}
<FaCreditCard
    onClick={() => handlePurchaseWorldFromCart(world._id)}
    style={{ color: 'blue', cursor: 'pointer', marginRight: '10px' }}
    title="Go to Payment"
/>
            <FaTrash
                onClick={() => handleDeleteWorldFromCart(world._id)}
                style={{ color: 'red', cursor: 'pointer' }}
                title="Delete"
            />
        </div>
    ))}

            {expandedSection === 'myCart' && (
                <div style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'right' }}>
                    Total Price: ${totalPrice.toFixed(2)}
                </div>
            )}

             <SectionHeader onClick={() => toggleSection('chats')}>
                 {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
                 My Chats
             </SectionHeader>
             {expandedSection === 'chats' &&
                 messages.map((message) => (
                     <div key={message._id}>
                         {message.senderName && (
                             <Button
                                 onClick={() => handleNameClick(message.withId)}
                                 variant="light"
                                 style={{ width: '100%' }}
                             >
                                 {message.senderName}
                             </Button>
                         )}
                     </div>
                 ))}
             <SectionHeader onClick={() => toggleSection('myEarning')}>
             {expandedSection === 'myEarning' ? <FaChevronUp /> : <FaChevronDown />}
                 MY Earning
                
             </SectionHeader>
             {expandedSection === 'myEarning' &&
               (
                     <div >
                         {myEarning} $
                     </div>
                 )}

 <SectionHeader onClick={() => toggleSection('myRate')}>
     {expandedSection === 'myRate' ? <FaChevronUp /> : <FaChevronDown />}
     My Rate
 </SectionHeader>
 {expandedSection === 'myRate' && (
     <div>
         {Array.from({ length: 5 }, (_, index) => {
             if (myRate >= index + 1) {
                 return <FaStar key={index} color="gold" />;
             } else if (myRate > index && myRate < index + 1) {
                 return <FaStarHalfAlt key={index} color="gold" />;
             } else {
                 return <FaRegStar key={index} color="gold" />;
             }
         })}
     </div>
 )}
         </Sidebar>
     )}


     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}






                <MainContent style={{ marginTop: '200px', padding: '50px' }} onScroll={handleScroll}>
                <Header>
    <TabContainer>
        <Tab
            active={activeTab === 'worlds'}
            onClick={handleToggleWorldsSection}
        >
             Worlds
        </Tab>
        <Tab
            active={activeTab === 'mates'}
            onClick={ handleMateTab}
        >
             Mates
        </Tab>
        <Tab active={activeTab === 'posts'} onClick={handlePostsTab}>
                         Posts
                    </Tab>
    </TabContainer>
{  !showDropdown &&  <UploadButtonWrapper>
        <UploadButton onClick={() => navigate('/add-world')}>
            <FaUpload style={{ marginRight: '5px' }} /> Upload World
        </UploadButton>
    </UploadButtonWrapper>}
</Header>


{       activeTab === 'worlds' &&           ( 

    <>
    
    <SubTabContainer opacity={opacity}>
            <SubTab active={activeSubTab === 'exploreWorlds'} onClick={() => setActiveSubTab('exploreWorlds')}>
                Explore Worlds
            </SubTab>
            <SubTab active={activeSubTab === 'myUploads'} onClick={() => setActiveSubTab('myUploads')}>
                My Uploads
            </SubTab>
            <SubTab active={activeSubTab === 'myDrafts'} onClick={() => setActiveSubTab('myDrafts')}>
                My drafts
            </SubTab>
            <SubTab active={activeSubTab === 'mySubscribtions'} onClick={() => setActiveSubTab('mySubscribtions')}>
                My Subscribtions
            </SubTab>
        </SubTabContainer>
    


{activeSubTab === 'exploreWorlds' && (
    <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>

            <Row>
                     
                {filteredWorlds.map((world, index) => {
                    const totalRate = world?.rates?.reduce((sum, r) => sum + r.rate, 0);
                    const averageRate = totalRate / world?.rates?.length || 0;

                    return (
                        <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">

                            <Card style={{ overflow: 'hidden', width: '100%' }}>
                            <Card.Header className="d-flex align-items-center">
{        world?.publisher?.picture &&                    <a href={`/profile/${world?.publisher?._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${world?.publisher?.picture?.picture?.contentType};base64,${bufferToBase64(world?.publisher?.picture?.picture?.data?.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         world?.publisher?.name &&               <a
                            href={`/profile/${world?.publisher?._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {world?.publisher?.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < world?.publisher?.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>


{   world?.worldThumbnail  &&
                                <CardImage
                                    variant="top"
                                    src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`}
                                    alt="World Thumbnail"
                                    style={{
                                        width: '100%',
                                        height: 'auto', /* Maintain aspect ratio */
                                        objectFit: 'cover', /* Prevent stretched images */
                                      }}
                                />}
                                <Card.Body>
                                    <Card.Title>{world?.worldName}</Card.Title>
                                    <Card.Text>{ world?.worldDescription}</Card.Text>
                                    <Card.Text>Price: ${world?.price}</Card.Text>
                                { world?.advisorAmount &&   <Card.Text>Adviosr pudget: ${world?.advisorAmount}</Card.Text>}
                                { /*           world.students.length > 0 &&                        <Card.Text>
                                        <FaUserGraduate /> {world.students.length.toString()} Students

                                    </Card.Text>*/}
{                         world?.advisors?.length > 0 &&           <Card.Text>
                                        <FaChalkboardTeacher /> {world?.advisors?.length?.toString()} Advisors

                                    </Card.Text>}
                                    {world?.isWorldAllowingAdvisors && (
                                        <Card.Text>
                                            <FaCheckCircle color="green" /> Allowing Advisors
                                        </Card.Text>
                                    )}
                                    <Card.Text>
                                        Date of Publish: {new Date(world?.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>
                                    <div className="mb-3">
                                        {Array.from({ length: 5 }, (_, i) => (
                                            <FaStar
                                                key={i}
                                                color={i < averageRate ? '#f39c12' : '#dcdcdc'}
                                            />
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleGetInWorld(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                            Get In
                                        </Button>
                                        {world?.allowedCard && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Add To Cart
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleViewWorldTrailer(world._id)}
                                        >
                                            <FaPlayCircle style={{ marginRight: '5px' }} />
                                            View Trailer
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleWorldComments(world?._id)}
                                        >
                                            <FaComments style={{ marginRight: '5px' }} />
                                            Comments
                                        </Button>
                                    </div>
                                </Card.Body>   
                            </Card>
                  
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>
)}




                        
{ activeSubTab === 'myUploads' && 
     <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>
            <Row>
                {filteredMyUploads.map((world, index) => {

                    return (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                         <CardContainer>
                            <Card>



{   world.worldThumbnail  &&
                                <Card.Img
                                    variant="top"
                                    src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'}
                                    alt="World Thumbnail"
                                />}
                                <Card.Body>
                                    <Card.Title>{world.worldName}</Card.Title>
                                    <Card.Text>{world.worldDescription}</Card.Text>
                                    <Card.Text>Price: ${world.price}</Card.Text>
                                { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
                                    <Card.Text>
                                        <FaUserGraduate /> {world.numberOdStudents} Students
                                    </Card.Text>
                                    <Card.Text>
                                        <FaChalkboardTeacher /> {world.numberOfAdvisors} Advisors
                                    </Card.Text>
                                    {world.isWorldAllowingAdvisors && (
                                        <Card.Text>
                                            <FaCheckCircle color="green" /> Allowing Advisors
                                        </Card.Text>
                                    )}
                                    <Card.Text>
                                        Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>

                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleGetInWorld(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                            Get In
                                        </Button>
                                        {world.allowedCard && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Add To Cart
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleViewWorldTrailer(world._id)}
                                        >
                                            <FaPlayCircle style={{ marginRight: '5px' }} />
                                            View Trailer
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleWorldComments(world.comments)}
                                        >
                                            <FaComments style={{ marginRight: '5px' }} />
                                            Comments
                                        </Button>
                                    </div>
                                </Card.Body>   
                            </Card>
                            </CardContainer>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>}



                        
{ activeSubTab === 'mySubscribtions' &&      <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>
            <Row>
                {filteredMySubscriptions.map((world, index) => {
                    const totalRate = world?.rates?.reduce((sum, r) => sum + r.rate, 0);
                    const averageRate = totalRate / world?.rates?.length || 0;

                    return (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                         <CardContainer>
                            <Card>
                            <Card.Header className="d-flex align-items-center">
{        world.publisherr?.picture &&                    <a href={`/profile/${world.publisherr._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${world.publisherr.picture.picture.contentType};base64,${bufferToBase64(world.publisherr.picture.picture.data.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         world.publisherr.name &&               <a
                            href={`/profile/${world.publisherr._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {world.publisherr.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < world.publisherr.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>



                {   world.worldThumbnail  &&
                                <CardImage
                                    variant="top"
                                    src={`data:${world.worldThumbnail.contentType};base64,${bufferToBase64(world.worldThumbnail.data.data)}`}
                                    alt="World Thumbnail"
                                    style={{
                                        width: '100%',
                                        height: 'auto', /* Maintain aspect ratio */
                                        objectFit: 'cover', /* Prevent stretched images */
                                      }}
                                />}
                                <Card.Body>
                                    <Card.Title>{world.worldName}</Card.Title>
                                    <Card.Text>{world.worldDescription}</Card.Text>
                                    <Card.Text>Price: ${world.price}</Card.Text>
                                { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
{            world?.students?.length > 0 &&                        <Card.Text>
                                        <FaUserGraduate /> {world.students.length.toString()} Students

                                    </Card.Text>}
{                         world?.advisors?.length > 0 &&           <Card.Text>
                                        <FaChalkboardTeacher /> {world.advisors.length.toString()} Advisors

                                    </Card.Text>}
                                    {world?.isWorldAllowingAdvisors && (
                                        <Card.Text>
                                            <FaCheckCircle color="green" /> Allowing Advisors
                                        </Card.Text>
                                    )}
                                    <Card.Text>
                                        Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>
                                    <div className="mb-3">
                                        {Array.from({ length: 5 }, (_, i) => (
                                            <FaStar
                                                key={i}
                                                color={i < averageRate ? '#f39c12' : '#dcdcdc'}
                                            />
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleGetInWorld(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                            Get In
                                        </Button>
                                        {world.allowedCard && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Add To Cart
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleViewWorldTrailer(world._id)}
                                        >
                                            <FaPlayCircle style={{ marginRight: '5px' }} />
                                            View Trailer
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleWorldComments(world.comments)}
                                        >
                                            <FaComments style={{ marginRight: '5px' }} />
                                            Comments
                                        </Button>
                                    </div>
                                </Card.Body>   
                            </Card>
                            </CardContainer>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>}
    




    

                        
{ activeSubTab === 'myDrafts' &&    
  <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>
            <Row>
                {filteredMyDrafts.map((world, index) => {


                    return (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                         <CardContainer>
                            <Card>



{   world.worldThumbnail  &&
                                <Card.Img
                                    variant="top"
                                    src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'}
                                    alt="World Thumbnail"
                                />}
                                <Card.Body>
                                    <Card.Title>{world.worldName}</Card.Title>
                                    <Card.Text>{world.worldDescription}</Card.Text>
                                    <Card.Text>Price: ${world.price}</Card.Text>
                                { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
                                    <Card.Text>
                                        <FaUserGraduate /> {world.numberOdStudents} Students
                                    </Card.Text>
                                    <Card.Text>
                                        <FaChalkboardTeacher /> {world.numberOfAdvisors} Advisors
                                    </Card.Text>
                                    {world.isWorldAllowingAdvisors && (
                                        <Card.Text>
                                            <FaCheckCircle color="green" /> Allowing Advisors
                                        </Card.Text>
                                    )}
                                    <Card.Text>
                                        Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>

                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleGetInWorld(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                            Get In
                                        </Button>
                                        {world.allowedCard && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Add To Cart
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleViewWorldTrailer(world._id)}
                                        >
                                            <FaPlayCircle style={{ marginRight: '5px' }} />
                                            View Trailer
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleWorldComments(world.comments)}
                                        >
                                            <FaComments style={{ marginRight: '5px' }} />
                                            Comments
                                        </Button>
                                    </div>
                                </Card.Body>   
                            </Card>
                            </CardContainer>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>}
    </>
    

                    
                ) } 


{activeTab === 'mates' && (
    <>
        {/* Sub-tabs for mates */}
        <SubTabContainer>
            <SubTab active={activeSubTab === 'exploreMates'} onClick={() => setActiveSubTab('exploreMates')}>
                Explore Mates {activeSubTab === 'exploreMates' &&         <SearchContainer>
                <FaSearch onClick={handleSearchIconClickM} />
                <SearchBar isVisibleM={isSearchVisibleM}>
                    <input
                        type="text"
                        placeholder="Search Worlds"
                        value={searchQuery}
                        
                    />
                </SearchBar>
            </SearchContainer>}
            </SubTab>
            <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('matesRequests')}>
                Mates Requests
            </SubTab>
            <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('currentMates')}>
                Current Mates
            </SubTab>
        </SubTabContainer>

        {/* Content based on the selected sub-tab */}
        {activeSubTab === 'exploreMates' && (
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
                
                <Row>

{
  mates.length === 0 && (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-2xl shadow-lg p-6 max-w-md text-center">
 
        {/* Text */}
        <h2 className="text-xl font-semibold mb-2 text-gray-800">
          You should <span className="text-green-500">subscribe to some world</span> to get mates!
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Discover worlds, connect with people, and grow your knowledge together.
        </p>
        {/* CTA Button */}
        <Button
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600"
          onClick={() => setActiveTab("worlds")}
        >
          Explore Worlds
        </Button>
      </div>
    </div>
  )
}

                    
                    {mates.map((mate) => {
                        const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                        return (
        <Col
            key={mate._id}
            xs={12}
            sm={mates.length === 1 ? 8 : 6}
            md={mates.length === 1 ? 6 : 4}
            lg={mates.length === 1 ? 4 : 3}
            className="mb-4"
            style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
        >
            <CardContainer>
            <Card
                style={{
                    minHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {mate.picture && (
                      <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center mt-3">
                        <Card.Img
                            variant="top"
                            src={mate.picture || '/images/default-profile.jpg'}
                            alt={`${mate.name || 'Mate'}'s profile`}
                            className="rounded-circle mx-auto"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '2px solid #ccc',
                            }}
                        />
                    </div>
                    </a>
                )}
                <Card.Body style={{ flex: 1 }}>
                <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${mate.name} ${mate.surname}`}
                    </Card.Title>
                    </a>
                    <Card.Text className="text-center">
                        Total Cash: ${mate.totalCash || 0}
                    </Card.Text>
                    <div className="d-flex justify-content-center mb-3">
                        {Array.from({ length: 5 }, (_, i) => (
                            i < averageRate ? (
                                <FaStar key={i} color="gold" />
                            ) : (
                                <FaRegStar key={i} color="gold" />
                            )
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {mate.sharedWorlds?.map((world) => (
                             <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                {world.thumbnail && 
                                    <img
                                        key={world._id}
                                        src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                        alt={world.name || 'World'}
                                        title={world.name || 'World'}
                                        className="rounded-circle"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'contain',
                                            margin: '5px',
                                        }}
                                    />
                                }
                            </a>
                        ))}
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success" onClick={() => handleAddMate(mate._id)}>
                        Send Request
                    </Button>

                </Card.Footer>
            </Card>
            </CardContainer>
        </Col>
        
                        );
                    })}
                </Row>
            </Container>
        </ContentWrapper>



        )}

        

        {activeSubTab === 'matesRequests' && (
            <ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>
        
        <Row>
            
            {matesRequests.map((mate) => {
                const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                return (
<Col
    key={mate._id}
    xs={12}
    sm={matesRequests.length === 1 ? 8 : 6}
    md={matesRequests.length === 1 ? 6 : 4}
    lg={matesRequests.length === 1 ? 4 : 3}
    className="mb-4"
    style={matesRequests.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
>
    <CardContainer>
    <Card
        style={{
            minHeight: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
    >
        {mate.picture && (
                                  <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <div className="text-center mt-3">
                <Card.Img
                    variant="top"
                    src={mate.picture || '/images/default-profile.jpg'}
                    alt={`${mate.name || 'Mate'}'s profile`}
                    className="rounded-circle mx-auto"
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                        border: '2px solid #ccc',
                    }}
                />
            </div>
            </a>
        )}
        <Card.Body style={{ flex: 1 }}>
        <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`${mate.name} ${mate.surname}`}
            </Card.Title>
            </a>
            <Card.Text className="text-center">
                Total Cash: ${mate.totalCash || 0}
            </Card.Text>
            <div className="d-flex justify-content-center mb-3">
                {Array.from({ length: 5 }, (_, i) => (
                    i < averageRate ? (
                        <FaStar key={i} color="gold" />
                    ) : (
                        <FaRegStar key={i} color="gold" />
                    )
                ))}
            </div>
            <div className="d-flex justify-content-center mb-3">
                {mate.sharedWorlds?.map((world) => (
                                                 <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                        {world.thumbnail &&
                            <img
                                key={world._id}
                                src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                alt={world.name || 'World'}
                                title={world.name || 'World'}
                                className="rounded-circle"
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    objectFit: 'contain',
                                    margin: '5px',
                                }}
                            />
                        }
                    </a>
                ))}
            </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
            <Button variant="success" onClick={() => handleAcceptRequest(mate._id)}>
                Accept 
            </Button>
            <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                Message
            </Button>
        </Card.Footer>
    </Card>
    </CardContainer>
</Col>

                );
            })}
        </Row>
    </Container>
</ContentWrapper>

        )}

        {activeSubTab === 'currentMates' && (

<ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>
        
        <Row>
            
            {currentMates.map((mate) => {
                const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                return (
<Col
    key={mate._id}
    xs={12}
    sm={currentMates.length === 1 ? 8 : 6}
    md={currentMates.length === 1 ? 6 : 4}
    lg={currentMates.length === 1 ? 4 : 3}
    className="mb-4"
    style={currentMates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
>
    <CardContainer>
    <Card
        style={{
            minHeight: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
    >
        {mate.picture && (
                                  <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <div className="text-center mt-3">
                <Card.Img
                    variant="top"
                    src={mate.picture || '/images/default-profile.jpg'}
                    alt={`${mate.name || 'Mate'}'s profile`}
                    className="rounded-circle mx-auto"
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                        border: '2px solid #ccc',
                    }}
                />
            </div>
            </a>
        )}
        <Card.Body style={{ flex: 1 }}>
        <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`${mate.name} ${mate.surname}`}
            </Card.Title>
            </a>
            <Card.Text className="text-center">
                Total Cash: ${mate.totalCash || 0}
            </Card.Text>
            <div className="d-flex justify-content-center mb-3">
                {Array.from({ length: 5 }, (_, i) => (
                    i < averageRate ? (
                        <FaStar key={i} color="gold" />
                    ) : (
                        <FaRegStar key={i} color="gold" />
                    )
                ))}
            </div>
            <div className="d-flex justify-content-center mb-3">
                {mate.sharedWorlds?.map((world) => (
                                                <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                        {world.thumbnail &&
                            <img
                                key={world._id}
                                src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                alt={world.name || 'World'}
                                title={world.name || 'World'}
                                className="rounded-circle"
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    objectFit: 'contain',
                                    margin: '5px',
                                }}
                            />
                        }
                    </a>
                ))}
            </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">

            <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                Message
            </Button>
        </Card.Footer>
    </Card>
    </CardContainer>
</Col>

                );
            })} 
        </Row>
    </Container>
</ContentWrapper>

        )}
    </>
)}



{activeTab === 'posts' && (
<>

<SubTabContainer>
            <SubTab active={activeSubTab === 'explorePosts'} onClick={() => setActiveSubTab('explorePosts')}>
                Explore Posts
            </SubTab>
            <SubTab active={activeSubTab === 'myPosts'} onClick={() => setActiveSubTab('myPosts')}>
                My Posts
            </SubTab>
            <SubTab active={activeSubTab === 'savedPosts'} onClick={() => setActiveSubTab('savedPosts')}>
                Saved Posts
            </SubTab>
        </SubTabContainer>

        { activeSubTab === 'explorePosts' &&
            <ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>

   

      <h3 className="mt-5">Posts</h3>
      <Row>
        {
            Aposts.length === 0 &&     <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-2xl shadow-lg p-6 max-w-md text-center">
        {/* PNG Image */}
 
        {/* Text */}
        <h2 className="text-xl font-semibold mb-2 text-gray-800">
          You should <span className="text-green-500">subscribe to some world</span> to get posts!
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Discover worlds, connect with people, and grow your knowledge together.
        </p>
        {/* CTA Button */}
        <Button
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600"
          onClick={() => setActiveTab("worlds")}
        >
          Explore Worlds
        </Button>
      </div>
    </div>
        }
        {Aposts.map((post) => (
          <Col md={4} key={post._id} className="mb-4">
            <CardContainer>
            <Card>
            <Card.Header className="d-flex align-items-center">
{        post.poster.picture &&                    <a href={`/profile/${post.poster._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${post.poster.picture.picture.contentType};base64,${bufferToBase64(post.poster.picture.picture.data.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         post.poster.name &&               <a
                            href={`/profile/${post.poster._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {post.poster.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < post.poster.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>
              {post.thumbnail && (
                <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
              )}
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                <Button variant="primary" >
                  Like
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
              
                >
                  Comment
                </Button>
                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleShowModal(post)}
                >
                  View attachements
                </Button>
              </Card.Body>
            </Card>
            </CardContainer>
          </Col>
        ))}
      </Row>

      {/* Modal */}
      <Modal
  show={showModal}
  onHide={handleCloseModal}
  centered
  style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
>
  <Modal.Header closeButton>
    <Modal.Title style={{ fontSize: "1.5rem" }}>Resources for {selectedPost?.title}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <ListGroup variant="flush">
      {resources?.map((res, idx) => (
        <ListGroup.Item
          key={idx}
          style={{
            fontSize: "1.2rem", // Larger text
            padding: "1.5rem", // Extra padding for better appearance
          }}
        >
          {res.type}:{" "}
          {res.type === "photo" && (
            <img
              src={res.content}
              alt="Photo"
              style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
            />
          )}
          {res.type === "video" && (
            <video
              controls
              style={{
                maxWidth: "100%",
                display: "block",
                margin: "10px auto",
              }}
            >
              <source src={res.content} type="video/mp4" />
            </video>
          )}
          {res.type === "pdf" && (
            <a
              href={res.content}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
            >
              View PDF
            </a>
          )}
          {res.type === "text" && (
            <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
          )}
          {res.type === "quiz" && (
            <>
              <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
              <ul style={{ paddingLeft: "20px" }}>
                {res.choices?.map((choice, i) => (
                  <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                    {choice}
                  </li>
                ))}
              </ul>
              <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                Correct Answer: {res.correctAnswer}
              </p>
            </>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="secondary"
      onClick={handleCloseModal}
      style={{
        padding: "0.75rem 1.5rem",
        fontSize: "1.2rem", // Larger button
      }}
    >
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </Container>
    </ContentWrapper>

    }




                    { activeSubTab === 'myPosts' &&
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
        
           
        
              <h3 className="mt-5">My Posts</h3>
              <Row>
                {ASposts.map((post) => (
                  <Col md={4} key={post._id} className="mb-4">
                    <CardContainer>
                    <Card>
                      {post.thumbnail && (
                        <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
                      )}
                      <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>{post.description}</Card.Text>
                        <Button variant="primary" >
                          Like
                        </Button>
                        <Button
                          variant="danger"
                          className="ms-2"
                      
                        >
                          Comment
                        </Button>
                        <Button
                          variant="info"
                          className="ms-2"
                          onClick={() => handleShowModal(post)}
                        >
                          Save
                        </Button>
                        <Button
                          variant="info"
                          className="ms-2"
                          onClick={() => handleShowModal(post)}
                        >
                          View attachements
                        </Button>
                      </Card.Body>
                    </Card>
                    </CardContainer>
                  </Col>
                ))}
              </Row>
        
              {/* Modal */}
              <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup variant="flush">
              {resources?.map((res, idx) => (
                <ListGroup.Item
                  key={idx}
                  style={{
                    fontSize: "1.2rem", // Larger text
                    padding: "1.5rem", // Extra padding for better appearance
                  }}
                >
                  {res.type}:{" "}
                  {res.type === "photo" && (
                    <img
                      src={res.content}
                      alt="Photo"
                      style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
                    />
                  )}
                  {res.type === "video" && (
                    <video
                      controls
                      style={{
                        maxWidth: "100%",
                        display: "block",
                        margin: "10px auto",
                      }}
                    >
                      <source src={res.content} type="video/mp4" />
                    </video>
                  )}
                  {res.type === "pdf" && (
                    <a
                      href={res.content}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
                    >
                      View PDF
                    </a>
                  )}
                  {res.type === "text" && (
                    <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
                  )}
                  {res.type === "quiz" && (
                    <>
                      <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
                      <ul style={{ paddingLeft: "20px" }}>
                        {res.choices?.map((choice, i) => (
                          <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                            {choice}
                          </li>
                        ))}
                      </ul>
                      <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Correct Answer: {res.correctAnswer}
                      </p>
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{
                padding: "0.75rem 1.5rem",
                fontSize: "1.2rem", // Larger button
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        
            </Container>
            </ContentWrapper>}



                    { activeSubTab === 'savedPosts' &&
 <ContentWrapper sidebarVisible={sidebarVisible}>
 <Container fluid>



   <h3 className="mt-5">Posts</h3>
   <Row>
     {ASVposts.map((post) => (
       <Col md={4} key={post._id} className="mb-4">
        <CardContainer>
         <Card>
           {post.thumbnail && (
             <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
           )}
           <Card.Body>
             <Card.Title>{post.title}</Card.Title>
             <Card.Text>{post.description}</Card.Text>
             <Button variant="primary" >
               Like
             </Button>
             <Button
               variant="danger"
               className="ms-2"
           
             >
               Comment
             </Button>
             <Button
               variant="info"
               className="ms-2"
               onClick={() => handleUnSavePost(post._id)}
             >
               Unsave
             </Button>
             <Button
               variant="info"
               className="ms-2"
               onClick={() => handleShowModal(post)}
             >
               View attachements
             </Button>
           </Card.Body>
         </Card>
         </CardContainer>
       </Col>
     ))}
   </Row>

   {/* Modal */}
   <Modal
show={showModal}
onHide={handleCloseModal}
centered
style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
>
<Modal.Header closeButton>
 <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
</Modal.Header>
<Modal.Body>
 <ListGroup variant="flush">
   {resources?.map((res, idx) => (
     <ListGroup.Item
       key={idx}
       style={{
         fontSize: "1.2rem", // Larger text
         padding: "1.5rem", // Extra padding for better appearance
       }}
     >
       {res.type}:{" "}
       {res.type === "photo" && (
         <img
           src={res.content}
           alt="Photo"
           style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
         />
       )}
       {res.type === "video" && (
         <video
           controls
           style={{
             maxWidth: "100%",
             display: "block",
             margin: "10px auto",
           }}
         >
           <source src={res.content} type="video/mp4" />
         </video>
       )}
       {res.type === "pdf" && (
         <a
           href={res.content}
           target="_blank"
           rel="noopener noreferrer"
           style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
         >
           View PDF
         </a>
       )}
       {res.type === "text" && (
         <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
       )}
       {res.type === "quiz" && (
         <>
           <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
           <ul style={{ paddingLeft: "20px" }}>
             {res.choices?.map((choice, i) => (
               <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                 {choice}
               </li>
             ))}
           </ul>
           <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
             Correct Answer: {res.correctAnswer}
           </p>
         </>
       )}
     </ListGroup.Item>
   ))}
 </ListGroup>
</Modal.Body>
<Modal.Footer>
 <Button
   variant="secondary"
   onClick={handleCloseModal}
   style={{
     padding: "0.75rem 1.5rem",
     fontSize: "1.2rem", // Larger button
   }}
 >
   Close
 </Button>
</Modal.Footer>
</Modal>

 </Container>
 </ContentWrapper>}
                    </>
                )}

{
    loading && (

        <div
        className="loading-circle"
        style={{
            position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
            zIndex: 1000, // Optional: Ensure it stays on top of other elements
        }}
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#27ae60"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="90 150"
                strokeDashoffset="0"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
    )
}


                </MainContent>
            </ContentContainer>

            <Modal show={showCommentsModal} onHide={closeCommentsModal}>
  <Modal.Header closeButton>
    <Modal.Title>Comments</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedWorldComments?.length > 0 ? (
      selectedWorldComments.map((comment) => (
        <div
          key={comment._id}
          style={{
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a
            href={`/profile/${comment?.commenter?.[0]?._id}`} 
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              marginRight: '10px',
            }}
          >
            <img
                                                src={`data:${comment?.commenter?.[0]?.picture.contentType};base64,${bufferToBase64(comment?.commenter?.[0]?.picture.picture.data.data)}`}

              alt="Commenter"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            />
          </a>
          <div>
            <a
              href={`/profile/${comment?.commenter?.[0]?._id}`} 
              style={{
                textDecoration: 'none',
                color: '#007bff',
                fontWeight: 'bold',
              }}
            >
              {comment?.commenter?.[0]?.name || 'Unknown'}
            </a>
            <p style={{ margin: 0 }}>
              <a
                href={`/profile/${comment?.commenter?.[0]?._id}`} 
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                ⭐ {comment?.commenter?.[0]?.rate || 'No rating'}
              </a>
            </p>
            <p style={{ marginTop: '5px' }}>
            {comment?.comment?.length > maxLength && !isExpanded
                ? `${comment.comment.substring(0, maxLength)}...`
                : comment.comment}
            {comment?.comment?.length > maxLength && (
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        marginLeft: '5px',
                        textDecoration: 'underline'
                    }}
                    onClick={toggleExpand}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </span>
            )}
        </p>
          </div>
        </div>
      ))
    ) : (
      <p>No comments available for this world!</p>
    )}
  </Modal.Body>
</Modal>
        </Container>
        </PageContainer>
    );
}

export default Worlds;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
 
    
  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;





















const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 400px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;





const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;






const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;



const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height for scrolling */
 
    box-sizing: border-box;
`;

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';
// import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard } from 'react-icons/fa';
// import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { setWithId, setWorldId } from '../../../../reducers/reducers';
// import { v4 as uuidv4 } from 'uuid';
// function Worlds() {
//     let filteredWorlds;
//     const [worlds, setWorlds] = useState([]);
//     const [activeSubTab, setActiveSubTab] = useState('exploreWorlds');
//     const [matesRequests, setMatesRequests] = useState([]);
// const [currentMates, setCurrentMates] = useState([]);
// const [myUploads, setMyUploads] = useState([])
// const [subscribedInWorlds, setSubscribedInWorlds] = useState([])
//     const [activeTab, setActiveTab] = useState('worlds');
//     const [posts, setPosts] = useState([])
//     const [Aposts, setAPosts] = useState([])
//     const [ASposts, setASPosts] = useState([])
//     const [ASVposts, setASVPosts] = useState([])
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [searchQuery, setSearchQuery] = useState('');
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [messages, setMessages] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(null);
//     const [sidebarVisible, setSidebarVisible] = useState(true);
//     const [myEarning, setMyEarning] = useState('');
//     const [myRate, setMyRate] = useState('');
//     const [myCart, setMyCart] = useState([]);
//     const [selectedWorldComments, setSelectedWorldComments] = useState([]);
//     const [showCommentsModal, setShowCommentsModal] = useState(false);
//     const [mates, setMates] = useState([])
//     const [totalPrice, setTotalPrice] = useState(0);
//     const [resources, setResources] = useState([])
//   const [showModal, setShowModal] = useState(false);
//   const [selectedPost, setSelectedPost] = useState(null);

//     const [relativePeople, setRelativePeople] = useState([]);
//     const [filters, setFilters] = useState({
//         category: '',
//         priceRange: [0, 1000], // Example: [minPrice, maxPrice]
//         minRate: 0,
//         publishDateRange: [null, null], // [startDate, endDate]
//         minStudents: 0,
//         allowAdvisors: null, // true, false, or null for any
//         minAdvisors: 0,
//     });
    
//     const [categories, setCategories] = useState([
//         'Technology', 'Business', 'Art', 'Education', 'Health', 'Science'
//     ]); // Example categories
//     const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
//     const handleMateTab = () => {
//         setActiveTab('mates')
//         setActiveSubTab('exploreMates')
        
//     }

//     const handlePostsTab = () => {
//         setActiveTab('posts')
//         setActiveSubTab('explorePosts')
        
//     }

//     const handleShowModal = (post) => {
//         setSelectedPost(post);
//         setResources([
//           ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
//           ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
//           ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
//           ...(post.texts || []).map((item) => ({ type: "text", content: item })),
//           ...(post.quizs || []).map((item) => ({
//             type: "quiz",
//             content: item.content,
//             choices: item.choices,
//             correctAnswer: item.correctAnswer,
//           })),
//         ]);
    
//         console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
//         setShowModal(true);
//       };
//       const handleCloseModal = () => setShowModal(false);



//       useEffect(() => {
//         const fetchWorlds = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
//                 setWorlds(response.data);
//                 console.log('response.data from fetchWorlds ===========================-=-=-=---------------------------------------------------=-=--=->  > > > >> > > > >>  ',response.data)
//             } catch (error) {
//                 console.error('Error fetching worlds:', error);
//             }
//         };

//         const fetchProfilePicture = async () => {
//             try {
//                 const response = await axios.get(
//                     'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
//                     { responseType: 'blob' }
//                 );
        
//                 console.log('Fetched blob:', response.data);
        
//                 if (response.data.size > 0) {
//                     // Blob has data, create an object URL
//                     const url = URL.createObjectURL(response.data);
//                     setProfilePictureSrc(url);
//                 } else {
//                     // Blob is empty, use the Firebase image URL
//                     setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
//                 }
//             } catch (error) {
//                 console.error('Error fetching profile picture:', error);
//                 // Optionally set a fallback image if there's an error
//                 setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
//             }
//         };
        
//         const getMates = async() => {

//             const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
//             setMates(response.data)
//         }
//         const getMateRequests = async() => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getMateRequests')
//             setMatesRequests(response.data)
//             console.log('response.data from getMateRequests ==================-=-=-=-==========-=-=-=-=-=-> > > >  > > > >  ',response.data)
//          }
//          const getPosts = async() => {
//             const response =  await axios.get('https://sell-skill.com/api/endpoints/WgetPosts')
//             setPosts(response.data)
//           }
//           const fetchMyWorlds = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
//                 setMyUploads(response.data);

//             } catch (error) {
//                 console.error('Error fetching worlds:', error);
//             }
//         };

//         const fetchSubscribedInWorlds = async () => {
//             try {
//               const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn');
//               setSubscribedInWorlds(response.data);
//               console.log('response.data from get subscribtions===========================-=-=-==-==-=-=-=-=-=-=--==-=-=-==-=---------------------->>> > > > >> > > >  > >>  ',response.data)
//             } catch (error) {
//               console.error('Error fetching worlds:', error);
//             }
//           };
//           const getCurrentMates = async() => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getCurrentMates')
//             setCurrentMates(response.data)
//             console.log('response.data from getMateRequests =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
//          }

//          const ASVGetPosts = async () => {
 
//             const response = await axios.get(
//               "https://sell-skill.com/api/endpoints/ASVgetPosts"
           
//             );
//             console.log('response.data from ASV getPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
//             setASVPosts(response.data);
    
//             console.log('response.data from ASVgetPosts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)

//         };

//         const AgetPosts = async () => {
//             try {
//               const response = await axios.get(
//                 "https://sell-skill.com/api/endpoints/AgetPosts"
             
//               );
//               console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
//               setAPosts(response.data);
      
//               console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
//             } catch (error) {
//               console.error("Error fetching posts:", error);
//             }
//           };

//           const fetchRelativePeople = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/relativePeople');
//                 setRelativePeople(response.data);
//             } catch (error) {
//                 console.error('Error fetching relative people:', error);
//             }
//         };
//             fetchWorlds();
//         fetchRelativePeople();
//           AgetPosts();
//         ASVGetPosts();
//          getCurrentMates()
//           fetchSubscribedInWorlds();
//         getMates();

//         fetchProfilePicture();
//         getPosts()
//         getMateRequests()
//         fetchMyWorlds();
//     }, []);



//     const handleAddMate = async (mateId) => {
//         const mateRequestId = uuidv4();
//         await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId, mateRequestId})
//         console.log(`Add mate with ID: ${mateId}`);

//         // Add your logic here
//     };
           
//     const handleMessage = (mateId) => {
//         console.log(`Message mate with ID: ${mateId}`);
//         // Add your logic here
//     };
    

//     const handleAcceptRequest = async( mateId) => {
//         console.log('mateId from handleAcceptRequest ===================================================> > > > > ', mateId)
//         await axios.patch('https://sell-skill.com/api/endpoints/acceptMateRequest', {mateId})
//     }
//     const  handleDeclineRequest = async() => {

//     }
//     const clearAllCookies = () => {
//         document.cookie.split(";").forEach((cookie) => {
//             const cookieParts = cookie.split("=");
//             const cookieName = cookieParts[0].trim();
//             document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
//         });
//     };
//     const handleUnSavePost = async(postId) => {
//         await axios.delete('https://sell-skill.com/api/endpoints/unSavePost', {postId})
//     }
//     const handleLogout = async () => {
//         // Clear localStorage
//         console.log("Before clearing localStorage:", localStorage);
//         localStorage.clear();
//         console.log("After clearing localStorage:", localStorage);
    
//         // Clear specific keys if clear() doesn't work
//         localStorage.removeItem("authenticated");
//         localStorage.removeItem("permission");
//         localStorage.removeItem("skillerToken");
//         localStorage.removeItem("lastExternalReferrer");
//         localStorage.removeItem("lastExternalReferrerTime");
    
//         // Clear sessionStorage
//         sessionStorage.clear();
    
//         // Clear all cookies
//         const clearAllCookies = () => {
//             document.cookie.split(";").forEach((cookie) => {
//                 const cookieName = cookie.split("=")[0].trim();
//                 document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
//                 document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
//             });
//         };
//         clearAllCookies();
    
//         console.log("Cookies after clearing:", document.cookie);
    
//         // Delay to ensure clearing is complete
//         await new Promise((resolve) => setTimeout(resolve, 100));
    
//         // Redirect to login page
//         navigate("/skiller-signIn");
//     };
    

//       useEffect(() => {
//         const getPosts = async () => {
//           try {
//             const response = await axios.get(
//               "https://sell-skill.com/api/endpoints/ASgetPosts"
           
//             );
//             console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
//             setASPosts(response.data);
    
//             console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
//           } catch (error) {
//             console.error("Error fetching posts:", error);
//           }
//         };
//         getPosts();
//       },[]);  


 
//     useEffect(() => {
//         const getCart = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getCart');
//                 setMyCart(response.data);
//                 const total = response.data.reduce((sum, world) => sum + (world.price || 0), 0);
//                 setTotalPrice(total); // Update the state
//             } catch (error) {
//                 console.error('Error fetching cart:', error);
//             }
//         };
//         getCart();
//     }, []);
    


//     const toggleSidebar = () => {
//         setSidebarVisible(!sidebarVisible);
//     };

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const toggleSection = (section) => {
//         setExpandedSection(expandedSection === section ? null : section);
//     };


//          const handleWorldClickFromMyCart = (worldId) => {
//          dispatch(setWorldId(worldId))
//          navigate('/levels')
//      }
//      const handleNameClick = (withId) => {
//          dispatch(setWithId(withId));
//          navigate('/chat');
//      };

//           useEffect(() => {
//       const getSendersInfo = async () => {
//              const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
//              setMessages(response.data);
//              console.log('senders ==========>>', response.data);
//          };
//          getSendersInfo();
//      }, []);
//     useEffect(() => {
//         const myEarning = async () => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
//             setMyEarning(response.data);
//         };
//         myEarning();
//     }, []);


//     useEffect(() => {
//         const myRate = async () => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
//             setMyRate(response.data);
//         };
//         myRate();
//     }, []);


//     const handleSearch = (e) => {
//         setSearchQuery(e.target.value);
//     };

//       filteredWorlds = worlds.filter((world) =>
//         world.worldName.toLowerCase().includes(searchQuery.toLowerCase())
//     );



//      filteredWorlds = worlds.filter((world) => {
//         const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
//         return (
//             // Skip category filter if "Any" is selected (empty string)
//             (!category || world.category?.toLowerCase() === category.toLowerCase()) &&
    
//             // Skip price range filter if both values are default
//             (priceRange[0] === 0 && priceRange[1] === 1000 || 
//                 (priceRange[0] <= world.price && world.price <= priceRange[1])) &&
    
//             // Skip rating filter if set to 0 (Any)
//             (minRate === 0 || 
//                 (world.rates.reduce((sum, r) => sum + r.rate, 0) / world.rates.length) >= minRate) &&
    
//             // Skip publish date filter if range is not set
//             (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
//             (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]) &&
    
//             // Skip minimum students filter if set to 0 (Any)
//             (minStudents === 0 || world.numberOfStudents >= minStudents) &&
    
//             // Skip allow advisors filter if set to null (Any)
//             (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
    
//             // Skip minimum advisors filter if set to 0 (Any)
//             (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors)
//         );
//     });
    
    

//     const handleGetInWorld = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/levels');
//     };

//     const handleViewWorldTrailer = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/world-trailer');
//     };

//     const handleAddToCart = async (worldId) => {
//         await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
//     };

//     const handleWorldComments = (comments) => {
//         setSelectedWorldComments(comments);
//         setShowCommentsModal(true);
//     };

//     const closeCommentsModal = () => {
//         setShowCommentsModal(false);
//     };
//     const handleToggleWorldsSection = () => {
//         setActiveTab('worlds')
//         setActiveSubTab('exploreWorlds')
//     }

//     const handlePurchaseWorldFromCart = (worldId) => {
//         dispatch(setWorldId(worldId))
//         navigate('/levels')
//     }

//     const handleDeleteWorldFromCart = async(worldId) => {
//         await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
//     }

//     const handleProfileClick = () => {
//         setShowDropdown((prev) => !prev);
//     };

//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setShowDropdown(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);
//     const [opacity, setOpacity] = useState(1);

//     useEffect(() => {
//       const handleScroll = () => {
//         // Calculate opacity based on scroll position
//         const maxScroll = 300; // Adjust based on when you want it to vanish completely
//         const currentScroll = window.scrollY;
//         const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
//         setOpacity(newOpacity);
//       };
  
//       window.addEventListener('scroll', handleScroll);
//       return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }, []);


    
//     return (
//         <Container>
//             <Header>
//                 <Navbar>
//                     <LeftNav>
//                         <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                         <NavLinks>
//                             <a href="/my-learning">My profile</a>
//                             <a href="/my-earning">My uploads</a>
//                             <a>withdraw cash</a>
//                             <a href="/my-cart">My Cart</a>
//                             <a href="/certifications">Certifications</a>
//                             <a href="/exams">Exams</a>
//                         </NavLinks>
//                     </LeftNav>
//                     <SearchBar>
//                         <FaSearch />
//                         <input
//                             type="text"
//                             placeholder="Search Worlds"
//                             value={searchQuery}
//                             onChange={handleSearch}
//                         />
//                     </SearchBar>
//                     <NavIcons>
//                         <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                         <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                         <div ref={dropdownRef}>
//                             {
//                                 profilePictureSrc &&             <ProfileWrapper onClick={handleProfileClick}>
//                                 <ProfilePicture
//                                     src={ profilePictureSrc }
//                                     alt="Profile"
//                                 />
//                             </ProfileWrapper>
//                             }

//             {showDropdown && (
//                 <DropdownMenu>
//                     <a href="/my-profile">My Profile</a>
//                     <a href="/manage-uploads">uploads management</a>
//                     <a href="/manage-financial"> financial management</a>
//                     <a href = "/skiller-signIn" onClick={()=>handleLogout}>Log Out</a>
//                 </DropdownMenu>
//             )}
//         </div>
//                     </NavIcons>
//                 </Navbar>
//             </Header>
      



//                         <ContentContainer>
//              {sidebarVisible && (
//          <Sidebar>

      

// {/* Category Filter */}
// <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
//     <h5 style={{ marginBottom: '20px', color: '#2c3e50' }}>Filters</h5>

//     {/* Category Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:</label>
//         <div className="position-relative">
//             <button 
//                 onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
//                 style={{
//                     background: 'white',
//                     border: '1px solid #ccc',
//                     padding: '10px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     width: '100%',
//                     alignItems: 'center',
//                     borderRadius: '8px',
//                 }}
//             >
//                 {filters.category || 'Any'}
//                 <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
//             </button>
//             {showCategoryDropdown && (
//                 <ul 
//                     style={{
//                         position: 'absolute',
//                         top: '100%',
//                         left: 0,
//                         width: '100%',
//                         background: 'white',
//                         border: '1px solid #ccc',
//                         borderRadius: '8px',
//                         listStyle: 'none',
//                         padding: 0,
//                         margin: 0,
//                         zIndex: 1000,
//                         maxHeight: '200px',
//                         overflowY: 'auto',
//                         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                     }}
//                 >
//                     <li 
//                         style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
//                         onClick={() => {
//                             setFilters({ ...filters, category: '' });
//                             setShowCategoryDropdown(false);
//                         }}
//                     >
//                         Any
//                     </li>
//                     {categories.map((cat) => (
//                         <li 
//                             key={cat} 
//                             style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
//                             onClick={() => {
//                                 setFilters({ ...filters, category: cat });
//                                 setShowCategoryDropdown(false);
//                             }}
//                         >
//                             {cat}
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     </div>

//     {/* Price Range Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Price Range:</label>
//         <div className="d-flex">
//             <input 
//                 type="number" 
//                 placeholder="Min" 
//                 className="form-control me-2" 
//                 onChange={(e) => setFilters({ 
//                     ...filters, 
//                     priceRange: [Number(e.target.value) || 0, filters.priceRange[1]] 
//                 })} 
//             />
//             <input 
//                 type="number" 
//                 placeholder="Max" 
//                 className="form-control" 
//                 onChange={(e) => setFilters({ 
//                     ...filters, 
//                     priceRange: [filters.priceRange[0], Number(e.target.value) || 1000] 
//                 })} 
//             />
//         </div>
//     </div>

//     {/* Rating Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Rating:</label>
//         <select
//             value={filters.minRate}
//             onChange={(e) => setFilters({ ...filters, minRate: Number(e.target.value) })}
//             className="form-select"
//         >
//             <option value="0">Any</option>
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="4">4</option>
//             <option value="5">5</option>
//         </select>
//     </div>

//     {/* Publish Date Range Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Publish Date:</label>
//         <div className="d-flex">
//             <input 
//                 type="date" 
//                 className="form-control me-2"
//                 onChange={(e) => setFilters({ 
//                     ...filters, 
//                     publishDateRange: [new Date(e.target.value), filters.publishDateRange[1]] 
//                 })} 
//             />

//         </div>

//         <div className="d-flex">
//         <input 
//                 type="date" 
//                 className="form-control"
//                 onChange={(e) => setFilters({ 
//                     ...filters, 
//                     publishDateRange: [filters.publishDateRange[0], new Date(e.target.value)] 
//                 })} 
//             />
            

//         </div>


//     </div>

//     {/* Minimum Students Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Students:</label>
//         <input 
//             type="number" 
//             value={filters.minStudents} 
//             className="form-control"
//             onChange={(e) => setFilters({ ...filters, minStudents: Number(e.target.value) })}
//             placeholder="Any"
//         />
//     </div>

//     {/* Allow Advisors Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Allow Advisors:</label>
//         <select 
//             value={filters.allowAdvisors === null ? 'any' : filters.allowAdvisors.toString()}
//             className="form-select"
//             onChange={(e) => setFilters({ ...filters, allowAdvisors: e.target.value === 'any' ? null : e.target.value === 'true' })}
//         >
//             <option value="any">Any</option>
//             <option value="true">Yes</option>
//             <option value="false">No</option>
//         </select>
//     </div>

//     {/* Minimum Advisors Filter */}
//     <div className="mb-3">
//         <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Advisors:</label>
//         <input 
//             type="number" 
//             value={filters.minAdvisors} 
//             className="form-control"
//             onChange={(e) => setFilters({ ...filters, minAdvisors: Number(e.target.value) })}
//             placeholder="Any"
//         />
//     </div>
// </div>


//  <SectionHeader onClick={() => toggleSection('myCart')}>
//                 {expandedSection === 'myCart' ? <FaChevronUp /> : <FaChevronDown />}
//                 My Cart
//             </SectionHeader>
//             {expandedSection === 'myCart' &&
//     myCart.map((world) => (
//         <div key={world._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
//             {world.worldName && (
//                 <Button
//                     onClick={() => handleWorldClickFromMyCart(world._id)}
//                     variant="light"
//                     style={{ flexGrow: 1, marginRight: '10px' }}
//                 >
//                     {world.worldName}
//                 </Button>
//             )}
//             {world.price && (
//                 <span style={{ marginRight: '10px' }}>${world.price.toFixed(2)}</span>
//             )}
// <FaCreditCard
//     onClick={() => handlePurchaseWorldFromCart(world._id)}
//     style={{ color: 'blue', cursor: 'pointer', marginRight: '10px' }}
//     title="Go to Payment"
// />
//             <FaTrash
//                 onClick={() => handleDeleteWorldFromCart(world._id)}
//                 style={{ color: 'red', cursor: 'pointer' }}
//                 title="Delete"
//             />
//         </div>
//     ))}

//             {expandedSection === 'myCart' && (
//                 <div style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'right' }}>
//                     Total Price: ${totalPrice.toFixed(2)}
//                 </div>
//             )}

//              <SectionHeader onClick={() => toggleSection('chats')}>
//                  {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
//                  My Chats
//              </SectionHeader>
//              {expandedSection === 'chats' &&
//                  messages.map((message) => (
//                      <div key={message._id}>
//                          {message.senderName && (
//                              <Button
//                                  onClick={() => handleNameClick(message.withId)}
//                                  variant="light"
//                                  style={{ width: '100%' }}
//                              >
//                                  {message.senderName}
//                              </Button>
//                          )}
//                      </div>
//                  ))}
//              <SectionHeader onClick={() => toggleSection('myEarning')}>
//              {expandedSection === 'myEarning' ? <FaChevronUp /> : <FaChevronDown />}
//                  MY Earning
                
//              </SectionHeader>
//              {expandedSection === 'myEarning' &&
//                (
//                      <div >
//                          {myEarning} $
//                      </div>
//                  )}

//  <SectionHeader onClick={() => toggleSection('myRate')}>
//      {expandedSection === 'myRate' ? <FaChevronUp /> : <FaChevronDown />}
//      My Rate
//  </SectionHeader>
//  {expandedSection === 'myRate' && (
//      <div>
//          {Array.from({ length: 5 }, (_, index) => {
//              if (myRate >= index + 1) {
//                  return <FaStar key={index} color="gold" />;
//              } else if (myRate > index && myRate < index + 1) {
//                  return <FaStarHalfAlt key={index} color="gold" />;
//              } else {
//                  return <FaRegStar key={index} color="gold" />;
//              }
//          })}
//      </div>
//  )}
//          </Sidebar>
//      )}
//      <ToggleSidebarButton onClick={toggleSidebar}>
//          {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
//      </ToggleSidebarButton>





//                 <MainContent style={{ marginTop: '200px', padding: '50px' }}>
//                 <Header>
//     <TabContainer>
//         <Tab
//             active={activeTab === 'worlds'}
//             onClick={handleToggleWorldsSection}
//         >
//             Explore Worlds
//         </Tab>
//         <Tab
//             active={activeTab === 'mates'}
//             onClick={ handleMateTab}
//         >
//             Explore Mates
//         </Tab>
//         <Tab active={activeTab === 'posts'} onClick={handlePostsTab}>
//                         Explore Posts
//                     </Tab>
//     </TabContainer>
// {  !showDropdown &&  <UploadButtonWrapper>
//         <UploadButton onClick={() => navigate('/add-world')}>
//             <FaUpload style={{ marginRight: '5px' }} /> Upload World
//         </UploadButton>
//     </UploadButtonWrapper>}
// </Header>


// {       activeTab === 'worlds' &&           ( 

//     <>
    
//     <SubTabContainer opacity={opacity}>
//             <SubTab active={activeSubTab === 'exploreWorlds'} onClick={() => setActiveSubTab('exploreWorlds')}>
//                 Explore Worlds
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('myUploads')}>
//                 My Uploads
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('myDrafts')}>
//                 My drafts
//             </SubTab>
//             <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('mySubscribtions')}>
//                 My Subscribtions
//             </SubTab>
//         </SubTabContainer>
    


// {activeSubTab === 'exploreWorlds' && (
//     <ContentWrapper sidebarVisible={sidebarVisible}>
//         <Container fluid>
//             <Row>
//                 {filteredWorlds.map((world, index) => {
//                     const totalRate = world.rates.reduce((sum, r) => sum + r.rate, 0);
//                     const averageRate = totalRate / world.rates.length || 0;

//                     return (
//                         <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
//                             <Card>
//                             <Card.Header className="d-flex align-items-center">
// {        world.publisher.picture &&                    <a href={`/profile/${world.publisher._id}`} style={{ textDecoration: 'none' }}>
//     <img
//         src={world.publisher.picture || '/images/default-profile.jpg'}
//         alt="Publisher Picture"
//         style={{
//             width: '40px',
//             height: '40px',
//             borderRadius: '50%',
//             marginRight: '10px',
//         }}
//     />
// </a>}

//                     <div>
// {         world.publisher.name &&               <a
//                             href={`/profile/${world.publisher._id}`}
//                             style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
//                         >
//                             {world.publisher.name}
//                         </a>}
//                         <div>
//                             {Array.from({ length: 5 }, (_, i) => (
//                                 <FaStar
//                                     key={i}
//                                     size={12}
//                                     color={i < world.publisher.rate ? '#f39c12' : '#dcdcdc'}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 </Card.Header>


// {   world.worldThumbnail  &&
//                                 <Card.Img
//                                     variant="top"
//                                     src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'}
//                                     alt="World Thumbnail"
//                                 />}
//                                 <Card.Body>
//                                     <Card.Title>{world.worldName}</Card.Title>
//                                     <Card.Text>{world.worldDescription}</Card.Text>
//                                     <Card.Text>Price: ${world.price}</Card.Text>
//                                 { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
//                                     <Card.Text>
//                                         <FaUserGraduate /> {world.numberOdStudents} Students
//                                     </Card.Text>
//                                     <Card.Text>
//                                         <FaChalkboardTeacher /> {world.numberOfAdvisors} Advisors
//                                     </Card.Text>
//                                     {world.isWorldAllowingAdvisors && (
//                                         <Card.Text>
//                                             <FaCheckCircle color="green" /> Allowing Advisors
//                                         </Card.Text>
//                                     )}
//                                     <Card.Text>
//                                         Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
//                                     </Card.Text>
//                                     <div className="mb-3">
//                                         {Array.from({ length: 5 }, (_, i) => (
//                                             <FaStar
//                                                 key={i}
//                                                 color={i < averageRate ? '#f39c12' : '#dcdcdc'}
//                                             />
//                                         ))}
//                                     </div>
//                                     <div className="d-flex justify-content-between">
//                                         <Button
//                                             variant="success"
//                                             onClick={() => handleGetInWorld(world._id)}
//                                         >
//                                             <FaArrowRight style={{ marginRight: '5px' }} />
//                                             Get In
//                                         </Button>
//                                         {world.allowedCard && (
//                                             <Button
//                                                 variant="primary"
//                                                 onClick={() => handleAddToCart(world._id)}
//                                             >
//                                                 <FaShoppingCart style={{ marginRight: '5px' }} />
//                                                 Add To Cart
//                                             </Button>
//                                         )}
//                                     </div>
//                                     <div className="d-flex justify-content-between mt-2">
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleViewWorldTrailer(world._id)}
//                                         >
//                                             <FaPlayCircle style={{ marginRight: '5px' }} />
//                                             View Trailer
//                                         </Button>
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleWorldComments(world.comments)}
//                                         >
//                                             <FaComments style={{ marginRight: '5px' }} />
//                                             Comments
//                                         </Button>
//                                     </div>
//                                 </Card.Body>   
//                             </Card>
//                         </Col>
//                     );
//                 })}
//             </Row>
//         </Container>
//     </ContentWrapper>
// )}




                        
// { activeSubTab === 'myUploads' &&      <ContentWrapper sidebarVisible={sidebarVisible}>
//         <Container fluid>
//             <Row>
//                 {myUploads.map((world, index) => {
//                     const totalRate = world.rates.reduce((sum, r) => sum + r.rate, 0);
//                     const averageRate = totalRate / world.rates.length || 0;

//                     return (
//                         <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
//                             <Card>
//                             <Card.Header className="d-flex align-items-center">
// {        world.publisher.picture &&                    <a href={`/profile/${world.publisher._id}`} style={{ textDecoration: 'none' }}>
//     <img
//         src={world.publisher.picture || '/images/default-profile.jpg'}
//         alt="Publisher Picture"
//         style={{
//             width: '40px',
//             height: '40px',
//             borderRadius: '50%',
//             marginRight: '10px',
//         }}
//     />
// </a>}

//                     <div>
// {         world.publisher.name &&               <a
//                             href={`/profile/${world.publisher._id}`}
//                             style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
//                         >
//                             {world.publisher.name}
//                         </a>}
//                         <div>
//                             {Array.from({ length: 5 }, (_, i) => (
//                                 <FaStar
//                                     key={i}
//                                     size={12}
//                                     color={i < world.publisher.rate ? '#f39c12' : '#dcdcdc'}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 </Card.Header>


// {   world.worldThumbnail  &&
//                                 <Card.Img
//                                     variant="top"
//                                     src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'}
//                                     alt="World Thumbnail"
//                                 />}
//                                 <Card.Body>
//                                     <Card.Title>{world.worldName}</Card.Title>
//                                     <Card.Text>{world.worldDescription}</Card.Text>
//                                     <Card.Text>Price: ${world.price}</Card.Text>
//                                 { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
//                                     <Card.Text>
//                                         <FaUserGraduate /> {world.numberOdStudents} Students
//                                     </Card.Text>
//                                     <Card.Text>
//                                         <FaChalkboardTeacher /> {world.numberOfAdvisors} Advisors
//                                     </Card.Text>
//                                     {world.isWorldAllowingAdvisors && (
//                                         <Card.Text>
//                                             <FaCheckCircle color="green" /> Allowing Advisors
//                                         </Card.Text>
//                                     )}
//                                     <Card.Text>
//                                         Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
//                                     </Card.Text>
//                                     <div className="mb-3">
//                                         {Array.from({ length: 5 }, (_, i) => (
//                                             <FaStar
//                                                 key={i}
//                                                 color={i < averageRate ? '#f39c12' : '#dcdcdc'}
//                                             />
//                                         ))}
//                                     </div>
//                                     <div className="d-flex justify-content-between">
//                                         <Button
//                                             variant="success"
//                                             onClick={() => handleGetInWorld(world._id)}
//                                         >
//                                             <FaArrowRight style={{ marginRight: '5px' }} />
//                                             Get In
//                                         </Button>
//                                         {world.allowedCard && (
//                                             <Button
//                                                 variant="primary"
//                                                 onClick={() => handleAddToCart(world._id)}
//                                             >
//                                                 <FaShoppingCart style={{ marginRight: '5px' }} />
//                                                 Add To Cart
//                                             </Button>
//                                         )}
//                                     </div>
//                                     <div className="d-flex justify-content-between mt-2">
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleViewWorldTrailer(world._id)}
//                                         >
//                                             <FaPlayCircle style={{ marginRight: '5px' }} />
//                                             View Trailer
//                                         </Button>
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleWorldComments(world.comments)}
//                                         >
//                                             <FaComments style={{ marginRight: '5px' }} />
//                                             Comments
//                                         </Button>
//                                     </div>
//                                 </Card.Body>   
//                             </Card>
//                         </Col>
//                     );
//                 })}
//             </Row>
//         </Container>
//     </ContentWrapper>}



                        
// { activeSubTab === 'mySubscribtions' &&      <ContentWrapper sidebarVisible={sidebarVisible}>
//         <Container fluid>
//             <Row>
//                 {subscribedInWorlds.map((world, index) => {
//                     const totalRate = world.rates.reduce((sum, r) => sum + r.rate, 0);
//                     const averageRate = totalRate / world.rates.length || 0;

//                     return (
//                         <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
//                             <Card>
//                             <Card.Header className="d-flex align-items-center">
// {        world.publisher.picture &&                    <a href={`/profile/${world.publisher._id}`} style={{ textDecoration: 'none' }}>
//     <img
//         src={world.publisher.picture || '/images/default-profile.jpg'}
//         alt="Publisher Picture"
//         style={{
//             width: '40px',
//             height: '40px',
//             borderRadius: '50%',
//             marginRight: '10px',
//         }}
//     />
// </a>}

//                     <div>
// {         world.publisher.name &&               <a
//                             href={`/profile/${world.publisher._id}`}
//                             style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
//                         >
//                             {world.publisher.name}
//                         </a>}
//                         <div>
//                             {Array.from({ length: 5 }, (_, i) => (
//                                 <FaStar
//                                     key={i}
//                                     size={12}
//                                     color={i < world.publisher.rate ? '#f39c12' : '#dcdcdc'}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 </Card.Header>


// {   world.worldThumbnail  &&
//                                 <Card.Img
//                                     variant="top"
//                                     src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'}
//                                     alt="World Thumbnail"
//                                 />}
//                                 <Card.Body>
//                                     <Card.Title>{world.worldName}</Card.Title>
//                                     <Card.Text>{world.worldDescription}</Card.Text>
//                                     <Card.Text>Price: ${world.price}</Card.Text>
//                                 { world.advisorAmount &&   <Card.Text>Adviosr pudget: ${world.advisorAmount}</Card.Text>}
//                                     <Card.Text>
//                                         <FaUserGraduate /> {world.numberOdStudents} Students
//                                     </Card.Text>
//                                     <Card.Text>
//                                         <FaChalkboardTeacher /> {world.numberOfAdvisors} Advisors
//                                     </Card.Text>
//                                     {world.isWorldAllowingAdvisors && (
//                                         <Card.Text>
//                                             <FaCheckCircle color="green" /> Allowing Advisors
//                                         </Card.Text>
//                                     )}
//                                     <Card.Text>
//                                         Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}
//                                     </Card.Text>
//                                     <div className="mb-3">
//                                         {Array.from({ length: 5 }, (_, i) => (
//                                             <FaStar
//                                                 key={i}
//                                                 color={i < averageRate ? '#f39c12' : '#dcdcdc'}
//                                             />
//                                         ))}
//                                     </div>
//                                     <div className="d-flex justify-content-between">
//                                         <Button
//                                             variant="success"
//                                             onClick={() => handleGetInWorld(world._id)}
//                                         >
//                                             <FaArrowRight style={{ marginRight: '5px' }} />
//                                             Get In
//                                         </Button>
//                                         {world.allowedCard && (
//                                             <Button
//                                                 variant="primary"
//                                                 onClick={() => handleAddToCart(world._id)}
//                                             >
//                                                 <FaShoppingCart style={{ marginRight: '5px' }} />
//                                                 Add To Cart
//                                             </Button>
//                                         )}
//                                     </div>
//                                     <div className="d-flex justify-content-between mt-2">
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleViewWorldTrailer(world._id)}
//                                         >
//                                             <FaPlayCircle style={{ marginRight: '5px' }} />
//                                             View Trailer
//                                         </Button>
//                                         <Button
//                                             variant="outline-secondary"
//                                             onClick={() => handleWorldComments(world.comments)}
//                                         >
//                                             <FaComments style={{ marginRight: '5px' }} />
//                                             Comments
//                                         </Button>
//                                     </div>
//                                 </Card.Body>   
//                             </Card>
//                         </Col>
//                     );
//                 })}
//             </Row>
//         </Container>
//     </ContentWrapper>}
    
//     </>
    

                    
//                 ) } 


// {activeTab === 'mates' && (
//     <>
//         {/* Sub-tabs for mates */}
//         <SubTabContainer>
//             <SubTab active={activeSubTab === 'exploreMates'} onClick={() => setActiveSubTab('exploreMates')}>
//                 Explore Mates
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('matesRequests')}>
//                 Mates Requests
//             </SubTab>
//             <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('currentMates')}>
//                 Current Mates
//             </SubTab>
//         </SubTabContainer>

//         {/* Content based on the selected sub-tab */}
//         {activeSubTab === 'exploreMates' && (
//             <ContentWrapper sidebarVisible={sidebarVisible}>
//             <Container fluid>
                
//                 <Row>
                    
//                     {mates.map((mate) => {
//                         const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
//                         return (
//         <Col
//             key={mate._id}
//             xs={12}
//             sm={mates.length === 1 ? 8 : 6}
//             md={mates.length === 1 ? 6 : 4}
//             lg={mates.length === 1 ? 4 : 3}
//             className="mb-4"
//             style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
//         >
//             <Card
//                 style={{
//                     minHeight: '350px',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'space-between',
//                 }}
//             >
//                 {mate.picture && (
//                     <div className="text-center mt-3">
//                         <Card.Img
//                             variant="top"
//                             src={mate.picture || '/images/default-profile.jpg'}
//                             alt={`${mate.name || 'Mate'}'s profile`}
//                             className="rounded-circle mx-auto"
//                             style={{
//                                 width: '100px',
//                                 height: '100px',
//                                 objectFit: 'contain',
//                                 border: '2px solid #ccc',
//                             }}
//                         />
//                     </div>
//                 )}
//                 <Card.Body style={{ flex: 1 }}>
//                     <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                         {`${mate.name} ${mate.surname}`}
//                     </Card.Title>
//                     <Card.Text className="text-center">
//                         Total Cash: ${mate.totalCash || 0}
//                     </Card.Text>
//                     <div className="d-flex justify-content-center mb-3">
//                         {Array.from({ length: 5 }, (_, i) => (
//                             i < averageRate ? (
//                                 <FaStar key={i} color="gold" />
//                             ) : (
//                                 <FaRegStar key={i} color="gold" />
//                             )
//                         ))}
//                     </div>
//                     <div className="d-flex justify-content-center mb-3">
//                         {mate.sharedWorlds?.map((world) => (
//                             <>
//                                 {world.thumbnail && 
//                                     <img
//                                         key={world._id}
//                                         src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
//                                         alt={world.name || 'World'}
//                                         title={world.name || 'World'}
//                                         className="rounded-circle"
//                                         style={{
//                                             width: '50px',
//                                             height: '50px',
//                                             objectFit: 'contain',
//                                             margin: '5px',
//                                         }}
//                                     />
//                                 }
//                             </>
//                         ))}
//                     </div>
//                 </Card.Body>
//                 <Card.Footer className="d-flex justify-content-between">
//                     <Button variant="success" onClick={() => handleAddMate(mate._id)}>
//                         Add Mate
//                     </Button>
//                     <Button variant="primary" onClick={() => handleMessage(mate._id)}>
//                         Message
//                     </Button>
//                 </Card.Footer>
//             </Card>
//         </Col>
        
//                         );
//                     })}
//                 </Row>
//             </Container>
//         </ContentWrapper>



//         )}

        

//         {activeSubTab === 'matesRequests' && (
//             <ContentWrapper sidebarVisible={sidebarVisible}>
//     <Container fluid>
        
//         <Row>
            
//             {matesRequests.map((mate) => {
//                 const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
//                 return (
// <Col
//     key={mate._id}
//     xs={12}
//     sm={matesRequests.length === 1 ? 8 : 6}
//     md={matesRequests.length === 1 ? 6 : 4}
//     lg={matesRequests.length === 1 ? 4 : 3}
//     className="mb-4"
//     style={matesRequests.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
// >
//     <Card
//         style={{
//             minHeight: '350px',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-between',
//         }}
//     >
//         {mate.picture && (
//             <div className="text-center mt-3">
//                 <Card.Img
//                     variant="top"
//                     src={mate.picture || '/images/default-profile.jpg'}
//                     alt={`${mate.name || 'Mate'}'s profile`}
//                     className="rounded-circle mx-auto"
//                     style={{
//                         width: '100px',
//                         height: '100px',
//                         objectFit: 'contain',
//                         border: '2px solid #ccc',
//                     }}
//                 />
//             </div>
//         )}
//         <Card.Body style={{ flex: 1 }}>
//             <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                 {`${mate.name} ${mate.surname}`}
//             </Card.Title>
//             <Card.Text className="text-center">
//                 Total Cash: ${mate.totalCash || 0}
//             </Card.Text>
//             <div className="d-flex justify-content-center mb-3">
//                 {Array.from({ length: 5 }, (_, i) => (
//                     i < averageRate ? (
//                         <FaStar key={i} color="gold" />
//                     ) : (
//                         <FaRegStar key={i} color="gold" />
//                     )
//                 ))}
//             </div>
//             <div className="d-flex justify-content-center mb-3">
//                 {mate.sharedWorlds?.map((world) => (
//                     <>
//                         {world.thumbnail &&
//                             <img
//                                 key={world._id}
//                                 src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
//                                 alt={world.name || 'World'}
//                                 title={world.name || 'World'}
//                                 className="rounded-circle"
//                                 style={{
//                                     width: '50px',
//                                     height: '50px',
//                                     objectFit: 'contain',
//                                     margin: '5px',
//                                 }}
//                             />
//                         }
//                     </>
//                 ))}
//             </div>
//         </Card.Body>
//         <Card.Footer className="d-flex justify-content-between">
//             <Button variant="success" onClick={() => handleAddMate(mate._id)}>
//                 Add Mate
//             </Button>
//             <Button variant="primary" onClick={() => handleMessage(mate._id)}>
//                 Message
//             </Button>
//         </Card.Footer>
//     </Card>
// </Col>

//                 );
//             })}
//         </Row>
//     </Container>
// </ContentWrapper>

//         )}

//         {activeSubTab === 'currentMates' && (

// <ContentWrapper sidebarVisible={sidebarVisible}>
//     <Container fluid>
        
//         <Row>
            
//             {currentMates.map((mate) => {
//                 const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
//                 return (
// <Col
//     key={mate._id}
//     xs={12}
//     sm={currentMates.length === 1 ? 8 : 6}
//     md={currentMates.length === 1 ? 6 : 4}
//     lg={currentMates.length === 1 ? 4 : 3}
//     className="mb-4"
//     style={currentMates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
// >
//     <Card
//         style={{
//             minHeight: '350px',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-between',
//         }}
//     >
//         {mate.picture && (
//             <div className="text-center mt-3">
//                 <Card.Img
//                     variant="top"
//                     src={mate.picture || '/images/default-profile.jpg'}
//                     alt={`${mate.name || 'Mate'}'s profile`}
//                     className="rounded-circle mx-auto"
//                     style={{
//                         width: '100px',
//                         height: '100px',
//                         objectFit: 'contain',
//                         border: '2px solid #ccc',
//                     }}
//                 />
//             </div>
//         )}
//         <Card.Body style={{ flex: 1 }}>
//             <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                 {`${mate.name} ${mate.surname}`}
//             </Card.Title>
//             <Card.Text className="text-center">
//                 Total Cash: ${mate.totalCash || 0}
//             </Card.Text>
//             <div className="d-flex justify-content-center mb-3">
//                 {Array.from({ length: 5 }, (_, i) => (
//                     i < averageRate ? (
//                         <FaStar key={i} color="gold" />
//                     ) : (
//                         <FaRegStar key={i} color="gold" />
//                     )
//                 ))}
//             </div>
//             <div className="d-flex justify-content-center mb-3">
//                 {mate.sharedWorlds?.map((world) => (
//                     <>
//                         {world.thumbnail &&
//                             <img
//                                 key={world._id}
//                                 src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
//                                 alt={world.name || 'World'}
//                                 title={world.name || 'World'}
//                                 className="rounded-circle"
//                                 style={{
//                                     width: '50px',
//                                     height: '50px',
//                                     objectFit: 'contain',
//                                     margin: '5px',
//                                 }}
//                             />
//                         }
//                     </>
//                 ))}
//             </div>
//         </Card.Body>
//         <Card.Footer className="d-flex justify-content-between">
//             <Button variant="success" onClick={() => handleAddMate(mate._id)}>
//                 Add Mate
//             </Button>
//             <Button variant="primary" onClick={() => handleMessage(mate._id)}>
//                 Message
//             </Button>
//         </Card.Footer>
//     </Card>
// </Col>

//                 );
//             })}
//         </Row>
//     </Container>
// </ContentWrapper>

//         )}
//     </>
// )}



// {activeTab === 'posts' && (
// <>

// <SubTabContainer>
//             <SubTab active={activeSubTab === 'explorePosts'} onClick={() => setActiveSubTab('explorePosts')}>
//                 Explore Posts
//             </SubTab>
//             <SubTab active={activeSubTab === 'myPosts'} onClick={() => setActiveSubTab('myPosts')}>
//                 My Posts
//             </SubTab>
//             <SubTab active={activeSubTab === 'savedPosts'} onClick={() => setActiveSubTab('savedPosts')}>
//                 Saved Posts
//             </SubTab>
//         </SubTabContainer>

//         { activeSubTab === 'explorePosts' &&
//             <ContentWrapper sidebarVisible={sidebarVisible}>
//     <Container fluid>

   

//       <h3 className="mt-5">Posts</h3>
//       <Row>
//         {Aposts.map((post) => (
//           <Col md={4} key={post._id} className="mb-4">
//             <Card>
//               {post.thumbnail && (
//                 <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
//               )}
//               <Card.Body>
//                 <Card.Title>{post.title}</Card.Title>
//                 <Card.Text>{post.description}</Card.Text>
//                 <Button variant="primary" >
//                   Like
//                 </Button>
//                 <Button
//                   variant="danger"
//                   className="ms-2"
              
//                 >
//                   Comment
//                 </Button>
//                 <Button
//                   variant="info"
//                   className="ms-2"
//                   onClick={() => handleShowModal(post)}
//                 >
//                   View attachements
//                 </Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>

//       {/* Modal */}
//       <Modal
//   show={showModal}
//   onHide={handleCloseModal}
//   centered
//   style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
// >
//   <Modal.Header closeButton>
//     <Modal.Title style={{ fontSize: "1.5rem" }}>Resources for {selectedPost?.title}</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <ListGroup variant="flush">
//       {resources?.map((res, idx) => (
//         <ListGroup.Item
//           key={idx}
//           style={{
//             fontSize: "1.2rem", // Larger text
//             padding: "1.5rem", // Extra padding for better appearance
//           }}
//         >
//           {res.type}:{" "}
//           {res.type === "photo" && (
//             <img
//               src={res.content}
//               alt="Photo"
//               style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
//             />
//           )}
//           {res.type === "video" && (
//             <video
//               controls
//               style={{
//                 maxWidth: "100%",
//                 display: "block",
//                 margin: "10px auto",
//               }}
//             >
//               <source src={res.content} type="video/mp4" />
//             </video>
//           )}
//           {res.type === "pdf" && (
//             <a
//               href={res.content}
//               target="_blank"
//               rel="noopener noreferrer"
//               style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
//             >
//               View PDF
//             </a>
//           )}
//           {res.type === "text" && (
//             <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//           )}
//           {res.type === "quiz" && (
//             <>
//               <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//               <ul style={{ paddingLeft: "20px" }}>
//                 {res.choices?.map((choice, i) => (
//                   <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
//                     {choice}
//                   </li>
//                 ))}
//               </ul>
//               <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
//                 Correct Answer: {res.correctAnswer}
//               </p>
//             </>
//           )}
//         </ListGroup.Item>
//       ))}
//     </ListGroup>
//   </Modal.Body>
//   <Modal.Footer>
//     <Button
//       variant="secondary"
//       onClick={handleCloseModal}
//       style={{
//         padding: "0.75rem 1.5rem",
//         fontSize: "1.2rem", // Larger button
//       }}
//     >
//       Close
//     </Button>
//   </Modal.Footer>
// </Modal>

//     </Container>
//     </ContentWrapper>

//     }




//                     { activeSubTab === 'myPosts' &&
//             <ContentWrapper sidebarVisible={sidebarVisible}>
//             <Container fluid>
        
           
        
//               <h3 className="mt-5">Posts</h3>
//               <Row>
//                 {ASposts.map((post) => (
//                   <Col md={4} key={post._id} className="mb-4">
//                     <Card>
//                       {post.thumbnail && (
//                         <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
//                       )}
//                       <Card.Body>
//                         <Card.Title>{post.title}</Card.Title>
//                         <Card.Text>{post.description}</Card.Text>
//                         <Button variant="primary" >
//                           Like
//                         </Button>
//                         <Button
//                           variant="danger"
//                           className="ms-2"
                      
//                         >
//                           Comment
//                         </Button>
//                         <Button
//                           variant="info"
//                           className="ms-2"
//                           onClick={() => handleShowModal(post)}
//                         >
//                           Save
//                         </Button>
//                         <Button
//                           variant="info"
//                           className="ms-2"
//                           onClick={() => handleShowModal(post)}
//                         >
//                           View attachements
//                         </Button>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 ))}
//               </Row>
        
//               {/* Modal */}
//               <Modal
//           show={showModal}
//           onHide={handleCloseModal}
//           centered
//           style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
//         >
//           <Modal.Header closeButton>
//             <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <ListGroup variant="flush">
//               {resources?.map((res, idx) => (
//                 <ListGroup.Item
//                   key={idx}
//                   style={{
//                     fontSize: "1.2rem", // Larger text
//                     padding: "1.5rem", // Extra padding for better appearance
//                   }}
//                 >
//                   {res.type}:{" "}
//                   {res.type === "photo" && (
//                     <img
//                       src={res.content}
//                       alt="Photo"
//                       style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
//                     />
//                   )}
//                   {res.type === "video" && (
//                     <video
//                       controls
//                       style={{
//                         maxWidth: "100%",
//                         display: "block",
//                         margin: "10px auto",
//                       }}
//                     >
//                       <source src={res.content} type="video/mp4" />
//                     </video>
//                   )}
//                   {res.type === "pdf" && (
//                     <a
//                       href={res.content}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
//                     >
//                       View PDF
//                     </a>
//                   )}
//                   {res.type === "text" && (
//                     <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//                   )}
//                   {res.type === "quiz" && (
//                     <>
//                       <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//                       <ul style={{ paddingLeft: "20px" }}>
//                         {res.choices?.map((choice, i) => (
//                           <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
//                             {choice}
//                           </li>
//                         ))}
//                       </ul>
//                       <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
//                         Correct Answer: {res.correctAnswer}
//                       </p>
//                     </>
//                   )}
//                 </ListGroup.Item>
//               ))}
//             </ListGroup>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button
//               variant="secondary"
//               onClick={handleCloseModal}
//               style={{
//                 padding: "0.75rem 1.5rem",
//                 fontSize: "1.2rem", // Larger button
//               }}
//             >
//               Close
//             </Button>
//           </Modal.Footer>
//         </Modal>
        
//             </Container>
//             </ContentWrapper>}



//                     { activeSubTab === 'savedPosts' &&
//  <ContentWrapper sidebarVisible={sidebarVisible}>
//  <Container fluid>



//    <h3 className="mt-5">Posts</h3>
//    <Row>
//      {ASVposts.map((post) => (
//        <Col md={4} key={post._id} className="mb-4">
//          <Card>
//            {post.thumbnail && (
//              <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
//            )}
//            <Card.Body>
//              <Card.Title>{post.title}</Card.Title>
//              <Card.Text>{post.description}</Card.Text>
//              <Button variant="primary" >
//                Like
//              </Button>
//              <Button
//                variant="danger"
//                className="ms-2"
           
//              >
//                Comment
//              </Button>
//              <Button
//                variant="info"
//                className="ms-2"
//                onClick={() => handleUnSavePost(post._id)}
//              >
//                Unsave
//              </Button>
//              <Button
//                variant="info"
//                className="ms-2"
//                onClick={() => handleShowModal(post)}
//              >
//                View attachements
//              </Button>
//            </Card.Body>
//          </Card>
//        </Col>
//      ))}
//    </Row>

//    {/* Modal */}
//    <Modal
// show={showModal}
// onHide={handleCloseModal}
// centered
// style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
// >
// <Modal.Header closeButton>
//  <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
// </Modal.Header>
// <Modal.Body>
//  <ListGroup variant="flush">
//    {resources?.map((res, idx) => (
//      <ListGroup.Item
//        key={idx}
//        style={{
//          fontSize: "1.2rem", // Larger text
//          padding: "1.5rem", // Extra padding for better appearance
//        }}
//      >
//        {res.type}:{" "}
//        {res.type === "photo" && (
//          <img
//            src={res.content}
//            alt="Photo"
//            style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
//          />
//        )}
//        {res.type === "video" && (
//          <video
//            controls
//            style={{
//              maxWidth: "100%",
//              display: "block",
//              margin: "10px auto",
//            }}
//          >
//            <source src={res.content} type="video/mp4" />
//          </video>
//        )}
//        {res.type === "pdf" && (
//          <a
//            href={res.content}
//            target="_blank"
//            rel="noopener noreferrer"
//            style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
//          >
//            View PDF
//          </a>
//        )}
//        {res.type === "text" && (
//          <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//        )}
//        {res.type === "quiz" && (
//          <>
//            <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
//            <ul style={{ paddingLeft: "20px" }}>
//              {res.choices?.map((choice, i) => (
//                <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
//                  {choice}
//                </li>
//              ))}
//            </ul>
//            <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
//              Correct Answer: {res.correctAnswer}
//            </p>
//          </>
//        )}
//      </ListGroup.Item>
//    ))}
//  </ListGroup>
// </Modal.Body>
// <Modal.Footer>
//  <Button
//    variant="secondary"
//    onClick={handleCloseModal}
//    style={{
//      padding: "0.75rem 1.5rem",
//      fontSize: "1.2rem", // Larger button
//    }}
//  >
//    Close
//  </Button>
// </Modal.Footer>
// </Modal>

//  </Container>
//  </ContentWrapper>}
//                     </>
//                 )}
//                 </MainContent>
//             </ContentContainer>

//             <Modal show={showCommentsModal} onHide={closeCommentsModal}>
//   <Modal.Header closeButton>
//     <Modal.Title>Comments</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     {selectedWorldComments?.length > 0 ? (
//       selectedWorldComments.map((comment) => (
//         <div
//           key={comment._id}
//           style={{
//             marginBottom: '15px',
//             display: 'flex',
//             alignItems: 'center',
//           }}
//         >
//           <a
//             href={`/profile/${comment?.commenters?.[0]?._id}`} 
//             style={{
//               display: 'flex',
//               alignItems: 'center',
//               textDecoration: 'none',
//               color: 'inherit',
//               marginRight: '10px',
//             }}
//           >
//             <img
//               src={comment?.commenters?.[0]?.picture || '/images/default-commenter.jpg'}
//               alt="Commenter"
//               style={{
//                 width: '40px',
//                 height: '40px',
//                 borderRadius: '50%',
//                 marginRight: '10px',
//               }}
//             />
//           </a>
//           <div>
//             <a
//               href={`/profile/${comment?.commenters?.[0]?._id}`} 
//               style={{
//                 textDecoration: 'none',
//                 color: '#007bff',
//                 fontWeight: 'bold',
//               }}
//             >
//               {comment?.commenters?.[0]?.name || 'Unknown'}
//             </a>
//             <p style={{ margin: 0 }}>
//               <a
//                 href={`/profile/${comment?.commenters?.[0]?._id}`} 
//                 style={{
//                   textDecoration: 'none',
//                   color: 'inherit',
//                 }}
//               >
//                 ⭐ {comment?.commenters?.[0]?.rate || 'No rating'}
//               </a>
//             </p>
//             <p style={{ marginTop: '5px' }}>{comment?.comment || 'No comment text available.'}</p>
//           </div>
//         </div>
//       ))
//     ) : (
//       <p>No comments available for this world.</p>
//     )}
//   </Modal.Body>
// </Modal>
//         </Container>
//     );
// }

// export default Worlds;

// Styled Components
// Add unchanged styled components here...

// Styled Components






















// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';
// import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard } from 'react-icons/fa';
// import { Button, Modal } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { setWithId, setWorldId } from '../../../../reducers/reducers';
// import { v4 as uuidv4 } from 'uuid';
// function Worlds() {
//     const [worlds, setWorlds] = useState([]);
//     const [activeSubTab, setActiveSubTab] = useState('exploreWorlds');
//     const [matesRequests, setMatesRequests] = useState([]);
// const [currentMates, setCurrentMates] = useState([]);
// const [myUploads, setMyUploads] = useState([])
// const [subscribedInWorlds, setSubscribedInWorlds] = useState([])
//     const [activeTab, setActiveTab] = useState('worlds');
//     const [posts, setPosts] = useState([])
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [searchQuery, setSearchQuery] = useState('');
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [messages, setMessages] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(null);
//     const [sidebarVisible, setSidebarVisible] = useState(true);
//     const [myEarning, setMyEarning] = useState('');
//     const [myRate, setMyRate] = useState('');
//     const [myCart, setMyCart] = useState([]);
//     const [selectedWorldComments, setSelectedWorldComments] = useState([]);
//     const [showCommentsModal, setShowCommentsModal] = useState(false);
//     const [mates, setMates] = useState([])
//     const [totalPrice, setTotalPrice] = useState(0);

//     const [relativePeople, setRelativePeople] = useState([]);
//     const [filters, setFilters] = useState({
//         category: '',
//         priceRange: [0, 1000], // Example: [minPrice, maxPrice]
//         minRate: 0,
//         publishDateRange: [null, null], // [startDate, endDate]
//         minStudents: 0,
//         allowAdvisors: null, // true, false, or null for any
//         minAdvisors: 0,
//     });
    
//     const [categories, setCategories] = useState([
//         'Technology', 'Business', 'Art', 'Education', 'Health', 'Science'
//     ]); // Example categories
//     const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
//     const handleMateTab = () => {
//         setActiveTab('mates')
//         setActiveSubTab('exploreMates')
        
//     }

//     const handlePostsTab = () => {
//         setActiveTab('posts')
//         setActiveSubTab('explorePosts')
        
//     }
//     useEffect(() => {
//         const getMates = async() => {

//             const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
//             setMates(response.data)
//         }
//         getMates();
//     }, [])
    
//     useEffect(() => {
//         const getMateRequests = async() => {
//            const response = await axios.get('https://sell-skill.com/api/endpoints/getMateRequests')
//            setMatesRequests(response.data)
//            console.log('response.data from getMateRequests ==================-=-=-=-==========-=-=-=-=-=-> > > >  > > > >  ',response.data)
//         }
//         getMateRequests()
//     }, [])


//     useEffect(() => {
//         const getPosts = async() => {
//           const response =  await axios.get('https://sell-skill.com/api/endpoints/WgetPosts')
//           setPosts(response.data)
//         }
//         getPosts()
//     }, [])
//     useEffect(() => {
//         const fetchWorlds = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
//                 setMyUploads(response.data);

//             } catch (error) {
//                 console.error('Error fetching worlds:', error);
//             }
//         };
//         fetchWorlds();
//     }, []);
    

//     useEffect(() => {
//         const fetchWorlds = async () => {
//           try {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn');
//             setSubscribedInWorlds(response.data);
//             console.log('response.data from get subscribtions===========================-=-=-==-==-=-=-=-=-=-=--==-=-=-==-=---------------------->>> > > > >> > > >  > >>  ',response.data)
//           } catch (error) {
//             console.error('Error fetching worlds:', error);
//           }
//         };
    
//         fetchWorlds();
//       }, []);


//     useEffect(() => {
//         const getMateRequests = async() => {
//            const response = await axios.get('https://sell-skill.com/api/endpoints/getCurrentMates')
//            setCurrentMates(response.data)
//            console.log('response.data from getMateRequests =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
//         }
//         getMateRequests()
//     }, [])
//     const handleAddMate = async (mateId) => {
//         const mateRequestId = uuidv4();
//         await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId, mateRequestId})
//         console.log(`Add mate with ID: ${mateId}`);

//         // Add your logic here
//     };
           
//     const handleMessage = (mateId) => {
//         console.log(`Message mate with ID: ${mateId}`);
//         // Add your logic here
//     };
    

//     const handleAcceptRequest = async( mateId) => {
//         console.log('mateId from handleAcceptRequest ===================================================> > > > > ', mateId)
//         await axios.patch('https://sell-skill.com/api/endpoints/acceptMateRequest', {mateId})
//     }
//     const  handleDeclineRequest = async() => {

//     }
//     const clearAllCookies = () => {
//         document.cookie.split(";").forEach((cookie) => {
//             const cookieParts = cookie.split("=");
//             const cookieName = cookieParts[0].trim();
//             document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
//         });
//     };
    
//     const handleLogout = async () => {
//         // Clear localStorage
//         console.log("Before clearing localStorage:", localStorage);
//         localStorage.clear();
//         console.log("After clearing localStorage:", localStorage);
    
//         // Clear specific keys if clear() doesn't work
//         localStorage.removeItem("authenticated");
//         localStorage.removeItem("permission");
//         localStorage.removeItem("skillerToken");
//         localStorage.removeItem("lastExternalReferrer");
//         localStorage.removeItem("lastExternalReferrerTime");
    
//         // Clear sessionStorage
//         sessionStorage.clear();
    
//         // Clear all cookies
//         const clearAllCookies = () => {
//             document.cookie.split(";").forEach((cookie) => {
//                 const cookieName = cookie.split("=")[0].trim();
//                 document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
//                 document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
//             });
//         };
//         clearAllCookies();
    
//         console.log("Cookies after clearing:", document.cookie);
    
//         // Delay to ensure clearing is complete
//         await new Promise((resolve) => setTimeout(resolve, 100));
    
//         // Redirect to login page
//         navigate("/skiller-signIn");
//     };
    
        
    
//     useEffect(() => {
//         const getCart = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getCart');
//                 setMyCart(response.data);
//                 const total = response.data.reduce((sum, world) => sum + (world.price || 0), 0);
//                 setTotalPrice(total); // Update the state
//             } catch (error) {
//                 console.error('Error fetching cart:', error);
//             }
//         };
//         getCart();
//     }, []);
    


//     const toggleSidebar = () => {
//         setSidebarVisible(!sidebarVisible);
//     };

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const toggleSection = (section) => {
//         setExpandedSection(expandedSection === section ? null : section);
//     };


//          const handleWorldClickFromMyCart = (worldId) => {
//          dispatch(setWorldId(worldId))
//          navigate('/levels')
//      }
//      const handleNameClick = (withId) => {
//          dispatch(setWithId(withId));
//          navigate('/chat');
//      };

//           useEffect(() => {
//       const getSendersInfo = async () => {
//              const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
//              setMessages(response.data);
//              console.log('senders ==========>>', response.data);
//          };
//          getSendersInfo();
//      }, []);
//     useEffect(() => {
//         const myEarning = async () => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
//             setMyEarning(response.data);
//         };
//         myEarning();
//     }, []);


//     useEffect(() => {
//         const myRate = async () => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
//             setMyRate(response.data);
//         };
//         myRate();
//     }, []);
//     useEffect(() => {
//         const fetchWorlds = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
//                 setWorlds(response.data);
//                 console.log('response.data from fetchWorlds ===========================-=-=-=---------------------------------------------------=-=--=->  > > > >> > > > >>  ',response.data)
//             } catch (error) {
//                 console.error('Error fetching worlds:', error);
//             }
//         };

//         const fetchProfilePicture = async () => {
//             try {
//                 const response = await axios.get(
//                     'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
//                     { responseType: 'blob' }
//                 );
//                 const url = URL.createObjectURL(response.data);
//                 setProfilePictureSrc(url);
//             } catch (error) {
//                 console.error('Error fetching profile picture:', error);
//             }
//         };

//         fetchWorlds();
//         fetchProfilePicture();
//     }, []);

//     const handleSearch = (e) => {
//         setSearchQuery(e.target.value);
//     };

//     const filteredWorlds = worlds.filter((world) => {
//         const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
//         return (
//             // Skip category filter if "Any" is selected (empty string)
//             (!category || world.category?.toLowerCase() === category.toLowerCase()) &&
    
//             // Skip price range filter if both values are default
//             (priceRange[0] === 0 && priceRange[1] === 1000 || 
//                 (priceRange[0] <= world.price && world.price <= priceRange[1])) &&
    
//             // Skip rating filter if set to 0 (Any)
//             (minRate === 0 || 
//                 (world.rates.reduce((sum, r) => sum + r.rate, 0) / world.rates.length) >= minRate) &&
    
//             // Skip publish date filter if range is not set
//             (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
//             (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]) &&
    
//             // Skip minimum students filter if set to 0 (Any)
//             (minStudents === 0 || world.numberOfStudents >= minStudents) &&
    
//             // Skip allow advisors filter if set to null (Any)
//             (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
    
//             // Skip minimum advisors filter if set to 0 (Any)
//             (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors)
//         );
//     });
    
    

//     const handleGetInWorld = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/levels');
//     };

//     const handleViewWorldTrailer = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/world-trailer');
//     };

//     const handleAddToCart = async (worldId) => {
//         await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
//     };

//     const handleWorldComments = (comments) => {
//         setSelectedWorldComments(comments);
//         setShowCommentsModal(true);
//     };

//     const closeCommentsModal = () => {
//         setShowCommentsModal(false);
//     };


//     const handlePurchaseWorldFromCart = (worldId) => {
//         dispatch(setWorldId(worldId))
//         navigate('/levels')
//     }

//     const handleDeleteWorldFromCart = async(worldId) => {
//         await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
//     }

//     const handleProfileClick = () => {
//         setShowDropdown((prev) => !prev);
//     };

//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setShowDropdown(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);


//     useEffect(() => {
//         const fetchRelativePeople = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/relativePeople');
//                 setRelativePeople(response.data);
//             } catch (error) {
//                 console.error('Error fetching relative people:', error);
//             }
//         };
    
//         fetchRelativePeople();
//     }, []);

    
    
//     return (
//         <PageContainer>
//             <Header>
//                 <Navbar>
//                     <LeftNav>
//                         <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                         <NavLinks>
//                             <a href="/my-learning">My profile</a>
//                             <a href="/my-earning">My uploads</a>
//                             <a>withdraw cash</a>
//                             <a href="/my-cart">My Cart</a>
//                             <a href="/certifications">Certifications</a>
//                             <a href="/exams">Exams</a>
//                         </NavLinks>
//                     </LeftNav>
//                     <SearchBar>
//                         <FaSearch />
//                         <input
//                             type="text"
//                             placeholder="Search Worlds"
//                             value={searchQuery}
//                             onChange={handleSearch}
//                         />
//                     </SearchBar>
//                     <NavIcons>
//                         <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                         <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                         <div ref={dropdownRef}>
//             <ProfileWrapper onClick={handleProfileClick}>
//                 <ProfilePicture
//                     src={profilePictureSrc || '/images/NormalProfile.jpg'}
//                     alt="Profile"
//                 />
//             </ProfileWrapper>
//             {showDropdown && (
//                 <DropdownMenu>
//                     <a href="/my-profile">My Profile</a>
//                     <a href="/manage-uploads">uploads management</a>
//                     <a href="/manage-financial"> financial management</a>
//                     <a href = "/skiller-signIn" onClick={()=>handleLogout}>Log Out</a>
//                 </DropdownMenu>
//             )}
//         </div>
//                     </NavIcons>
//                 </Navbar>
//             </Header>
      



//                         <ContentContainer>
//              {sidebarVisible && (
//          <Sidebar>



// <SectionHeader>Filters</SectionHeader>
//     {/* Category Filter */}
//     <SectionHeader>Filters</SectionHeader>

// {/* Category Filter */}
// <div>
//     <label>Category:</label>
//     <div style={{ position: 'relative' }}>
//         <button 
//             onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
//             style={{
//                 background: 'white',
//                 border: '1px solid #ccc',
//                 padding: '5px',
//                 cursor: 'pointer',
//                 display: 'flex',
//                 alignItems: 'center',
//             }}
//         >
//             {filters.category || 'Any'}
//             <FaChevronDown style={{ marginLeft: '10px' }} />
//         </button>
//         {showCategoryDropdown && (
//             <ul 
//                 style={{
//                     position: 'absolute',
//                     top: '100%',
//                     left: 0,
//                     right: 0,
//                     background: 'white',
//                     border: '1px solid #ccc',
//                     listStyle: 'none',
//                     padding: 0,
//                     margin: 0,
//                     zIndex: 1000,
//                     maxHeight: '200px',
//                     overflowY: 'auto',
//                 }}
//             >
//                 <li 
//                     style={{ padding: '10px', cursor: 'pointer' }}
//                     onClick={() => {
//                         setFilters({ ...filters, category: '' });
//                         setShowCategoryDropdown(false);
//                     }}
//                 >
//                     Any
//                 </li>
//                 {categories.map((cat) => (
//                     <li 
//                         key={cat} 
//                         style={{ padding: '10px', cursor: 'pointer' }}
//                         onClick={() => {
//                             setFilters({ ...filters, category: cat });
//                             setShowCategoryDropdown(false);
//                         }}
//                     >
//                         {cat}
//                     </li>
//                 ))}
//             </ul>
//         )}
//     </div>
// </div>

// {/* Price Range Filter */}
// <div>
//     <label>Price Range:</label>
//     <div>
//         <input 
//             type="number" 
//             placeholder="Min" 
//             onChange={(e) => setFilters({ 
//                 ...filters, 
//                 priceRange: [Number(e.target.value) || 0, filters.priceRange[1]] 
//             })} 
//         />
//         <input 
//             type="number" 
//             placeholder="Max" 
//             onChange={(e) => setFilters({ 
//                 ...filters, 
//                 priceRange: [filters.priceRange[0], Number(e.target.value) || 1000] 
//             })} 
//         />
//     </div>
// </div>

// {/* Rating Filter */}
// <div>
//     <label>Minimum Rating:</label>
//     <select
//         value={filters.minRate}
//         onChange={(e) => setFilters({ ...filters, minRate: Number(e.target.value) })}
//     >
//         <option value="0">Any</option>
//         <option value="1">1</option>
//         <option value="2">2</option>
//         <option value="3">3</option>
//         <option value="4">4</option>
//         <option value="5">5</option>
//     </select>
// </div>

// {/* Publish Date Range Filter */}
// <div>
//     <label>Publish Date:</label>
//     <input 
//         type="date" 
//         onChange={(e) => setFilters({ 
//             ...filters, 
//             publishDateRange: [new Date(e.target.value), filters.publishDateRange[1]] 
//         })} 
//     />
//     <input 
//         type="date" 
//         onChange={(e) => setFilters({ 
//             ...filters, 
//             publishDateRange: [filters.publishDateRange[0], new Date(e.target.value)] 
//         })} 
//     />
// </div>

// {/* Minimum Students Filter */}
// <div>
//     <label>Minimum Students:</label>
//     <input 
//         type="number" 
//         value={filters.minStudents} 
//         onChange={(e) => setFilters({ ...filters, minStudents: Number(e.target.value) })}
//         placeholder="Any"
//     />
// </div>

// {/* Allow Advisors Filter */}
// <div>
//     <label>Allow Advisors:</label>
//     <select 
//         value={filters.allowAdvisors === null ? 'any' : filters.allowAdvisors.toString()}
//         onChange={(e) => setFilters({ ...filters, allowAdvisors: e.target.value === 'any' ? null : e.target.value === 'true' })}
//     >
//         <option value="any">Any</option>
//         <option value="true">Yes</option>
//         <option value="false">No</option>
//     </select>
// </div>

// {/* Minimum Advisors Filter */}
// <div>
//     <label>Minimum Advisors:</label>
//     <input 
//         type="number" 
//         value={filters.minAdvisors} 
//         onChange={(e) => setFilters({ ...filters, minAdvisors: Number(e.target.value) })}
//         placeholder="Any"
//     />
// </div>

//  <SectionHeader onClick={() => toggleSection('myCart')}>
//                 {expandedSection === 'myCart' ? <FaChevronUp /> : <FaChevronDown />}
//                 My Cart
//             </SectionHeader>
//             {expandedSection === 'myCart' &&
//     myCart.map((world) => (
//         <div key={world._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
//             {world.worldName && (
//                 <Button
//                     onClick={() => handleWorldClickFromMyCart(world._id)}
//                     variant="light"
//                     style={{ flexGrow: 1, marginRight: '10px' }}
//                 >
//                     {world.worldName}
//                 </Button>
//             )}
//             {world.price && (
//                 <span style={{ marginRight: '10px' }}>${world.price.toFixed(2)}</span>
//             )}
// <FaCreditCard
//     onClick={() => handlePurchaseWorldFromCart(world._id)}
//     style={{ color: 'blue', cursor: 'pointer', marginRight: '10px' }}
//     title="Go to Payment"
// />
//             <FaTrash
//                 onClick={() => handleDeleteWorldFromCart(world._id)}
//                 style={{ color: 'red', cursor: 'pointer' }}
//                 title="Delete"
//             />
//         </div>
//     ))}

//             {expandedSection === 'myCart' && (
//                 <div style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'right' }}>
//                     Total Price: ${totalPrice.toFixed(2)}
//                 </div>
//             )}

//              <SectionHeader onClick={() => toggleSection('chats')}>
//                  {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
//                  My Chats
//              </SectionHeader>
//              {expandedSection === 'chats' &&
//                  messages.map((message) => (
//                      <div key={message._id}>
//                          {message.senderName && (
//                              <Button
//                                  onClick={() => handleNameClick(message.withId)}
//                                  variant="light"
//                                  style={{ width: '100%' }}
//                              >
//                                  {message.senderName}
//                              </Button>
//                          )}
//                      </div>
//                  ))}
//              <SectionHeader onClick={() => toggleSection('myEarning')}>
//              {expandedSection === 'myEarning' ? <FaChevronUp /> : <FaChevronDown />}
//                  MY Earning
                
//              </SectionHeader>
//              {expandedSection === 'myEarning' &&
//                (
//                      <div >
//                          {myEarning} $
//                      </div>
//                  )}

//  <SectionHeader onClick={() => toggleSection('myRate')}>
//      {expandedSection === 'myRate' ? <FaChevronUp /> : <FaChevronDown />}
//      My Rate
//  </SectionHeader>
//  {expandedSection === 'myRate' && (
//      <div>
//          {Array.from({ length: 5 }, (_, index) => {
//              if (myRate >= index + 1) {
//                  return <FaStar key={index} color="gold" />;
//              } else if (myRate > index && myRate < index + 1) {
//                  return <FaStarHalfAlt key={index} color="gold" />;
//              } else {
//                  return <FaRegStar key={index} color="gold" />;
//              }
//          })}
//      </div>
//  )}
//          </Sidebar>
//      )}
//      <ToggleSidebarButton onClick={toggleSidebar}>
//          {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
//      </ToggleSidebarButton>





//                 <MainContent>
//                 <Header>
//     <TabContainer>
//         <Tab
//             active={activeTab === 'worlds'}
//             onClick={() => setActiveTab('worlds')}
//         >
//             Explore Worlds
//         </Tab>
//         <Tab
//             active={activeTab === 'mates'}
//             onClick={ handleMateTab}
//         >
//             Explore Mates
//         </Tab>
//         <Tab active={activeTab === 'posts'} onClick={handlePostsTab}>
//                         Explore Posts
//                     </Tab>
//     </TabContainer>
// {  !showDropdown &&  <UploadButtonWrapper>
//         <UploadButton onClick={() => navigate('/add-world')}>
//             <FaUpload style={{ marginRight: '5px' }} /> Upload World
//         </UploadButton>
//     </UploadButtonWrapper>}
// </Header>


// {       activeTab === 'worlds' &&           ( 

//     <>
    
//     <SubTabContainer>
//             <SubTab active={activeSubTab === 'exploreWorlds'} onClick={() => setActiveSubTab('exploreWorlds')}>
//                 Explore Worlds
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('myUploads')}>
//                 My Uploads
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('myDrafts')}>
//                 My drafts
//             </SubTab>
//             <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('mySubscribtions')}>
//                 My Subscribtions
//             </SubTab>
//         </SubTabContainer>
    
    
// { activeSubTab === 'exploreWorlds' &&   <WorldsGrid>
//                         {filteredWorlds.map((world, index) => {
//                             const totalRate = world.rates.reduce((sum, r) => sum + r.rate, 0);
//                             const averageRate = totalRate / world.rates.length || 0;
//                             return (
//                                 <WorldCard key={index}>
//                                     <Publisher>
//                                         <img
//                                             src={world.publisher?.picture || '/images/default-publisher.jpg'}
//                                             alt="Publisher"
//                                         />
//                                         <div>
//                                             <p>{world.publisher?.name || 'Unknown Publisher'}</p>
//                                             <Rating>
//                                                 {Array.from({ length: 5 }, (_, i) => (
//                                                     <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
//                                                 ))}
//                                             </Rating>
//                                         </div>
//                                     </Publisher>
//                                     <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
//                                     <Info>
//                                         <h3>{world.worldName}</h3>
//                                         <p>{world.worldDescription}</p>
//                                         <p>{world.price} $</p>
//                                         <p>{world.advisorAmount}</p>
//                                         <p>
//                                             <FaUserGraduate /> {world.numberOdStudents}
//                                         </p>
//                                         <p>
//                                             <FaChalkboardTeacher /> {world.numberOfAdvisors}
//                                         </p>
//                                         {world.isWorldAllowingAdvisors ? (
//                                             <p>
//                                                 <FaCheckCircle color="green" /> Allowing Advisors
//                                             </p>
//                                         )
                                    
//                                     :
//                                     (<br/>)}
//                                      <p>Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}</p>

//                                         <Actions>
//     <ActionRow>
//         <GreenButton onClick={() => handleGetInWorld(world._id)}>
//             <FaArrowRight style={{ marginRight: '5px' }} />
//             Get In
//         </GreenButton>
// {        
//     world.allowedCard &&
//         <GreenButton onClick={() => handleAddToCart(world._id)}>
//             <FaShoppingCart style={{ marginRight: '5px' }} />
//             Add To Cart
//         </GreenButton>}
//     </ActionRow>
//     <ActionRow>
//     <NavColorButton onClick={() => handleViewWorldTrailer(world._id)}>
//     <FaPlayCircle style={{ marginRight: '5px' }} />
//     View Trailer
// </NavColorButton>

//         <NavColorButton onClick={() => handleWorldComments(world.comments)}>
//             <FaComments style={{ marginRight: '5px' }} />
//             Comments
//         </NavColorButton>
//     </ActionRow>
// </Actions>


//                                     </Info>
//                                 </WorldCard>
//                             );
//                         })}
//                     </WorldsGrid>}




                        
// { activeSubTab === 'myUploads' &&   <WorldsGrid>
//                         {myUploads.map((world, index) => {
//                             const totalRate = world?.rates?.reduce((sum, r) => sum + r.rate, 0);
//                             const averageRate = totalRate / world?.rates?.length || 0;
//                             return (
//                                 <WorldCard key={index}>
//                                     <Publisher>
//                                         <img
//                                             src={world.publisher?.picture || '/images/default-publisher.jpg'}
//                                             alt="Publisher"
//                                         />
//                                         <div>
//                                             <p>{world.publisher?.name || 'Unknown Publisher'}</p>
//                                             <Rating>
//                                                 {Array.from({ length: 5 }, (_, i) => (
//                                                     <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
//                                                 ))}
//                                             </Rating>
//                                         </div>
//                                     </Publisher>
//                                     <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
//                                     <Info>
//                                         <h3>{world.worldName}</h3>
//                                         <p>{world.worldDescription}</p>
//                                         <p>{world.price} $</p>
//                                         <p>{world.advisorAmount}</p>
//                                         <p>
//                                             <FaUserGraduate /> {world.numberOdStudents}
//                                         </p>
//                                         <p>
//                                             <FaChalkboardTeacher /> {world.numberOfAdvisors}
//                                         </p>
//                                         {world.isWorldAllowingAdvisors ? (
//                                             <p>
//                                                 <FaCheckCircle color="green" /> Allowing Advisors
//                                             </p>
//                                         )
                                    
//                                     :
//                                     (<br/>)}
//                                      <p>Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}</p>

//                                         <Actions>
//     <ActionRow>
//         <GreenButton onClick={() => handleGetInWorld(world._id)}>
//             <FaArrowRight style={{ marginRight: '5px' }} />
//             Get In
//         </GreenButton>
// {        
//     world.allowedCard &&
//         <GreenButton onClick={() => handleAddToCart(world._id)}>
//             <FaShoppingCart style={{ marginRight: '5px' }} />
//             Add To Cart
//         </GreenButton>}
//     </ActionRow>
//     <ActionRow>
//     <NavColorButton onClick={() => handleViewWorldTrailer(world._id)}>
//     <FaPlayCircle style={{ marginRight: '5px' }} />
//     View Trailer
// </NavColorButton>

//         <NavColorButton onClick={() => handleWorldComments(world.comments)}>
//             <FaComments style={{ marginRight: '5px' }} />
//             Comments
//         </NavColorButton>
//     </ActionRow>
// </Actions>


//                                     </Info>
//                                 </WorldCard>
//                             );
//                         })}
//                     </WorldsGrid>}



                        
// { activeSubTab === 'mySubscribtions' &&   <WorldsGrid>
//                         {subscribedInWorlds?.map((world, index) => {
//                             const totalRate = world?.rates?.reduce((sum, r) => sum + r.rate, 0);
//                             const averageRate = totalRate / world?.rates?.length || 0;
//                             return (
//                                 <WorldCard key={index}>
//                                     <Publisher>
//                                         <img
//                                             src={world.publisher?.picture || '/images/default-publisher.jpg'}
//                                             alt="Publisher"
//                                         />
//                                         <div>
//                                             <p>{world.publisher?.name || 'Unknown Publisher'}</p>
//                                             <Rating>
//                                                 {Array.from({ length: 5 }, (_, i) => (
//                                                     <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
//                                                 ))}
//                                             </Rating>
//                                         </div>
//                                     </Publisher>
//                                     <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
//                                     <Info>
//                                         <h3>{world.worldName}</h3>
//                                         <p>{world.worldDescription}</p>
//                                         <p>{world.price} $</p>
//                                         <p>{world.advisorAmount}</p>
//                                         <p>
//                                             <FaUserGraduate /> {world.numberOdStudents}
//                                         </p>
//                                         <p>
//                                             <FaChalkboardTeacher /> {world.numberOfAdvisors}
//                                         </p>
//                                         {world.isWorldAllowingAdvisors ? (
//                                             <p>
//                                                 <FaCheckCircle color="green" /> Allowing Advisors
//                                             </p>
//                                         )
                                    
//                                     :
//                                     (<br/>)}
//                                      <p>Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}</p>

//                                         <Actions>
//     <ActionRow>
//         <GreenButton onClick={() => handleGetInWorld(world._id)}>
//             <FaArrowRight style={{ marginRight: '5px' }} />
//             Get In
//         </GreenButton>
// {        
//     world.allowedCard &&
//         <GreenButton onClick={() => handleAddToCart(world._id)}>
//             <FaShoppingCart style={{ marginRight: '5px' }} />
//             Add To Cart
//         </GreenButton>}
//     </ActionRow>
//     <ActionRow>
//     <NavColorButton onClick={() => handleViewWorldTrailer(world._id)}>
//     <FaPlayCircle style={{ marginRight: '5px' }} />
//     View Trailer
// </NavColorButton>

//         <NavColorButton onClick={() => handleWorldComments(world.comments)}>
//             <FaComments style={{ marginRight: '5px' }} />
//             Comments
//         </NavColorButton>
//     </ActionRow>
// </Actions>


//                                     </Info>
//                                 </WorldCard>
//                             );
//                         })}
//                     </WorldsGrid>}
    
//     </>
    

                    
//                 ) } 


// {activeTab === 'mates' && (
//     <>
//         {/* Sub-tabs for mates */}
//         <SubTabContainer>
//             <SubTab active={activeSubTab === 'exploreMates'} onClick={() => setActiveSubTab('exploreMates')}>
//                 Explore Mates
//             </SubTab>
//             <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('matesRequests')}>
//                 Mates Requests
//             </SubTab>
//             <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('currentMates')}>
//                 Current Mates
//             </SubTab>
//         </SubTabContainer>

//         {/* Content based on the selected sub-tab */}
//         {activeSubTab === 'exploreMates' && (
//                     <MatesGrid>
//                     {mates.map((mate) => {
//                         const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
//                         return (
//                             <MateCard   key={mate._id}
//                             onClick={(e) => {
//                                 if (!e.target.closest('.mate-actions button')) {
//                                     // Navigate to the profile page
//                                     window.location.href = '/profile'; // Use mate._id to dynamically navigate to their profile
//                                 }
//                             }}>
//                                 {/* Mate Header */}
//                                 <div className="mate-header">
//                                     <img
//                                         src={mate.picture || '/images/default-profile.jpg'} // Replace with actual profile image URL
//                                         alt={mate.name || 'Mate'}
//                                     />
//                                     <div className="mate-info">
//                                         <h3>{`${mate.name} ${mate.surname}`}</h3>
//                                         <p>Total Cash: ${mate.totalCash || 0}</p>
//                                         <div>
//                                             {Array.from({ length: 5 }, (_, i) => (
//                                                 i < averageRate ? (
//                                                     <FaStar key={i} color="gold" />
//                                                 ) : (
//                                                     <FaRegStar key={i} color="gold" />
//                                                 )
//                                             ))}
//                                         </div>
//                                     </div>
//                                 </div>
        
//                                 {/* Shared Worlds */}
//                                 <div className="world-thumbnails">
//                                     {mate.sharedWorlds?.map((world) => (
//                                         <img
//                                             key={world._id}
//                                             src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
//                                             alt={world.name || 'World'}
//                                             title={world.name || 'World'}
//                                         />
//                                     ))}
//                                 </div>
        
//                                 {/* Actions */}
//                                 <div className="mate-actions">
//                                     <button onClick={() => handleAddMate(mate._id)}>Add Mate</button>
//                                     <button onClick={() => handleMessage(mate._id)}>Message</button>
//                                 </div>
//                             </MateCard>
//                         );
//                     })}
//                 </MatesGrid>
//         )}

        

//         {activeSubTab === 'matesRequests' && (
//             <MatesGrid>
//                                    {matesRequests.map((mate) => {
//                         const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
//                         return (
//                             <MateCard   key={mate._id}
//                             onClick={(e) => {
//                                 if (!e.target.closest('.mate-actions button')) {
//                                     // Navigate to the profile page
//                                     window.location.href = '/profile'; // Use mate._id to dynamically navigate to their profile
//                                 }
//                             }}>
//                                 {/* Mate Header */}
//                                 <div className="mate-header">
//                                     <img
//                                         src={mate.picture || '/images/default-profile.jpg'} // Replace with actual profile image URL
//                                         alt={mate.name || 'Mate'}
//                                     />
//                                     <div className="mate-info">
//                                         <h3>{`${mate.name} ${mate.surname}`}</h3>
//                                         <p>Total Cash: ${mate.totalCash || 0}</p>
//                                         <div>
//                                             {Array.from({ length: 5 }, (_, i) => (
//                                                 i < averageRate ? (
//                                                     <FaStar key={i} color="gold" />
//                                                 ) : (
//                                                     <FaRegStar key={i} color="gold" />
//                                                 )
//                                             ))}
//                                         </div>
//                                     </div>
//                                 </div>
        
//                                 {/* Shared Worlds */}
//                                 <div className="world-thumbnails">
//                                     {mate.sharedWorlds?.map((world) => (
//                                         <img
//                                             key={world._id}
//                                             src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
//                                             alt={world.name || 'World'}
//                                             title={world.name || 'World'}
//                                         />
//                                     ))}
//                                 </div>
        
//                                 {/* Actions */}
//                                 <div className="mate-actions">
//                                     <button onClick={() => handleAcceptRequest(mate._id)}>Accept Mate</button>

//                                 </div>
//                             </MateCard>
//                         );
//                     })}
//             </MatesGrid>
//         )}

//         {activeSubTab === 'currentMates' && (


//             <MatesGrid>
//                 {currentMates.map((mate) => (
//                     <MateCard key={mate._id}>
//                         <div className="mate-header">
//                             <img
//                                 src={mate.picture || '/images/default-profile.jpg'}
//                                 alt={mate.name || 'Mate'}
//                             />
//                             <div className="mate-info">
//                                 <h3>{`${mate.name} ${mate.surname}`}</h3>
//                                 <p>Total Shared Worlds: {mate.sharedWorlds?.length || 0}</p>
//                             </div>
//                         </div>
//                         <div className="mate-actions">
//                             <button onClick={() => handleMessage(mate._id)}>Message</button>
//                         </div>
//                     </MateCard>
//                 ))}
//             </MatesGrid>
//         )}
//     </>
// )}



// {activeTab === 'posts' && (
// <>

// <SubTabContainer>
//             <SubTab active={activeSubTab === 'explorePosts'} onClick={() => setActiveSubTab('explorePosts')}>
//                 Explore Posts
//             </SubTab>
//             <SubTab active={activeSubTab === 'myPosts'} onClick={() => setActiveSubTab('myPosts')}>
//                 My Posts
//             </SubTab>
//             <SubTab active={activeSubTab === 'savedPosts'} onClick={() => setActiveSubTab('savedPosts')}>
//                 Saved Posts
//             </SubTab>
//         </SubTabContainer>

//         { activeSubTab === 'explorePosts' &&
//                     <PostsGrid>
//                         {posts.map((post) => (
//                             <PostCard key={post._id}>
//                                 <PostHeader>
//                                     <img
//                                         src={post?.author?.picture || '/images/default-profile.jpg'}
//                                         alt={post?.author?.name}
//                                     />
//                                     <div>
//                                         <h3>{post?.author?.name}</h3>
//                                         <p>{new Date(post?.date).toLocaleDateString()}</p>
//                                     </div>
//                                 </PostHeader>
//                                 <PostContent>
//                                     <h4>{post?.title}</h4>
//                                     <p>{post?.content}</p>
//                                 </PostContent>
//                                 <PostActions>
//                                     <button>Like</button>
//                                     <button>Comment</button>
//                                     <button>Share</button>
//                                 </PostActions>
//                             </PostCard>
//                         ))}
//                     </PostsGrid>}




//                     { activeSubTab === 'myPosts' &&
//                     <PostsGrid>
//                         {posts.map((post) => (
//                             <PostCard key={post._id}>
//                                 <PostHeader>
//                                     <img
//                                         src={post?.author?.picture || '/images/default-profile.jpg'}
//                                         alt={post?.author?.name}
//                                     />
//                                     <div>
//                                         <h3>{post?.author?.name}</h3>
//                                         <p>{new Date(post?.date).toLocaleDateString()}</p>
//                                     </div>
//                                 </PostHeader>
//                                 <PostContent>
//                                     <h4>{post?.title}</h4>
//                                     <p>{post?.content}</p>
//                                 </PostContent>
//                                 <PostActions>
//                                     <button>Like</button>
//                                     <button>Comment</button>
//                                     <button>Share</button>
//                                 </PostActions>
//                             </PostCard>
//                         ))}
//                     </PostsGrid>}



//                     { activeSubTab === 'savedPosts' &&
//                     <PostsGrid>
//                         {posts.map((post) => (
//                             <PostCard key={post._id}>
//                                 <PostHeader>
//                                     <img
//                                         src={post?.author?.picture || '/images/default-profile.jpg'}
//                                         alt={post?.author?.name}
//                                     />
//                                     <div>
//                                         <h3>{post?.author?.name}</h3>
//                                         <p>{new Date(post?.date).toLocaleDateString()}</p>
//                                     </div>
//                                 </PostHeader>
//                                 <PostContent>
//                                     <h4>{post?.title}</h4>
//                                     <p>{post?.content}</p>
//                                 </PostContent>
//                                 <PostActions>
//                                     <button>Like</button>
//                                     <button>Comment</button>
//                                     <button>Share</button>
//                                 </PostActions>
//                             </PostCard>
//                         ))}
//                     </PostsGrid>}
//                     </>
//                 )}
//                 </MainContent>
//             </ContentContainer>
//             <Modal show={showCommentsModal} onHide={closeCommentsModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Comments</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {selectedWorldComments?.length > 0 ? (
//                         selectedWorldComments?.map((comment) => (
//                             <div key={comment._id} style={{ marginBottom: '15px' }}>
//                                 <img
//                                     src={comment.commenter[0]?.picture || '/images/default-commenter.jpg'}
//                                     alt="Commenter"
//                                     style={{
//                                         width: '40px',
//                                         height: '40px',
//                                         borderRadius: '50%',
//                                         marginRight: '10px',
//                                     }}
//                                 />
//                                 <span>{comment.commenter[0]?.name || 'Unknown'}</span>
//                                 <p>{comment.comment}</p>
//                             </div>
//                         ))
//                     ) : (
//                         <p>No comments available for this world.</p>
//                     )}
//                 </Modal.Body>
//             </Modal>
//         </PageContainer>
//     );
// }

// export default Worlds;

// // Styled Components
// // Add unchanged styled components here...

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
// `;

// const Header = styled.div`
//     display: flex;       
//     justify-content: space-between;
//     align-items: center;
// `;
// const ContentContainer = styled.div`
//     display: flex;
//     position: relative;
//     width: 100%; /* Full width */
//     min-height: calc(100vh - 60px); /* Adjust for navbar height */
//     box-sizing: border-box;
//     align-items: stretch; /* Ensure children stretch */
// `;




// const LeftNav = styled.div`
//     display: flex;
//     align-items: center;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     margin-left: 20px;
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;
//     }
// `;

// const SearchBar = styled.div`
//     display: flex;
//     align-items: center;
//     background-color: #ecf0f1;
//     padding: 5px 10px;
//     border-radius: 20px;

//     input {
//         border: none;
//         outline: none;
//         background: transparent;
//         margin-left: 10px;
//     }
// `;

// const NavIcons = styled.div`
//     display: flex;
//     gap: 15px;
// `;

// const ProfileWrapper = styled.div`
//     position: relative;
// `;

// const ProfilePicture = styled.img`
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// `;

// const DropdownMenu = styled.div`
//     position: absolute;
//     top: 50px;
//     right: 0;
//     background-color: white;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//     width: 200px;
//     display: flex;
//     flex-direction: column;

//     a {
//         padding: 10px 15px;
//         color: black;
//         text-decoration: none;

//         &:hover {
//             background-color: #f0f0f0;
//         }
//     }
// `;

// const SectionHeader = styled.h2`
//     font-size: 18px;
//     color: #2c3e50;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     transition: all 0.3s ease;

//     &:hover {
//         font-size: 20px;
//         color: #2980b9;
//     }
// `;

// const WorldsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const WorldCard = styled.div`
//     background-color: white;
//     border-radius: 10px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     overflow: hidden;
//     transition: transform 0.3s ease;

//     &:hover {
//         transform: translateY(-10px);
//     }
// `;

// const Publisher = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 10px;

//     img {
//         width: 40px;
//         height: 40px;
//         border-radius: 50%;
//         margin-right: 10px;
//     }

//     p {
//         font-size: 14px;
//         color: #2c3e50;
//         margin: 0;
//     }
// `;

// const Thumbnail = styled.img`
//     width: 100%;
//     height: 150px;
//     object-fit: cover;
// `;

// const Info = styled.div`
//     padding: 15px;

//     h3 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: #2c3e50;
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Rating = styled.div`
//     display: flex;
// `;
// const Actions = styled.div`
//     display: flex;
//     flex-direction: column; /* Stack ActionRow elements vertically */
//     gap: 10px; /* Add spacing between rows */
// `;



// const GreenButton = styled.button`
//     background-color: #27ae60 !important; /* Green color */
//     color: white !important;
//     border: none;
//     width: 48%; /* Ensures two buttons fit in one row */
//     margin: 2px; /* Adds spacing between buttons */
//     padding: 5px 10px;
//     border-radius: 5px;

//     &:hover {
//         background-color: #219150 !important; /* Darker green on hover */
//     }
// `;

// const NavColorButton = styled.button`
//     background-color: #2c3e50 !important; /* Navbar color */
//     color: white !important;
//     border: none;
//     width: 48%; /* Ensures two buttons fit in one row */
//     margin: 2px; /* Adds spacing between buttons */
//     padding: 5px 10px;
//     border-radius: 5px;

//     &:hover {
//         background-color: #1a252f !important; /* Darker navbar color on hover */
//     }
// `;

// const ActionRow = styled.div`
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 10px; /* Space between rows */
// `;

// const ToggleSidebarButton = styled.div`
//     position: fixed; /* Ensures it's positioned relative to the viewport */
//     top: 100px; /* Adjust to set vertical placement */
//     left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     padding: 10px;
//     background-color: #ddd;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     z-index: 1000; /* Ensures it stays above other elements */
//     transition: left 0.3s ease; /* Smooth transition when toggling */
// `;




// const UploadButtonWrapper = styled.div`
//     position: fixed; /* Makes the button stay in one position */
//     top: 70px; /* Distance from the bottom of the viewport */
//     right: 20px; /* Distance from the right of the viewport */
//     z-index: 1000; /* Ensures it stays above other elements */
// `;

// const UploadButton = styled(Button)`
//     background-color: #27ae60;
//     color: white;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 50px;
//     font-size: 16px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     &:hover {
//         background-color: #219150;
//     }
// `;
        


// const Tab = styled.div`
//     flex: 1;
//     text-align: center;
//     padding: 15px 0;
//     cursor: pointer;
//     font-size: 18px;
//     font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
//     color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
//     border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
//     transition: all 0.3s ease-in-out;

//     &:hover {
//         color: #27ae60;
//     }
// `;




// const TabContainer = styled.div`
//     position: fixed;
//     top: 60px; /* Set below the navbar */
//     left: 0;
//     right: 0;
//     display: flex;
//     justify-content: center;
//     background-color: #f8f9fa; /* Light gray for a clean look */
//     border-bottom: 2px solid #ddd;
//     box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
//     z-index: 950; /* Below the navbar */
//     padding: 10px 0;
// `;

// const Sidebar = styled.div`
//     width: 300px;
//     position: fixed;
//     top: 120px; /* Below the navbar and tab container */
//     left: 0;
//     bottom: 0; /* Ensure full height */
//     background-color: #f4f4f4;
//     padding: 20px;
//     border-right: 1px solid #ddd;
//     overflow-y: auto; /* Make content scrollable */
//     z-index: 900; /* Below tab container and navbar */
// `;



// const MainContent = styled.div`
//     margin-left: 300px; /* Space for sidebar */
//     margin-top: 160px; /* Adjust for navbar + tab height */
//     flex-grow: 1; /* Expand to fill available space */
//     padding: 20px;
//     width: calc(100% - 300px); /* Full width minus sidebar */
//     box-sizing: border-box;
//     display: flex;
//     flex-direction: column;
//     align-items: stretch; /* Ensure children stretch horizontally */
// `;


// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
//     position: fixed; /* Fixed at the top */
//     top: 0;
//     left: 0;
//     width: 100%; /* Full width */
//     z-index: 1000; /* Above all other elements */
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
// `;




// const MatesGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;


// const MateCard = styled.div`
//     background-color: white;
//     border-radius: 10px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     overflow: hidden;
//     transition: transform 0.3s ease;

//     &:hover {
//         transform: translateY(-10px);
//     }

//     }
// `;








// const PostsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
//     margin-top: 20px;
// `;

// const PostCard = styled.div`
//     background-color: white;
//     border-radius: 10px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     padding: 15px;
//     transition: transform 0.3s ease;

//     &:hover {
//         transform: translateY(-10px);
//     }
// `;

// const PostHeader = styled.div`
//     display: flex;
//     align-items: center;

//     img {
//         width: 40px;
//         height: 40px;
//         border-radius: 50%;
//         margin-right: 15px;
//     }

//     h3 {
//         font-size: 16px;
//         margin: 0;
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//     }
// `;

// const PostContent = styled.div`
//     margin-top: 15px;

//     h4 {
//         font-size: 18px;
//         margin-bottom: 10px;
//     }

//     p {
//         font-size: 14px;
//         color: #2c3e50;
//     }
// `;

// const PostActions = styled.div`
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 8px 15px;
//         border-radius: 5px;
//         font-size: 14px;
//         cursor: pointer;

//         &:hover {
//             background-color: #219150;
//         }
//     }
// `;


// const SubTabContainer = styled.div`
//     position: fixed; /* Fixed to stay visible while scrolling */
//     top: 145px; /* Add spacing to prevent overlap (adjusted from 120px) */
//     left: 300px; /* Align with the main content considering sidebar width */
//     width: calc(100% - 300px); /* Stretch full width minus sidebar */
//     display: flex;
//     justify-content: center;
//     background-color: #f8f9fa; /* Light gray for a clean look */
//     border-bottom: 1px solid #ddd; /* Subtle border */
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
//     z-index: 950; /* Stay above content but below navbar */
//     padding: 5px 0; /* Reduced padding for a shorter height */
// `;

// const SubTab = styled.div`
//     flex: 1;
//     text-align: center;
//     padding: 8px 0; /* Reduce padding for shorter height */
//     cursor: pointer;
//     font-size: 16px; /* Slightly smaller font size */
//     font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
//     color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
//     border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
//     transition: all 0.3s ease-in-out;

//     &:hover {
//         color: #27ae60;
//     }
// `;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';  
// import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight } from 'react-icons/fa';
// import { Button } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { setWithId, setWorldId } from '../../../../reducers/reducers';

// function Worlds() {
//     const [worlds, setWorlds] = useState([]);
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [searchQuery, setSearchQuery] = useState('');
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [messages, setMessages] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(null);
//     const [sidebarVisible, setSidebarVisible] = useState(true);
//     const [myEarning, setMyEarning] = useState('')
//     const [myRate, setMyRate] = useState('')
//     const [myCart, setMyCart ] = useState([])

// const toggleSidebar = () => {
//     setSidebarVisible(!sidebarVisible);
// };

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const toggleSection = (section) => {
//         setExpandedSection(expandedSection === section ? null : section);
//     };


//     useEffect(() => {
//         const myEarning = async() => {
//            const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning')
//            setMyEarning(response.data)
//         }
//         myEarning();
//     }, [])

    
//     useEffect(() => {
//         const myRate = async() => {
//            const response = await axios.get('https://sell-skill.com/api/endpoints/myRate')
//            setMyRate(response.data)
//         }
//         myRate();
//     }, [])
//     useEffect(() => {
//         const fetchWorlds = async () => {
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
//                 setWorlds(response.data);
//             } catch (error) {
//                 console.error('Error fetching worlds:', error);
//             }
//         };

//         const fetchProfilePicture = async () => {
//             try {
//                 const response = await axios.get(
//                     'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
//                     { responseType: 'blob' }
//                 );
//                 const url = URL.createObjectURL(response.data);
//                 setProfilePictureSrc(url);
//             } catch (error) {
//                 console.error('Error fetching profile picture:', error);
//             }
//         };

//         fetchWorlds();
//         fetchProfilePicture();
//     }, []);

//     const handleSearch = (e) => {
//         setSearchQuery(e.target.value);
//     };

//     const filteredWorlds = worlds.filter((world) =>
//         world.worldName.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     const handleGetInWorld = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/levels');
//     };

//     const handleViewWorldTrailer = (worldId) => {
//         dispatch(setWorldId(worldId));
//         navigate('/world-trailer');
//     };

//     useEffect(() => {
//         const getSendersInfo = async () => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
//             setMessages(response.data);
//             console.log('senders ==========>>', response.data);
//         };
//         getSendersInfo();
//     }, []);

//     const handleNameClick = (withId) => {
//         dispatch(setWithId(withId));
//         navigate('/chat');
//     };


//     const handleAddToCart = async (worldId) => {
//         await axios.post('https://sell-skill.com/api/endpoints/addToCart', {worldId})
//     }

//     useEffect(() => {
//         const getCart = async() => {
//            const response =  await axios.get('https://sell-skill.com/api/endpoints/getCart')
//            setMyCart(response.data)
//         }
//         getCart();
//     }, [])

//     const handleWorldClickFromMyCart = (worldId) => {
//         dispatch(setWorldId(worldId))
//         navigate('/levels')
//     }


//     const handleAddToFaviourites = async (worldId) => {
//         await axios.post('https://sell-skill.com/api/endpoints/addToFaviourites', {worldId})
//     }
//     return (
//         <PageContainer>
//             <Header>
//                 <Navbar>
//                     <LeftNav>
//                         <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                         <NavLinks>
//                             <a href="/my-learning">My profile</a>
//                             <a href="/my-earning">My uploads</a>
//                             <a>withdraw cash</a>
//                             <a href="/my-cart">My Cart</a>
//                             <a href="/certifications">Certifications</a>
//                             <a href="/exams">Exams</a>
//                         </NavLinks>
//                     </LeftNav>
//                     <SearchBar>
//                         <FaSearch />
//                         <input
//                             type="text"
//                             placeholder="Search Worlds"
//                             value={searchQuery}
//                             onChange={handleSearch}
//                         />
//                     </SearchBar>
//                     <NavIcons>
//                         <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                         <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                         <ProfileWrapper
//                             onMouseEnter={() => setShowDropdown(true)}
//                             onMouseLeave={() => setShowDropdown(false)}
//                         >
//                             <ProfilePicture
//                                 src={profilePictureSrc || '/images/default-profile.jpg'}
//                                 alt="Profile"
//                             />
//                             {showDropdown && (
//                                 <DropdownMenu>
//                                     <a href="/my-profile">My Profile</a>
//                                     <a href="/my-learning">My Learning</a>
//                                     <a href="/my-earning">My Earning</a>
//                                     <a href="/my-cart">My Cart</a>
//                                     <a href="/certifications">Certifications</a>
//                                     <a href="/exams">Exams</a>
//                                 </DropdownMenu>
//                             )}
//                         </ProfileWrapper>
//                     </NavIcons>
//                 </Navbar>
//             </Header>
//             <ContentContainer>
//             {sidebarVisible && (
//         <Sidebar>
// <SectionHeader onClick={() => navigate('/my-learnings')}>
//     <FaArrowRight style={{ marginRight: '8px' }} /> My Learning
// </SectionHeader>


// <SectionHeader onClick={() => toggleSection('myCart')}>
//                 {expandedSection === 'myCart' ? <FaChevronUp /> : <FaChevronDown />}
//                 My Cart
//             </SectionHeader>
//             {expandedSection === 'myCart' &&
//                 myCart.map((world) => (
//                     <div key={world._id}>
//                         {world.worldName && (
//                             <Button
//                                 onClick={() => handleWorldClickFromMyCart(world._id)}
//                                 variant="light"
//                                 style={{ width: '100%' }}
//                             >
//                                 {world.worldName}
//                             </Button>
//                         )}
//                     </div>
//                 ))}


//             <SectionHeader onClick={() => toggleSection('chats')}>
//                 {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
//                 My Chats
//             </SectionHeader>
//             {expandedSection === 'chats' &&
//                 messages.map((message) => (
//                     <div key={message._id}>
//                         {message.senderName && (
//                             <Button
//                                 onClick={() => handleNameClick(message.withId)}
//                                 variant="light"
//                                 style={{ width: '100%' }}
//                             >
//                                 {message.senderName}
//                             </Button>
//                         )}
//                     </div>
//                 ))}
//             <SectionHeader onClick={() => toggleSection('myEarning')}>
//             {expandedSection === 'myEarning' ? <FaChevronUp /> : <FaChevronDown />}
//                 MY Earning
                
//             </SectionHeader>
//             {expandedSection === 'myEarning' &&
//               (
//                     <div >
//                         {myEarning} $
//                     </div>
//                 )}

// <SectionHeader onClick={() => toggleSection('myRate')}>
//     {expandedSection === 'myRate' ? <FaChevronUp /> : <FaChevronDown />}
//     My Rate
// </SectionHeader>
// {expandedSection === 'myRate' && (
//     <div>
//         {Array.from({ length: 5 }, (_, index) => {
//             if (myRate >= index + 1) {
//                 return <FaStar key={index} color="gold" />;
//             } else if (myRate > index && myRate < index + 1) {
//                 return <FaStarHalfAlt key={index} color="gold" />;
//             } else {
//                 return <FaRegStar key={index} color="gold" />;
//             }
//         })}
//     </div>
// )}
//         </Sidebar>
//     )}
//     <ToggleSidebarButton onClick={toggleSidebar}>
//         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
//     </ToggleSidebarButton>
//                 <MainContent>
//                     <Header>
//                         <h1>Explore Worlds</h1>
//                         <UploadButton onClick={() => navigate('/upload-world')}>
//                             <FaUpload /> Upload World
//                         </UploadButton>
//                     </Header>
//                     <WorldsGrid>
//                         {filteredWorlds.map((world, index) => {
//                             const totalRate = world.rates.reduce((sum, r) => sum + r.rate, 0);
//                             const averageRate = totalRate / world.rates.length || 0;
//                             return (
//                                 <WorldCard key={index}>
//                                     <Publisher>
//                                         <img
//                                             src={world.publisher?.picture || '/images/default-publisher.jpg'}
//                                             alt="Publisher"
//                                         />
//                                         <div>
//                                             <p>{world.publisher?.name || 'Unknown Publisher'}</p>
//                                             <Rating>
//                                                 {Array.from({ length: 5 }, (_, i) => (
//                                                     <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
//                                                 ))}
//                                             </Rating>
//                                         </div>
//                                     </Publisher>
//                                     <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
//                                     <Info>
//                                         <h3>{world.worldName}</h3>
//                                         <p>{world.worldDescription}</p>
//                                         <p>{world.price} $ </p>
//                                         <p>st number: {world.numberOdStudents}</p>
//                                         <p>ad number: {world.numberOfAdvisors}</p>
//                                         {
//                                             world.isWorldAllowingAdvisors && 
//                                             <p>allowing advisors: {world.isWorldAllowingAdvisors}</p>
//                                         }
                                      
//                                         <p>date of publish: {world.dateOfPublish}</p>
//                                         <Actions>
//                                             <Button onClick={() => handleGetInWorld(world._id)}>Get In</Button>
//                                             <Button onClick={() => handleViewWorldTrailer(world._id)}>
//                                                 View Trailer
//                                             </Button>
//                                             <Button onClick={() => handleAddToCart(world._id)}>Add To Faviouites</Button>
//                                             {
//                                                 world.allowedCard && 
//                                             <Button onClick={() => handleAddToCart(world._id)}>Add To Cart</Button>}

//                                         </Actions>
//                                     </Info>
//                                 </WorldCard>
//                             );
//                         })}
//                     </WorldsGrid>
//                 </MainContent>
//             </ContentContainer>
//         </PageContainer>
//     );
// }

// export default Worlds;









