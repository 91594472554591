import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { setAcheivementId, setWithId, setWorldId } from '../../reducers/reducers';
import { v4 as uuidv4 } from 'uuid';
import { FaArrowRight, FaChalkboardTeacher, FaCheckCircle, FaComments, FaPlayCircle, FaShoppingCart, FaStar, FaUserFriends, FaUserGraduate } from 'react-icons/fa';

const ProfileContainer = styled.div`
  font-family: 'Arial', sans-serif;
  color: #2c3e50;
  max-width: 1200px;
  margin: auto;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
`;

const CoverPhoto = styled.div`
  background-image: url(${props => props.coverImage});
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  border-radius: 10px;
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;

  background: #fff;
  position: relative;
  border-radius: 10px;
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;

    @media (max-width: 768px) {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align the elements */
  }
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -75px;
  left: 20px;

`;




const ProfileDetails = styled.div`
  flex: 1;
  width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
  margin-left: 180px; /* Add left margin to accommodate the profile picture */
  @media (max-width: 768px) {
    margin-left: 0; /* Remove left margin for mobile devices */
    margin-top: 80px; /* Add space between profile picture and details */
    text-align: center; /* Center text alignment */
  }
`;

const Stats = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  color: #7f8c8d;
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  .add-mate-btn {
    background: #27ae60;
    color: white;
  }

  .message-btn {
    background: #2c3e50;
    color: white;
  }
`;

const Section = styled.div`
  margin: 20px 0;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
`;

const SectionTitle = styled.h3`
  border-bottom: 2px solid #27ae60;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const Card = styled.div`

  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s;
  overflow: hidden;
  width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
    width: 100%; /* Ensure the cover spans full width */
  box-sizing: border-box;
`;

function GProfile() {
  const [profile, setProfile] = useState(null);
  const { id } = useParams(); // This will extract the `id` from the URL
  const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};


  const [showFullText, setShowFullText] = useState(false);
  const maxChars = 150; // Maximum number of characters to display initially

  const handleGetInWorld = (worldId) => {
    dispatch(setWorldId(worldId));
    navigate(`/levels/${worldId}`);
};

const handleViewWorldTrailer = (worldId) => {
    dispatch(setWorldId(worldId));
    navigate(`/world-trailer/${worldId}`);
};

const closeCommentsModal = () => {
  setShowCommentsModal(false);
};






const handleAddToCart = async (worldId) => {
    await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
};
const [selectedWorldComments, setSelectedWorldComments] = useState('')
const [showCommentsModal, setShowCommentsModal] = useState(false)
const handleWorldComments = (comments) => {
    setSelectedWorldComments(comments);
    setShowCommentsModal(true);
};
  const toggleText = () => setShowFullText((prev) => !prev);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Define the maximum length before truncation

    const toggleExpand = () => setIsExpanded(!isExpanded);
  const truncatedLetter =
    profile?.letter?.length > maxChars
      ? profile?.letter?.slice(0, maxChars) + "..."        
      : profile?.letter;
    

  console.log('certainSkillerId =======================-=-=-=-=-=-=-=-=-=-=-> > > > > > > ',id)
  const [loading, setLoading] = useState(true);        
  const [error, setError] = useState(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getProfile', {params: {id}});
        setProfile(response.data);
      } catch (err) {
        setError(err.message);  
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);


  const handleAddMate = async (mateId) => {
    const mateRequestId = uuidv4();
    await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId: id, mateRequestId})
    console.log(`Add mate with ID: ${mateId}`);

    // Add your logic here
};
  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }


  const {
    name,
    surname,
    letter,
    picture,
    coverPicture,
    cash,
    rate,
    followers,
    worlds,
    SubscribedInWorlds,
    AdvisorAtWorlds,
    certifications,
    instructorDescription,
    joinedAt,
    realWorldResults,
    definingVideo,
    haveSameWorld,
    areMates
  } = profile;
console.log('profile from GProfile ========================================> >> > > >  > > >>  > > > ',profile)
  const handleGoToAcheivement = (acheivementId) => {
    dispatch(setAcheivementId(acheivementId))
    navigate('/acheivment')
    
  }

  const handleMessage = () => {
    setWithId(id)
    navigate('/chat')
  }
  return (
  <PageContainer>
    <ProfileContainer>
      <CoverPhoto coverImage={profile?.coverPicture} />

      <ProfileHeader>
        <ProfilePicture
          src={profile?.picture}
          alt={`${name}'s profile`}
        />
        <ProfileDetails>
          <h1>{name} {surname}</h1>
          <CoverLetter>
        {showFullText ? profile?.letter : truncatedLetter}
        {profile?.letter?.length > maxChars && (
          <SeeMoreButton onClick={toggleText}>
            {showFullText ? "See Less" : "See More"}
          </SeeMoreButton>
        )}
      </CoverLetter>

                                    <div className="mb-3">
                                        {Array.from({ length: 5 }, (_, i) => (
                                            <FaStar
                                                key={i}
                                                color={i < rate ? '#f39c12' : '#dcdcdc'}
                                            />
                                        ))}
                                    </div>
          <Stats>
                <a href="/mates"> {/* Link to mates */}
            <FaUserFriends size={16} color="#2c3e50" style={{ marginRight: "5px" }} />
            Mates: {followers?.length || 0}
          </a>
            <p>Cash: ${cash}</p>
          </Stats>
          <ActionButtons>
{         <button className="add-mate-btn" onClick={handleAddMate} disabled={!haveSameWorld}>Add Mate</button>}
{           <button className="message-btn" onClick={handleMessage} disabled={!areMates}>Message</button>}
          </ActionButtons>
        </ProfileDetails>
      </ProfileHeader>

      {definingVideo && (
        <Section>
          <SectionTitle>Defining Video</SectionTitle>
          <video controls style={{ width: '100%', borderRadius: '10px' }}>
            <source src={definingVideo} type="video/mp4" />
          </video>
        </Section>
      )}

 


{  AdvisorAtWorlds?.length > 0 &&    <Section>
        <SectionTitle>Advisor At</SectionTitle>
        <Grid>
          {AdvisorAtWorlds?.map((world) => (
            <Card key={world._id}>
              <Thumbnail src={world?.worldThumbnail} alt={world?.worldName} />
              <h4>{world?.worldName}</h4>
              <p>{world?.worldDescription}</p>
            </Card>
          ))}
        </Grid>
      </Section>}

{    SubscribedInWorlds?.length> 0 &&  <Section>
        <SectionTitle>Subscribed In</SectionTitle>
        <Grid>
          {SubscribedInWorlds?.map((world) => (
            <Card key={world._id}>
              <Thumbnail src={world?.worldThumbnail} alt={world?.worldName} />
              <h4>{world?.worldName}</h4>
              <p>{world?.worldDescription}</p>
            </Card>
          ))}
        </Grid>
      </Section>}
{   certifications?.length > 0 &&   <Section>
        <SectionTitle>Certifications</SectionTitle>
        <Grid>
          {certifications?.map((cert) => (
            <Card key={cert?._id}>
{           cert?.thumbnail?.contentType && cert?.thumbnail?.data &&
   <Thumbnail src={`data:${cert?.thumbnail?.contentType};base64,${cert?.thumbnail?.data}`} alt={cert?.name} />}
              {cert?.name}
            </Card>
          ))}
        </Grid>
      </Section>}
{   realWorldResults?.length > 0 &&   <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
    {realWorldResults?.map((result, index) => (
      <Card key={index}>
        {result?.thumbnail && (
          <Thumbnail src={result?.thumbnail} alt={result?.title || 'Achievement Thumbnail'} />
        )}
        {result?.title && <h4>{result?.title}</h4>}
        {result?.texts && <p>{result?.texts.map((text) => text.text).join(' ')}</p>}
        <Button
          onClick={() => handleGoToAcheivement(result?._id)}
          style={{ marginTop: '10px', background: '#27ae60', color: 'white' }}
        >
          View Achievement
        </Button>
      </Card>
    ))}
  </Grid>
</Section>}
<Modal show={showCommentsModal} onHide={closeCommentsModal}>
  <Modal.Header closeButton>
    <Modal.Title>Comments</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedWorldComments?.length > 0 ? (
      selectedWorldComments.map((comment) => (
        <div
          key={comment._id}
          style={{
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a
            href={`/profile/${comment?.commenter?.[0]?._id}`} 
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              marginRight: '10px',
            }}
          >
            <img
                                                src={`data:${comment?.commenter?.[0]?.picture.contentType};base64,${bufferToBase64(comment?.commenter?.[0]?.picture.picture.data.data)}`}

              alt="Commenter"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            />
          </a>
          <div>
            <a
              href={`/profile/${comment?.commenter?.[0]?._id}`} 
              style={{
                textDecoration: 'none',
                color: '#007bff',
                fontWeight: 'bold',
              }}
            >
              {comment?.commenter?.[0]?.name || 'Unknown'}
            </a>
            <p style={{ margin: 0 }}>
              <a
                href={`/profile/${comment?.commenter?.[0]?._id}`} 
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                ⭐ {comment?.commenter?.[0]?.rate || 'No rating'}
              </a>
            </p>
            <p style={{ marginTop: '5px' }}>
            {comment?.comment?.length > maxLength && !isExpanded
                ? `${comment.comment.substring(0, maxLength)}...`
                : comment.comment}
            {comment?.comment?.length > maxLength && (
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        marginLeft: '5px',
                        textDecoration: 'underline'
                    }}
                    onClick={toggleExpand}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </span>
            )}
        </p>
          </div>
        </div>
      ))
    ) : (
      <p>No comments available for this world!</p>
    )}
  </Modal.Body>
</Modal>

      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <p>Joined on: {new Date(joinedAt).toLocaleDateString()}</p>
      </div>
    </ProfileContainer>
    </PageContainer>
  );
}

export default GProfile;








const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;



const CoverLetter = styled.div`
  font-size: 1rem; /* Smaller font size */
  font-family: 'Georgia', serif; /* Elegant font style */
  color: #555; /* Subtle color for elegance */
  line-height: 1.5; /* Improve readability */
  text-align: justify; /* Align text for a clean format */
  margin: 10px 0; /* Add some spacing above and below */
  word-wrap: break-word; /* Prevent text from overflowing */
  max-width: 100%; /* Constrain to the available width */
  box-sizing: border-box; /* Ensure consistent sizing */
  white-space: pre-wrap; /* Preserve line breaks and wrap text */
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Slightly smaller on mobile */
    margin: 10px 15px; /* Add some padding for small screens */
  }
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: #27ae60;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 5px;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: #2c3e50;
  }
`;




const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;


