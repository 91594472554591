import axios from "axios";
import { useDebugValue, useEffect, useRef, useState } from "react";

import { FaBars, FaBell, FaChevronLeft, FaChevronRight, FaComments, FaStar, FaTrash, FaUserFriends } from "react-icons/fa";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { Button, Container, ProgressBar, Row } from "react-bootstrap";
import { ref, getDownloadURL,uploadBytesResumable, uploadBytes } from 'firebase/storage';
import { setAcheivementId } from "../../reducers/reducers";
import { useDispatch } from "react-redux";
import { storage } from "../../Firebase/Firebase";
function MProfile() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [subscribedInWorlds, setSubscribedInWorlds] = useState([])
  const [showResultModel, setShowResultModal] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backendLoading, setBackendLoading] = useState(false);
  const [showEditLetterModal, setShowEditLetterModal] = useState(false);
  const [showDeleteLetterModal, setShowDeleteLetterModal] = useState(false);
  const [newLetter, setNewLetter] = useState("");
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [elements, setElements] = useState([]);
  const [coverPictureSrc, setCoverPictureSrc] = useState('')
  const [showDeleteCoverPictureModal, setShowDeleteCoverPictureModal] = useState(false)
  const [newCoverPicture, setNewCoverPicture] = useState('')
  const [selectedSection, setSelectedSection] = useState("overview"); // Default section
  const [showCoverPictureModal, setShowCoverPictureModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ picturee, setPicturee] = useState('')
  const [profile, setProfile] = useState(null);


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const [firstName, setFirstName] = useState(profile?.name || "");
const [lastName, setLastName] = useState(profile?.surname || "");
const [surnamee, setSurnamee] = useState('')
const [correctPastPassword, setCorrectPastPassword] = useState(false)
const [showPhotoModal, setShowPhotoModal] = useState(false);
const [showVideoModal, setShowVideoModal] = useState(false);
const [newPhoto, setNewPhoto] = useState(null);
const [newVideoUrl, setNewVideoUrl] = useState("");
const [showDeleteModal, setShowDeleteModal] = useState(false);
const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);


  const [selectedResult, setSelectedResult] = useState(null);

  const [newResult, setNewResult] = useState({ title: "", thumbnail: "", elements: [] });
  
  const {
    name = "",
    surname = "",
    picture = "",
    coverPicture = "",
    letter = "",
    cash = 0,
    rate = 0,
    followers = [],
    worlds = [],
    certifications = [],
    instructorDescription = "",
    joinedAt = "",
    realWorldResults = [],
    definingVideo = "",
  } = profile || {};
  




  

////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [skillerId, setSkillerId] = useState('')
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};


useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }
    getSkillerId();
  },[])
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    
    const fetchCoverPicture = async () => {
      try {
          const response = await axios.get(
              'https://sell-skill.com/api/endpoints/getSkillerCoverPicture',
              { responseType: 'blob' }
          );
  
          console.log('Fetched blob:', response.data);
  
          if (response.data.size > 0) {
              // Blob has data, create an object URL
              const url = URL.createObjectURL(response.data);
              setCoverPictureSrc(url);
          } else {
              // Blob is empty, use the Firebase image URL
              setCoverPictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
          }
      } catch (error) {
          console.error('Error fetching profile picture:', error);
          // Optionally set a fallback image if there's an error
          setCoverPictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
      }
  };
  
  fetchCoverPicture()

    fetchProfilePicture();
}, []);

const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee






  useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }

    const fetchSubscribedInWorlds = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn');
        setSubscribedInWorlds(response.data);
        console.log('response.data from get subscribtions===========================-=-=-==-==-=-=-=-=-=-=--==-=-=-==-=---------------------->>> > > > >> > > >  > >>  ',response.data)
      } catch (error) {
        console.error('Error fetching worlds:', error);
      }
    };
    fetchSubscribedInWorlds();
    getSkillerId();
  })

console.log('profile from MProfile ========================================> >> > > >  > > >>  > > > ',profile)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoToAcheivement = (acheivementId) => {
    dispatch(setAcheivementId(acheivementId))
    console.log('acheivementId from MProfile =================================================================> > > > > >   ',acheivementId)
    navigate('/acheivment')
    
  }
  const handleFileUpload = async (file) => {
    try {

      const fileRef = ref(storage, `real-world-results/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };


  const handleEditLetter = async () => {
    try {
      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerLetter", { letter: newLetter });
      alert("Letter updated successfully.");
      setShowEditLetterModal(false);
      window.location.reload(); // Refresh the page to reflect the changes
    } catch (error) {
      console.error("Error updating letter:", error);
      alert("Failed to update the letter.");
    }
  };
  
  const handleDeleteLetter = async () => {
    try {
      await axios.delete("https://sell-skill.com/api/endpoints/deletetSkillerLetter");
      alert("Letter deleted successfully.");
      setShowDeleteLetterModal(false);
      window.location.reload(); // Refresh the page to reflect the changes
    } catch (error) {
      console.error("Error deleting letter:", error);
      alert("Failed to delete the letter.");
    }
  };
  
  const handleVideoUpload = async (file) => {
    try {
      setIsLoading(true);

      const storageRef = ref(storage, `videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading video:", error);
          setIsLoading(false);
        },
        async () => {
          const videoURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadSuccess(true);
          setIsLoading(false);

          // Proceed with backend upload
          setBackendLoading(true);
          try {
            await axios.post("https://sell-skill.com/api/endpoints/uploadVideo", { videoURL });
            setBackendLoading(false);
            alert('definition video changed successfully')
          } catch (error) {
            console.error("Error sending video URL to backend:", error);
            setBackendLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading video:", error);
      setIsLoading(false);
    }
  };
  
  const addElement = (type) => {
    setElements((prev) => [
      ...prev,
      { id: uuidv4(), type, content: '', order: prev.length + 1 },
    ]);
  };

  const updateElementContent = async (index, fileOrContent) => {
    const updatedElements = [...elements];
    if (updatedElements[index].type === 'text') {
      updatedElements[index].content = fileOrContent;
    } else {
      const url = await handleFileUpload(fileOrContent);
      updatedElements[index].content = url;
    }
    setElements(updatedElements);
  };

  const removeElement = (index) => {
    setElements((prev) =>
      prev.filter((_, i) => i !== index).map((el, i) => ({ ...el, order: i + 1 }))
    );
  };

  const handleThumbnailUpload = async (file) => {
    const url = await handleFileUpload(file);
    setThumbnail(url);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sell-skill.com/api/endpoints/realWorldResults', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: uuidv4(),
          title,
          thumbnail,
          elements,
        }),
      });
      if (response.ok) {
        console.log('Real World Results uploaded successfully');
      } else {
        console.error('Error uploading results:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting results:', error);
    }
  };
  const { id } = useParams(); // This will extract the `id` from the URL
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getProfile', {params: {id}})
        setProfile(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/getSkillerProfilePicture",
          { responseType: "blob" }
        );
        const url = URL.createObjectURL(response.data);
        setProfilePictureSrc(url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchProfile();
    fetchProfilePicture();
  }, [skillerId]);


    if (loading) {
      return <div className="loading-spinner">Loading...</div>;
    }
  


    const handleDeleteVideo = async () => {
      try {
          await axios.delete("https://sell-skill.com/api/endpoints/deleteVideo");
          alert("Definition video deleted successfully.");
          setShowDeleteVideoModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error deleting definition video:", err);
          alert("Failed to delete definition video.");
      }
  };
  
    const handleEditPhoto = async() => {

    }
    const handleDeletePhoto = async () => {
      try {
          await axios.delete("https://sell-skill.com/api/endpoints/deleteProfilePicture");
          alert("Profile photo deleted successfully.");
          setShowDeleteModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error deleting profile photo:", err);

      }
  };

  const handleDeleteCoverPicture = async () => {
    try {
        await axios.delete("https://sell-skill.com/api/endpoints/deleteCoverPicture");
        alert("Profile photo deleted successfully.");
        setShowDeleteModal(false);
        window.location.reload();
    } catch (err) {
        console.error("Error deleting profile photo:", err);

    }
};
  
    const handleChangePassword = async() => {

    }

    const checkPastPass = async () => {
      try {
        const response = await axios.get("https://sell-skill.com/api/endpoints/checkPastPass", {
          params: { currentPassword },
        });
        setCorrectPastPassword(response.data);
      } catch (error) {
        console.error("Error checking current password:", error);
        alert("Failed to validate the current password. Please try again.");
      }
    };
    const updatePassword = async () => {
      try {
        await axios.patch("https://sell-skill.com/api/endpoints/updatePassword", { newPassword });
        alert("Password updated successfully.");
      } catch (error) {
        console.error("Error updating password:", error);
        alert("Failed to update password. Please try again.");
      }
    };
    const handleChangeNameSurname = async() => {
      await axios.patch('https://sell-skill.com/api/endpoints/changeNameSurname', {firstName, surnamee})
    }


  

  
    const handleProfileClick = () => {
      setShowDropdown((prev) => !prev);
    };
  
    const handleSectionClick = (section) => {
      setSelectedSection(section); // Update the selected section
    };




    const handlePhotoModalSubmit = async () => {
      if (newPhoto) {
          const formData = new FormData();
          formData.append("picture", newPhoto);
          try {
              await axios.patch("https://sell-skill.com/api/endpoints/updateProfilePicture", formData);
              alert("Profile picture updated successfully.");
              setShowPhotoModal(false);
              window.location.reload();
          } catch (err) {
              console.error("Error updating profile picture:", err);
              alert("Failed to update profile picture.");
          }
      }
  };


      const handleCoverPictureModalSubmit = async () => {
      if (newCoverPicture) {
          const formData = new FormData();
          formData.append("coverPicture", newCoverPicture);
          try {
              await axios.patch("https://sell-skill.com/api/endpoints/updateCoverPicture", formData);
              alert("Profile picture updated successfully.");
              setShowPhotoModal(false);
              window.location.reload();
          } catch (err) {
              console.error("Error updating profile picture:", err);
              alert("Failed to update profile picture.");
          }
      }
  };

  const handleVideoModalSubmit = async () => {
      try {
          await axios.patch("https://sell-skill.com/api/endpoints/updateDefinitionVideo", { definitionVideo: newVideoUrl });
          alert("Definition video updated successfully.");
          setShowVideoModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error updating definition video:", err);
          alert("Failed to update definition video.");
      }
  };
  const confirmDeleteVideo = () => {
    setShowDeleteVideoModal(true);
  };






  const handleResultClick = (result) => {
    setSelectedResult(result);
    setShowResultModal(true);
  };



  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;


  
    const renderSectionContent = () => {
      switch (selectedSection) {
        case "accountSettings":
          return (
            <SectionContainer>
              <SectionHeader>Account Settings</SectionHeader>
        
              {/* Cover Photo */}
              <div>
                <SubHeader>Cover Photo</SubHeader>
                {profile.coverPicture ? (
                  <ImageContainer>
                    {coverPictureSrc && <ImagePreview src={coverPictureSrc}  />}
                    <div>
                      <Button onClick={() => setShowCoverPictureModal(true)}>Change Photo</Button>
                      <Button danger onClick={() => setShowDeleteCoverPictureModal(true)}>
                        Delete Photo
                      </Button>
                    </div>
                  </ImageContainer>
                ) : (
                  <Input type="file" placeholder="Upload a photo" />
                )}
              </div>
        
              {/* Profile Photo */}
              <div>
                <SubHeader>Profile Photo</SubHeader>
                {profile.picture ? (
                  <ImageContainer>
                    {profilePictureSrc && <ImagePreview src={profilePictureSrc}/>}
                    <div>
                      <Button onClick={() => setShowPhotoModal(true)}>Change Photo</Button>
                      <Button danger onClick={() => setShowDeleteModal(true)}>Delete Photo</Button>
                    </div>
                  </ImageContainer>
                ) : (
                  <Input type="file" placeholder="Upload a photo" />
                )}
              </div>
        
              {/* Modal for Photo Deletion */}
              {showDeleteModal && (
                <ModalOverlay>
                  <ModalContent>
                    <h2>Confirm Deletion</h2>
                    <p>Are you sure you want to delete your profile photo?</p>
                    <Button danger onClick={handleDeletePhoto}>Yes, Delete</Button>
                    <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                  </ModalContent>
                </ModalOverlay>
              )}
        
              {showDeleteCoverPictureModal && (
                <ModalOverlay>
                  <ModalContent>
                    <h2>Confirm Deletion</h2>
                    <p>Are you sure you want to delete your cover picture?</p>
                    <Button danger onClick={handleDeleteCoverPicture}>Yes, Delete</Button>
                    <Button onClick={() => setShowDeleteCoverPictureModal(false)}>Cancel</Button>
                  </ModalContent>
                </ModalOverlay>
              )}
        
              {/* Change Password */}
              <div>
                <SubHeader>Change Password</SubHeader>
                <label>
                  Current Password:
                  <Input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </label>
                <Button onClick={checkPastPass}>Check Current Password</Button>
                {correctPastPassword !== null && (
                  <p>{correctPastPassword ? "Current password is correct." : "Incorrect current password."}</p>
                )}
                <label>
                  New Password:
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    disabled={!correctPastPassword}
                  />
                </label>
                <Button onClick={updatePassword} disabled={!correctPastPassword || !newPassword}>
                  Update Password
                </Button>
              </div>
        
              {/* Update Name */}
              <div>
                <SubHeader>Update Name</SubHeader>
                <label>
                  Name:
                  <Input
                    type="text"
                    placeholder={profile.name}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </label>
                <label>
                  Surname:
                  <Input
                    type="text"
                    placeholder={profile.surname}
                    value={surnamee}
                    onChange={(e) => setSurnamee(e.target.value)}
                  />
                </label>
                <Button onClick={handleChangeNameSurname}>Update Name</Button>
              </div>
            </SectionContainer>
          );
        
          case "certifications":
            return (
              <>
                <h2>Certifications</h2>
                {profile.certifications?.length > 0 ? (
  <Grid>
    {profile.certifications.map((cert) => (
      <Card key={cert._id}>
        <Thumbnail
          src={`data:${cert.contentType};base64,${cert.data.toString('base64')}`}
          alt={cert.name || 'Certification'}
        />
        <h4>{cert.name || 'Unnamed Certificate'}</h4>
      </Card>
    ))}
  </Grid>
) : (
  <p>No certifications available.</p>
)}

              </>
            );


           case "letter":
             return (
              <SectionContainer>
              <LetterTitle>Letter</LetterTitle>
              <LetterText>{letter || "No letter available."}</LetterText>
          
              {/* Buttons */}
              <LetterButton onClick={() => setShowEditLetterModal(true)}>Edit Letter</LetterButton>
              <LetterButton danger onClick={() => setShowDeleteLetterModal(true)}>
                <FaTrash />
              </LetterButton>
          
              {/* Modal for editing */}
              {showEditLetterModal && (
                <Modal>
                  <ModalContent>
                    <h2>Edit Letter</h2>
                    <textarea
                      rows="5"
                      placeholder="Enter your new letter"
                      value={newLetter}
                      onChange={(e) => setNewLetter(e.target.value)}
                      style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    ></textarea>
                    <LetterButton onClick={handleEditLetter}>Save</LetterButton>
                    <LetterButton danger onClick={() => setShowEditLetterModal(false)}>Cancel</LetterButton>
                  </ModalContent>
                </Modal>
              )}
          
              {/* Modal for deletion */}
              {showDeleteLetterModal && (
                <Modal>
                  <ModalContent>
                    <h2>Confirm Deletion</h2>
                    <p>Are you sure you want to delete your letter? This action cannot be undone.</p>
                    <LetterButton danger onClick={handleDeleteLetter}>Yes, Delete</LetterButton>
                    <LetterButton onClick={() => setShowDeleteLetterModal(false)}>Cancel</LetterButton>
                  </ModalContent>
                </Modal>
              )}
            </SectionContainer>
             );

             case "definitionVideo": 
             return (
              <SectionContainer>
              <VideoTitle>Upload Your Video</VideoTitle>
              <p>Choose a video to upload, or skip this step.</p>
          
              <VideoUploadGroup>
                <UploadButton htmlFor="file-upload">Change Definition Video</UploadButton>
                <input
                  type="file"
                  id="file-upload"
                  accept="video/*"
                  onChange={(e) => handleVideoUpload(e.target.files[0])}
                  style={{ display: "none" }}
                />
          
                {/* Progress and Status */}
                {isLoading && (
                  <ProgressBar
                    now={uploadProgress}
                    label={`${Math.round(uploadProgress)}%`}
                    animated
                    striped
                  />
                )}
                {uploadSuccess && !backendLoading && <ProgressLabel>Video uploaded successfully!</ProgressLabel>}
                {backendLoading && <ProgressLabel>Finalizing upload, please wait...</ProgressLabel>}
              </VideoUploadGroup>
          
              {/* Delete Button */}
              <LetterButton danger onClick={confirmDeleteVideo}>Delete Video</LetterButton>
          
              {/* Modal for deletion */}
              {showDeleteVideoModal && (
                <Modal>
                  <ModalContent>
                    <h2>Confirm Deletion</h2>
                    <p>Are you sure you want to delete this video? This action cannot be undone.</p>
                    <LetterButton danger onClick={handleDeleteVideo}>Yes, Delete</LetterButton>
                    <LetterButton onClick={() => setShowDeleteVideoModal(false)}>Cancel</LetterButton>
                  </ModalContent>
                </Modal>
              )}
          
              {/* Display the video if available */}
              {definingVideo && (
                <div>
                  <h3>Defining Video</h3>
                  <Video controls>
                    <source src={definingVideo} type="video/mp4" />
                  </Video>
                </div>
              )}
            </SectionContainer>
             );


          case "realWorldResults":
            return (

<>   
<Container>
              <Header>Real World Results</Header>
              <Content>
                <Input
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <FileInputContainer>
                  <FileLabel htmlFor="thumbnail-upload">Upload Thumbnail</FileLabel>
                  <FileInput
                    id="thumbnail-upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleThumbnailUpload(e.target.files[0])}
                  />
                  {thumbnail && <Thumbnail src={thumbnail} alt="Thumbnail preview" />}
                </FileInputContainer>
                <ElementsContainer>
        
                {elements.map((el, index) => (
          <Element key={el.id}>
            {el.type === 'text' ? (
              <TextArea
                placeholder="Enter text"
                value={el.content}
                onChange={(e) => updateElementContent(index, e.target.value)}
              />
            ) : (
              <>
                <FileLabel htmlFor={`file-upload-${index}`}>Upload {el.type}</FileLabel>
                <FileInput
                  id={`file-upload-${index}`}
                  type="file"
                  accept={
                    el.type === 'photo'
                      ? 'image/*'
                      : el.type === 'video'
                      ? 'video/*'
                      : '.pdf'
                  }
                  onChange={(e) => updateElementContent(index, e.target.files[0])}
                />
                {/* Preview the uploaded file */}
                {el.type === 'photo' && el.content && (
                  <PreviewImage src={el.content} alt="Uploaded" />
                )}
                {el.type === 'video' && el.content && (
                  <VideoPreview controls>
                    <source src={el.content} type="video/mp4" />
                    Your browser does not support video.
                  </VideoPreview>
                )}
                {el.type === 'pdf' && el.content && (
                  <PdfPreview>
                    <a href={el.content} target="_blank" rel="noopener noreferrer">
                      View PDF
                    </a>
                  </PdfPreview>
                )}
              </>
            )}
            <RemoveButton onClick={() => removeElement(index)}>Remove</RemoveButton>
          </Element>
        ))}
        
        
                </ElementsContainer>
                <ButtonContainer>
                  <Button onClick={() => addElement('text')}>Add Text</Button>
                  <Button onClick={() => addElement('photo')}>Add Photo</Button>
                  <Button onClick={() => addElement('video')}>Add Video</Button>
                  <Button onClick={() => addElement('pdf')}>Add PDF</Button>
                </ButtonContainer>
                <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              </Content>
            </Container>














{ realWorldResults.length > 0 &&
            <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
    {realWorldResults?.map((result, index) => (
      <Card key={index}>
        {result.thumbnail && (
          <Thumbnail src={result.thumbnail} alt={result.title || 'Achievement Thumbnail'} />
        )}
        {result.title && <h4>{result.title}</h4>}
        {result.texts && <p>{result.texts.map((text) => text.text).join(' ')}</p>}
        <Button
          onClick={() => handleGoToAcheivement(result._id)}
          style={{ marginTop: '10px', background: '#27ae60', color: 'white' }}
        >
          View Achievement
        </Button>
      </Card>
    ))}
  </Grid>
</Section>}
</>

              );

              case "meAsAnAdvisorStatus":
                return (
                  <>
                    <h2> i am advior at</h2>
   
                   
                  </>
                );


        default:
          return (
            <>

   
             <CoverPhoto coverImage={profile.coverPicture} /> 
      
         
              <MProfilePicture
                src={profile.picture}
                alt={`${name}'s profile`}
              />    
        
                <h1>{name} {surname}</h1>
                                    <div className="mb-3">
                                        {Array.from({ length: 5 }, (_, i) => (
                                            <FaStar
                                                key={i}
                                                color={i < rate ? '#f39c12' : '#dcdcdc'}
                                            />
                                        ))}
                                    </div>
               <Stats>
                <a href="/mates"> {/* Link to mates */}
            <FaUserFriends size={16} color="#2c3e50" style={{ marginRight: "5px" }} />
            Mates: {followers?.length || 0}
          </a>
                  <p>Cash: ${cash}</p>
                  </Stats>
                <ActionButtons>
                  <button className="add-mate-btn">Add Mate</button>
                  <button className="message-btn">Message</button>
                  </ActionButtons>
      
       
      
            {definingVideo && (
              <Section>
                <SectionTitle>Defining Video</SectionTitle>
                <video controls style={{ width: '100%', borderRadius: '10px' }}>
                  <source src={definingVideo} type="video/mp4" />
                </video>
              </Section>
            )}
      
{    worlds.length > 0 &&        <Section>
              <SectionTitle>Uploaded Worlds</SectionTitle>
              <Grid>
                {worlds?.map((world) => (
                  <Card key={world._id}>
                    <Thumbnail src={world.worldThumbnail} alt={world.worldName} />
                    <h4>{world.worldName}</h4>
                    <p>{world.worldDescription}</p>
                  </Card>
                ))}
              </Grid>
            </Section>}

{ subscribedInWorlds.length > 0 &&
            <Section>
              <SectionTitle>Subscribed In Worlds</SectionTitle>
              <Grid>
                {subscribedInWorlds?.map((world) => (
                  <Card key={world._id}>
                    <Thumbnail src={world.worldThumbnail} alt={world.worldName} />
                    <h4>{world.worldName}</h4>
                    <p>{world.worldDescription}</p>
                  </Card>
                ))}
              </Grid>
            </Section>}
      
{   certifications.length > 0 &&         <Section>
              <SectionTitle>Certifications</SectionTitle>
              <Grid>
                {certifications?.map((cert) => (
                  <Card key={cert._id}>
{              cert.thumbnail?.contentType && cert.thumbnail.data &&     
 <Thumbnail src={`data:${cert.thumbnail?.contentType};base64,${cert.thumbnail?.data}`} alt={cert.name} />}
                    {cert.name}
                  </Card>
                ))}
              </Grid>
            </Section>}
{    realWorldResults.length > 0 &&        <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
  {realWorldResults?.map((result) => (
  <ResultCard key={result._id} onClick={() => handleResultClick(result)}>
{  result.thumbnail &&  <img src={result.thumbnail || '/default-thumbnail.jpg'} alt={result.title || 'No Title'} />}
{result.title &&    <h4>{result.title }</h4>}
  </ResultCard>
))}

  </Grid>
</Section>}
      
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <p>Joined on: {new Date(joinedAt).toLocaleDateString()}</p>
            </div>
 
          </>
          );
      }
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!profile) return <p>No profile data found.</p>;
  
    return (

    <PageContainer>
      <Container>
      <ContentContainer>
        {/* Top Navbar */}

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
        {/* Sidebar and Main Content */}

          {sidebarVisible && (

<>

{  sidebarVisible && <Sidebar>

<SidebarHeader>Settings</SidebarHeader>
<SidebarItem>
<SidebarLink onClick={() => handleSectionClick("overview")}>
        Profile Overview
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("accountSettings")}>
        Account Settings
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("certifications")}>
        Certifications
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("realWorldResults")}>
        Real World Results
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("letter")}>
        Letter
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("definitionVideo")}>
        Definition Video
    </SidebarLink>
    <SidebarLink onClick={() => handleSectionClick("meAsAnAdvisorStatus")}>
        Me As An Advisor Status
    </SidebarLink>

</SidebarItem>
</Sidebar>}
</>



          )}
          <ToggleSidebarButton onClick={() => setSidebarVisible(!sidebarVisible)}>
            {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
          </ToggleSidebarButton>
          <ContentWrapper>
          <MainContent>{renderSectionContent()}</MainContent>
          </ContentWrapper>

        {showPhotoModal && (
                <Modal>
                    <ModalContent>
                        <h2>Change Profile Photo</h2>
                        <input type="file" onChange={(e) => setNewPhoto(e.target.files[0])} />
                        <Button onClick={handlePhotoModalSubmit}>Submit</Button>
                        <Button onClick={() => setShowPhotoModal(false)}>Cancel</Button>
                    </ModalContent>
                </Modal>
            )}


                    {showCoverPictureModal && (
                <Modal>
                    <ModalContent>
                        <h2>Change Cover Picture</h2>
                        <input type="file" onChange={(e) => setNewCoverPicture(e.target.files[0])} />
                        <Button onClick={handleCoverPictureModalSubmit}>Submit</Button>
                        <Button onClick={() => setShowCoverPictureModal(false)}>Cancel</Button>
                    </ModalContent>
                </Modal>
            )}

            {showVideoModal && (
                <Modal>
                    <ModalContent>
                        <h2>Change Definition Video</h2>
                        <input
                            type="text"
                            placeholder="Enter video URL"
                            value={newVideoUrl}
                            onChange={(e) => setNewVideoUrl(e.target.value)}
                        />
                        <button onClick={handleVideoModalSubmit}>Submit</button>
                        <button onClick={() => setShowVideoModal(false)}>Cancel</button>
                    </ModalContent>
                </Modal>
            )}


{showDeleteVideoModal && (
    <Modal>
        <ModalContent>
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete your definition video?</p>
            <button onClick={handleDeleteVideo}>Yes, Delete</button>
            <button onClick={() => setShowDeleteVideoModal(false)}>Cancel</button>
        </ModalContent>
    </Modal>
)}
</ContentContainer>
</Container>
      </PageContainer>

    );
  }
  
  export default MProfile;

  const LetterContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
`;

const LetterTitle = styled.h3`
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 10px;
`;

const LetterText = styled.p`
  color: #34495e;
  font-size: 14px;
  margin-bottom: 20px;
`;

const LetterButton = styled.button`
  background-color: ${(props) => (props.danger ? "#e74c3c" : "#27ae60")};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.danger ? "#c0392b" : "#1e8449")};
  }
`;


















  const VideoContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
`;

const VideoTitle = styled.h3`
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 10px;
`;

const VideoUploadGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const UploadButton = styled.label`
  background-color: #27ae60;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1e8449;
  }
`;

const ProgressLabel = styled.p`
  font-size: 14px;
  color: #27ae60;
  margin: 10px 0;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;












  const SectionContainer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
`;

const SectionHeader = styled.h2`
  color: #2c3e50;
  margin-bottom: 20px;
`;

const SubHeader = styled.h3`
  color: #34495e;
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #27ae60;
`;


const Input = styled.input`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;














const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;
























const ProfileContainer = styled.div`
  font-family: 'Arial', sans-serif;
  color: #2c3e50;
  max-width: 1200px;
  margin: auto;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
`;

const CoverPhoto = styled.div`
  background-image: url(${props => props.coverImage});
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  border-radius: 10px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  position: relative;
  border-radius: 10px;
`;


const Stats = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  color: #7f8c8d;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  .add-mate-btn {
    background: #27ae60;
    color: white;
  }

  .message-btn {
    background: #2c3e50;
    color: white;
  }

  
`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h3`
  border-bottom: 2px solid #27ae60;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
    @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
`;




const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;

  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.1); /* Slightly enlarge the image */
  }
`;





const ResultCard = styled.div`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  img {
    max-width: 100%;
    border-radius: 4px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;



const Content = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;



const FileInput = styled.input`
  display: none;
`;





const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;


const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
`;

const VideoPreview = styled.video`
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const PdfPreview = styled.div`
  margin-top: 10px;
  a {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Element = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Space between elements */
  padding: 10px;
  background-color: #f9f9f9; /* Light background for clarity */
  border: 1px solid #ddd; /* Add borders for visibility */
  border-radius: 4px;
  flex-wrap: wrap; /* Allow wrapping for large content */
`;
const FileLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: #27ae60;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-right: 10px; /* Space between the label and other content */
  margin-bottom: 10px;
  &:hover {
    background: #218c53; /* Darker green on hover */
  }
`;




const ElementsContainer = styled.div`
  margin-bottom: 20px;
  max-height: 400px; /* Set a max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fefefe;
`;
const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
`;

const MainCard = styled(Card)`
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;






const Label = styled.label`
  display: block;
  text-align: center;
  color: #2c3e50;
  cursor: pointer;
`;





const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;







const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;
const MProfilePicture = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;













const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

 const FileInputContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 5px;

   input {
     display: none;
   }

   label {
     background-color: #27ae60;
     color: white;
     padding: 10px 20px;
     border-radius: 5px;
     cursor: pointer;
     transition: background-color 0.3s ease;

     &:hover {
       background-color: #1e8449;
     }
   }
 `;


const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  background-color: #f8f9fa;
  overflow-x: hidden;
`;

 

const SidebarItem = styled.div`
  margin-bottom: 20px;
`;

const SidebarHeader = styled.h3`
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 10px;
  padding-left: 5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const SidebarLink = styled.a`
  display: block;
  color: #34495e;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  margin-bottom: 5px;

  &:hover {
    background-color: #f4f4f4;
    color: #27ae60;
  }

  &.active {
    background-color: #27ae60;
    color: #fff;
  }
`;


 

const MainContent = styled.div`
  padding: 20px;
  overflow-x: hidden; /* Prevent horizontal scroll */
  box-sizing: border-box;
`;


const Wrapper = styled.div`
  max-width: 100%; /* Prevent content from exceeding the parent's width */
  margin: 0 auto; /* Center the content */
  padding: 0 16px; /* Add padding for smaller screens */
  box-sizing: border-box; /* Include padding in width calculations */
  overflow-x: hidden; /* Prevent horizontal scrolling */
`;






const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 150px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 140px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 100000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 147px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


// import axios from "axios";
// import { useEffect, useRef, useState } from "react";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";

// function MProfile() {
//     const [profile, setProfile] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [sidebarVisible, setSidebarVisible] = useState(true);
//     const [expandedSection, setExpandedSection] = useState(null);
//     const [selectedSection, setSelectedSection] = useState("overview"); // Default section
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
  
//     useEffect(() => {
//       const fetchProfile = async () => {
//         try {
//           const response = await axios.get(
//             "https://sell-skill.com/api/endpoints/getProfile"
//           );
//           setProfile(response.data);
//         } catch (err) {
//           setError(err.message);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchProfile();
//     }, []);
  
//     const toggleSidebar = () => {
//       setSidebarVisible(!sidebarVisible);
//     };
  
//     const handleSectionClick = (section) => {
//       setSelectedSection(section); // Update the selected section
//     };
  
//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error}</p>;
//     if (!profile) return <p>No profile data found.</p>;
  
//     const renderSectionContent = () => {
//       switch (selectedSection) {
//         case "accountSettings":
//           return (
//             <div>
//               <h2>Account Settings</h2>
//               <ul>
//                 <li>Change Photo</li>
//                 <li>Change Password</li>
//                 <li>Change Name</li>
//                 {/* Add more options here */}
//               </ul>
//             </div>
//           );
//         case "certifications":
//           return (
//             <div>
//               <h2>Certifications</h2>
//               <ul>
//                 {profile.certifications.map((cert) => (
//                   <li key={cert._id}>{cert.name}</li>
//                 ))}
//               </ul>
//             </div>
//           );
//         case "realWorldResults":
//           return (
//             <div>
//               <h2>Real World Results</h2>
//               <ul>
//                 {profile.realWorldResults.map((result) => (
//                   <li key={result._id}>{result.description}</li>
//                 ))}
//               </ul>
//             </div>
//           );
//         // Add more cases for other sections
//         default:
//           return (
//             <div>
//               <h2>Overview</h2>
//               <p>Welcome to your profile overview!</p>
//             </div>
//           );
//       }
//     };
  
//     return (
//       <PageContainer>
//         <Navbar>
//           {/* Navbar content */}
//         </Navbar>
//         <ContentContainer>
//           {sidebarVisible && (
//             <Sidebar>
//               <SectionHeader onClick={() => handleSectionClick("overview")}>
//                 Profile Overview
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("accountSettings")}>
//                 Account Settings
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("certifications")}>
//                 Certifications
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("realWorldResults")}>
//                 Real World Results
//               </SectionHeader>
//               {/* Add more SectionHeaders here */}
//             </Sidebar>
//           )}
//           <ToggleSidebarButton onClick={toggleSidebar}>
//             {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
//           </ToggleSidebarButton>
//           <MainContent>{renderSectionContent()}</MainContent>
//         </ContentContainer>
//       </PageContainer>
//     );
//   }
  
//   export default MProfile;
// // Styled Components
// const PageContainer = styled.div`
//   background-color: #f8f9fa;
//   min-height: 100vh;
//   padding: 60px 20px 20px;
// `;

// const Navbar = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
//   background-color: #2c3e50;
//   color: white;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 1000;
// `;

// const LeftNav = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const Logo = styled.div`
//   font-size: 24px;
//   font-weight: bold;
//   cursor: pointer;
// `;

// const NavLinks = styled.div`
//   margin-left: 20px;
//   display: flex;
//   gap: 15px;

//   a {
//     color: white;
//     text-decoration: none;
//   }
// `;

// const NavIcons = styled.div`
//   display: flex;
//   gap: 15px;
// `;

// const ProfileWrapper = styled.div`
//   position: relative;
// `;

// const ProfilePicture = styled.img`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
// `;

// const DefaultProfile = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: #bdc3c7;
// `;

// const DropdownMenu = styled.div`
//   position: absolute;
//   top: 50px;
//   right: 0;
//   background-color: white;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   width: 200px;
//   display: flex;
//   flex-direction: column;

//   a {
//     padding: 10px 15px;
//     color: black;
//     text-decoration: none;

//     &:hover {
//       background-color: #f0f0f0;
//     }
//   }
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   position: relative;
//   width: 100%;
// `;

// const Sidebar = styled.div`
//   width: 300px;
//   background-color: #f4f4f4;
//   padding: 20px;
//   border-right: 1px solid #ddd;
// `;

// const SectionHeader = styled.h2`
//   font-size: 18px;
//   color: #2c3e50;
//   cursor: pointer;
// `;

// const ToggleSidebarButton = styled.div`
//   position: absolute;
//   top: 8px;
//   left: ${props => (props.sidebarVisible ? "300px" : "0")};
//   cursor: pointer;

//   svg {
//     font-size: 20px;
//   }
// `;

// const MainContent = styled.div`
//   flex: 1;
//   padding: 20px;
// `;

// const Header = styled.div`
//   margin-bottom: 20px;

//   h1 {
//     font-size: 24px;
//   }

//   p {
//     color: #7f8c8d;
//   }
// `;

// const ProfileDetails = styled.div`
//   h3 {
//     margin-top: 20px;
//   }

//   ul {
//     list-style: none;
//     padding: 0;

//     li {
//       margin: 5px 0;
//     }
//   }
// `;
