import React, { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light Gray background */
    font-family: Arial, Helvetica, sans-serif;
    color: #2c3e50; /* Dark Gray for text */
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const MainCard = styled(Card)`
  background-color: #ffffff; /* White for the card background */
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50; /* Dark text for the title */
  margin-bottom: 20px;
`;

const UploadCard = styled(Card)`
  background-color: #2c3e50; /* Dark background for the upload card */
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: #ffffff; /* White text */

  &:hover {
    background-color: #27ae60; /* Green hover */
    color: #ffffff;
  }
`;

const UploadButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #27ae60; /* Green button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #219150; /* Darker green */
    color: white;
  }
`;

const Input = styled.input`
  display: none;
`;

const ImagePreview = styled.img`
  display: block;
  margin: 20px auto;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #27ae60; /* Green border */
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;

function Certifications() {
  const [pdfCertificate, setPdfCertificate] = useState(null);
  const [imageCertificate, setImageCertificate] = useState(null);
  const navigate = useNavigate();

  const handlePDF = async (file) => {
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("name", "application/pdf");
    formData.append("id", uuidv4());

    try {
      await axios.post("https://sell-skill.com/api/endpoints/insertPdfCertificate", formData);
      setPdfCertificate(file);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("id", uuidv4());

    try {
      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerImageCertificate", formData);
      setImageCertificate(file);
    } catch (error) {
      console.error("Error uploading Image:", error);
    }
  };

  const handleNextClick = () => {
    navigate("/picture");
  };

  return (
    <PageContainer>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>Certifications Upload</Title>
          <UploadCard onClick={() => document.getElementById("image-upload").click()}>
            <b>Upload IMAGE</b>
          </UploadCard>
          <Input
            id="image-upload"
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={(e) => handleImage(e.target.files[0])}
          />
          {imageCertificate && <ImagePreview src={URL.createObjectURL(imageCertificate)} alt="Certificate Preview" />}
          
          <UploadCard onClick={() => document.getElementById("pdf-upload").click()}>
            <b>Upload PDF</b>
          </UploadCard>
          <Input
            id="pdf-upload"
            type="file"
            name="pdf"
            accept="application/pdf"
            onChange={(e) => handlePDF(e.target.files[0])}
          />

          <UploadButton onClick={handleNextClick}>Next</UploadButton>
        </MainCard>
      </StyledContainer>
    </PageContainer>
  );
}

export default Certifications;
































// import React, { useState } from 'react';
// import { Button, Card, Container } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import styled, { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #E6F0FF; /* Light Blue background */
//     color: #2F4F4F; /* Dark Gray for text */
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   padding: 20px;
// `;

// const MainCard = styled(Card)`
//   background-color: #FAFAFA; /* Off-White for the card background */
//   border-radius: 20px;
//   width: 100%;
//   max-width: 600px;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
//   padding: 20px;
// `;

// const Title = styled.h2`
//   text-align: center;
//   color: #4169E1; /* Royal Blue for the title */
//   margin-bottom: 20px;
// `;

// const UploadCard = styled(Card)`
//   background-color: #4169E1; /* Royal Blue for the upload card */
//   margin: 10px 0;
//   padding: 20px;
//   border-radius: 15px;
//   text-align: center;
//   cursor: pointer;
//   color: #FAFAFA; /* Off-White text */

//   &:hover {
//     background-color: #FFD700; /* Golden Yellow on hover */
//     color: #4169E1; /* Royal Blue text on hover */
//   }
// `;

// const UploadButton = styled(Button)`
//   width: 100%;
//   margin-top: 20px;
//   background-color: #FFD700; /* Golden Yellow for the button */
//   border: none;
//   padding: 12px;
//   font-size: 16px;
//   font-weight: bold;
//   border-radius: 8px;

//   &:hover {
//     background-color: #4169E1; /* Royal Blue on hover */
//     color: #FAFAFA; /* Off-White text on hover */
//   }
// `;

// const Input = styled.input`
//   display: none;
// `;

// const ImagePreview = styled.img`
//   display: block;
//   margin: 20px auto;
//   width: 140px;
//   height: 140px;
//   border-radius: 20px;
//   object-fit: cover;
//   border: 2px solid #4169E1; /* Royal Blue border */
// `;

// function Certifications() {
//   const [pdfCertificate, setPdfCertificate] = useState(null);
//   const [imageCertificate, setImageCertificate] = useState(null);
//   const navigate = useNavigate();

//   const handlePDF = async (e) => {
//     const formData = new FormData();
//     formData.append('pdf', e);
//     formData.append('name', 'application/pdf');
//     formData.append('id', uuidv4());
//     await axios.post('https://sell-skill.com/api/endpoints/insertPdfCertificate', formData);
//     setPdfCertificate(e);
//   };

//   const handleIMAGE = async (e) => {
//     const formData = new FormData();
//     formData.append('image', e);
//     formData.append('id', uuidv4());
//     await axios.post('https://sell-skill.com/api/endpoints/insertSkillerImageCertificate', formData);
//     setImageCertificate(e);
//   };

//   const handleNextClick = () => {
//     navigate('/experience');
//   };

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer>
//         <MainCard>
//           <Title>Certifications Here</Title>
//           <UploadCard onClick={() => document.getElementById('image-upload').click()}>
//             <b>Upload IMAGE</b>
//           </UploadCard>
//           <Input
//             id="image-upload"
//             type="file"
//             name="image"
//             onChange={(e) => {
//               handleIMAGE(e.target.files[0]);
//               setImageCertificate(e.target.files[0]);
//             }}
//           />
//           {imageCertificate && <ImagePreview src={URL.createObjectURL(imageCertificate)} />}
          
//           <UploadCard onClick={() => document.getElementById('pdf-upload').click()} style={{ marginTop: '10px' }}>
//             <b>Upload PDF</b>
//           </UploadCard>
//           <Input
//             id="pdf-upload"
//             type="file"
//             name="pdf"
//             accept="application/pdf"
//             onChange={(e) => handlePDF(e.target.files[0])}
//           />

//           <UploadButton onClick={handleNextClick}>Next</UploadButton>
//         </MainCard>
//       </StyledContainer>
//     </>
//   );
// }

// export default Certifications;
